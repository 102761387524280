import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { ExpertServicesOngoingDto } from '../dtos';

export const Actions = {
  getExpertServicesOngoing: () =>
    createAction(ActionTypes.GetExpertServicesOngoingRequest),
  getExpertServicesOngoingSuccess: (
    expertServicesOngoingDto: ExpertServicesOngoingDto
  ) =>
    createAction(
      ActionTypes.GetExpertServicesOngoingSuccess,
      expertServicesOngoingDto
    ),
  getExpertServicesOngoingFailure: (errors: ExpertServicesOngoingDto) =>
    createAction(ActionTypes.GetExpertServicesOngoingFailure, errors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
