const Backbone = require("backbone");
const APIConfig = require("apiconfig");
const _ = require("underscore");

module.exports = Backbone.Model.extend({
  url: `${APIConfig.host}user/status`,

  isAccountOnHold() {
    const accountHolds = [
      "suspended",
      "banned",
      "deactivated",
      "warned",
      "billing frozen",
      "billing terminated",
      "billing warned",
    ];

    return _.contains(accountHolds, this.get("status"));
  },
});
