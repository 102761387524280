var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <h1>Resend Confirmation Email</h1>\n\n  <div class='input-text-wrap' data-text-input-parent=\"true\">\n    <input data-text-input=\"true\"  data-field=\"email\" class=\"input-text\" id=\"email\" role=\"emailAddress\" type=\"text\" name=\"email\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.email : depth0), depth0))
    + "\" placeholder=\"Email Address\"/>\n    <label class=\"input-text-label\" for=\"email\">Email Address</label>\n  </div>\n\n  <div class=\"btn-list pull-right\">\n    <div class=\"btn btn-primary\" role=\"submitButton\">Send</div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/centerModal.hbs"),depth0,{"name":"centerModal","hash":{"containerRole":"emailConfirmationContainer"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});