import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { TypedMap } from '../../../@types/TypedMap';
import _ from 'underscore';
// types
import { ContactSales } from '../../../state/types/contactSales';
import { MakoStateType } from '../../../state/types/makoStateType';
import { Package } from '../../../state/types/package';
import { SubscriptionPayment } from '../../../state/types/subscriptionPayment';
import { TeammateRequestAccessScope } from '../../../state/types/teammateRequestAccess';
import { UpdatePlanType } from '../../../state/types/updatedPlan';
// actions
import { Actions as ContactSalesActions } from '../../../state/contact_sales/actions';
import { Actions as ContactsActions } from '../../../state/contacts/actions';
import { Actions as CreateSubscriptionPaymentActions } from '../../../state/subscription_payment/actions';
import { Actions as EaseActions } from '../../../state/ease/actions';
import { Actions as PackagesActions } from '../../../state/packages/actions';
import { Actions as PaymentFormActions } from '../../../state/payment_form/actions';
import { Actions as PremierPackagesActions } from '../../../state/premier_packages/actions';
import { Actions as TeammateRequestAccessActions } from '../../../state/teammate_request_access/actions';
import { Actions as UpdatePlanActions } from '../../../state/update_plan/actions';
import { Actions as UserPackageActions } from '../../../state/user_package/actions';
// actionTypes
import { ActionTypes as ContactsActionTypes } from '../../../state/contacts/actionTypes';
import { ActionTypes as EaseActionTypes } from '../../../state/ease/actionTypes';
import { ActionTypes as PackagesActionTypes } from '../../../state/packages/actionTypes';
import { ActionTypes as PaymentMethodActionTypes } from '../../../state/payment_methods/actionTypes';
import { ActionTypes as TeammateRequestAccessActionTypes } from '../../../state/teammate_request_access/actionTypes';
import { ActionTypes as UserPackageActionTypes } from '../../../state/user_package/actionTypes';
// selectors
import { getIsSubuser } from '../../../state/account/selector';
// utils
import packageConfig from '../../../state/utils/packageConfig';

const choosePlanActionMap = {} as TypedMap<string>;
const choosePlanActionTypes = {
  ...ContactsActionTypes,
  ...EaseActionTypes,
  ...PackagesActionTypes,
  ...PaymentMethodActionTypes,
  ...TeammateRequestAccessActionTypes,
  ...UserPackageActionTypes,
};

for (const key in choosePlanActionTypes) {
  if (key in choosePlanActionTypes) {
    choosePlanActionMap[choosePlanActionTypes[key]] =
      choosePlanActionTypes[key];
  }
}

const getLoadingState = (state: MakoStateType): boolean => {
  // if any loading value is true, we're in a loading state and want to return that
  // otherwise false
  for (const k in state.loading) {
    if (choosePlanActionMap[k] && state.loading[k]) {
      return true;
    }
  }
  return false;
};

// Don't show packages > 25 Million
const filterPremierPackages = (packages: Package[] = []): Package[] =>
  packages.filter((p) => p.id in packageConfig.premierPackages25MAndUnder);

const mapStateToProps = (state: MakoStateType) => {
  const {
    contactSalesInfo,
    contacts,
    deleteEase,
    packages,
    paymentForm,
    premierPackages,
    subscriptionPayment,
    teammateRequestAccessInfo,
    updatedEasePricing,
    updatedPlan,
    userPackage,
  } = state;

  return {
    contactSalesInfo,
    contacts,
    deleteEase,
    isSubuser: getIsSubuser(state),
    loading: getLoadingState(state),
    packages,
    paymentForm,
    premierPackages: filterPremierPackages(premierPackages),
    subscriptionPayment,
    teammateRequestAccessInfo,
    updatedEasePricing,
    updatedPlan,
    userEase: state.ease,
    userPackage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getContacts: () => dispatch(ContactsActions.getBillableContactsInfo()),
    getPackages: () => dispatch(PackagesActions.getPackages()),
    getPremierPackages: () =>
      dispatch(PremierPackagesActions.getPremierPackages()),
    getEase: () => dispatch(EaseActions.getEase()),
    updatePlan: (updatePlan: UpdatePlanType) => {
      const { userEase } = updatePlan;
      dispatch(UpdatePlanActions.updatePlan(updatePlan));
      if (userEase) {
        dispatch(EaseActions.deleteEase(userEase.baseData));
      }
    },
    getEaseUpdatedPricing: (packageId: string) => {
      dispatch(EaseActions.getEaseUpdatedPrice(packageId));
    },
    clearUpdatePlanErrors: () => {
      dispatch(UpdatePlanActions.clearUpdatePlanServerErrors());
    },
    clearDeleteEaseErrors: () => {
      dispatch(EaseActions.clearDeleteEaseServerErrors());
    },
    contactSales: (premierPackageRequest: ContactSales) =>
      dispatch(ContactSalesActions.contactSalesRequest(premierPackageRequest)),
    clearContactSalesServerErrors: () =>
      dispatch(ContactSalesActions.clearContactSalesServerErrors()),
    getPaymentForm: () => {
      dispatch(PaymentFormActions.getPaymentFormForUpgradingUser());
    },
    clearPaymentFormErrors: () => {
      dispatch(PaymentFormActions.clearPaymentFormErrorsForPaidUser());
      dispatch(
        CreateSubscriptionPaymentActions.clearCreateSubscriptionPaymentErrors()
      );
    },
    getUserPackage: () => {
      dispatch(UserPackageActions.getUserPackage());
    },
    createSubscriptionPayment: (subscriptionPayment: SubscriptionPayment) => {
      dispatch(
        CreateSubscriptionPaymentActions.createSubscriptionPayment(
          subscriptionPayment
        )
      );
    },
    teammateRequestAccess: (
      teammateRequestAccessScope: TeammateRequestAccessScope
    ) => {
      dispatch(
        TeammateRequestAccessActions.teammateRequestAccessRequest(
          teammateRequestAccessScope
        )
      );
    },
    clearTeammateRequestAccess: () => {
      dispatch(TeammateRequestAccessActions.clearTeammateRequestAccess());
    },
  };
};

export type ChoosePlanContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export interface ChoosePlanContainerState {
  isTeammateWithUpdateAccess: boolean;
}

export default function ChoosePlanContainer<T>(
  Component: React.ComponentType<T>
) {
  class Container extends React.Component<
    ChoosePlanContainerProps,
    ChoosePlanContainerState
  > {
    constructor(props: ChoosePlanContainerProps) {
      super(props);
      this.state = {
        isTeammateWithUpdateAccess: window.Reqres.request(
          'hasScopes',
          'billing.update'
        ),
      };
    }

    public componentDidMount() {
      const {
        clearTeammateRequestAccess,
        getContacts,
        getEase,
        getPackages,
        getPremierPackages,
        getUserPackage,
        userEase,
        userPackage,
      } = this.props;

      getContacts();
      getPackages();
      getPremierPackages();
      clearTeammateRequestAccess();

      // we get into this state if the user loads into the choose plan page as their first page
      if (!userEase.storage) {
        getEase();
      }
      if (_.isEmpty(userPackage)) {
        getUserPackage();
      }
    }

    public render() {
      return <Component {...this.props} {...this.state} />;
    }
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Container);
}
