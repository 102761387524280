import * as React from 'react';

export const List: React.SFC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  >
> = ({ className = '', children, ...props }) => {
  const baseClass = 'feature-list';
  const classNames = `${baseClass} ${className}`.trim();
  return (
    <ul className={classNames} {...props}>
      {children}
    </ul>
  );
};
