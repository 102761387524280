export enum ResponseImplementationServiceNames {
  EmailBootcamp = 'email_bootcamp',
  EmailImplementation = 'email_implementation',
}

export enum DisplayImplementationServiceNames {
  EmailBootcamp = 'Email Bootcamp',
  EmailImplementation = 'Email Implementation',
}

export interface ExpertServiceImplementation {
  name:
    | ResponseImplementationServiceNames.EmailBootcamp
    | ResponseImplementationServiceNames.EmailImplementation;
  basePrice: number;
  purchaseDate: string;
  isPurchased: boolean;
}

export interface ExpertServicesImplementation {
  implementationServices: ExpertServiceImplementation[];
  errorMessage: string;
}
