// Only add actions to this if they use epics
import { ActionsUnion, createAction } from '../actionBuilder';
import { FeatureToggleDto } from '../dtos';
import { ActionTypes } from './actionTypes';

export const Actions = {
  createFeatureToggles: (featureToggles: FeatureToggleDto[]) =>
    createAction(ActionTypes.CreateFeatureToggles, featureToggles),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
