export enum ActionTypes {
  ClearPaymentFormErrors = 'clearPaymentFormErrors',

  GetBillingAccount = 'getBillingAccount',
  GetBillingAccountRequest = 'getBillingAccount.request',
  GetBillingAccountSuccess = 'getBillingAccount.success',
  GetBillingAccountFailure = 'getBillingAccount.failure',

  GetPaymentFormForPaidUser = 'getPaymentFormForPaidUser',
  GetPaymentFormForPaidUserRequest = 'getPaymentFormForPaidUser.request',
  GetPaymentFormForPaidUserSuccess = 'getPaymentFormForPaidUser.success',
  GetPaymentFormForPaidUserFailure = 'getPaymentFormForPaidUser.success',

  GetPaymentFormForUpgradingUser = 'getPaymentFormForUpgradingUser',
  GetPaymentFormForUpgradingUserRequest = 'getPaymentFormForUpgradingUser.request',
  GetPaymentFormForUpgradingUserSuccess = 'getPaymentFormForUpgradingUser.success',
  GetPaymentFormForUpgradingUserFailure = 'getPaymentFormForUpgradingUser.failure',
}
