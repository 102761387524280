export enum ActionTypes {
  GetEase = 'getEase',
  GetEaseRequest = 'getEase.request',
  GetEaseSuccess = 'getEase.success',
  GetEaseFailure = 'getEase.failure',

  GetEaseUpdatedPriceRequest = 'getEaseUpdatedPrice.request',
  GetEaseUpdatedPriceSuccess = 'getEaseUpdatedPrice.success',
  GetEaseUpdatedPriceFailure = 'getEaseUpdatedPrice.failure',

  DeleteEase = 'deleteEase',
  DeleteEaseRequest = 'deleteEase.request',
  DeleteEaseSuccess = 'deleteEase.success',
  DeleteEaseFailure = 'deleteEase.failure',

  ClearDeleteEaseServerErrors = 'clearDeleteEaseServerErrors',
}
