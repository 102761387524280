import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { InvoiceAddressDto } from '../dtos';
import { InvoiceAddress } from '../../state/types/invoiceAddress';

export const Actions = {
  getInvoiceAddress: () => createAction(ActionTypes.GetInvoiceAddressRequest),
  getInvoiceAddressSuccess: (invoiceAddressDto: InvoiceAddressDto) =>
    createAction(ActionTypes.GetInvoiceAddressSuccess, invoiceAddressDto),
  getInvoiceAddressFailure: (invoiceAddressDto: InvoiceAddressDto) =>
    createAction(ActionTypes.GetInvoiceAddressFailure, invoiceAddressDto),

  updateInvoiceAddressRequest: (invoiceAddress: InvoiceAddress) =>
    createAction(ActionTypes.UpdateInvoiceAddressRequest, invoiceAddress),
  updateInvoiceAddressSuccess: (invoiceAddress: InvoiceAddressDto) =>
    createAction(ActionTypes.UpdateInvoiceAddressSuccess, invoiceAddress),
  updateInvoiceAddressFailure: (errors: InvoiceAddressDto) =>
    createAction(ActionTypes.UpdateInvoiceAddressFailure, errors),
  clearInvoiceAddressServerErrors: () =>
    createAction(ActionTypes.ClearInvoiceAddressServerErrors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
