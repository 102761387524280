(function($) {
  $.fn.errors = function(action, message) {
    return this.each(function() {
      const $elt = $(this);

      if (action === "show") {
        show($elt, message);
      } else if (action === "clear") {
        clear($elt);
      }
    });
  };

  function show($elt, message) {
    clear($elt);

    $elt.addClass($.fn.errors.defaults.errorClass);

    const $message = $("<span />").addClass(
      $.fn.errors.defaults.errorMessageClass
    );
    $message.text(message);
    $elt.append($message);

    $elt.data("error", true);
  }

  function clear($elt) {
    if ($elt.data("error")) {
      $elt.removeClass($.fn.errors.defaults.errorClass);
      $elt
        .find(
          `span.${$.fn.errors.defaults.errorMessageClass.split(" ").join(".")}`
        )
        .remove();

      $elt.data("error", false);
    }
  }

  $.fn.errors.defaults = {
    errorMessageClass: "input-text-info danger",
    errorClass: "error",
  };
})(jQuery);
