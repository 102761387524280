import * as React from 'react';
import cn from 'classnames';

import { WithScale, ScaleProps } from '../../WithScale';

import './index.scss';

type Width = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type Offset = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

interface ColumnProps extends GridProps, ScaleProps {
  children?: React.ReactNode | string;
  className?: string;
}

interface GridProps {
  offset?: Offset;
  width?: Width;
  [key: number]: Width | Offset | undefined;
}

enum ClassNameMap {
  offset = 'col-start-',
  width = 'mako-grid__col-',
}

function mapGridProps(gridProps: GridProps) {
  const classNames = [];
  for (const key in gridProps) {
    if (gridProps[key]) {
      classNames.push(
        `${ClassNameMap[key as keyof typeof ClassNameMap]}${gridProps[key]}`
      );
    }
  }
  return classNames;
}

export const Column: React.SFC<ColumnProps> = ({
  children,
  className = '',
  width,
  offset,
  ...props
}) => {
  const gridProps = { offset, width };
  const gridClassNames = mapGridProps(gridProps)
    .concat(className)
    .join(' ')
    .trim();
  return (
    <WithScale className={gridClassNames} {...props}>
      {(injectedProps) => {
        return <div {...injectedProps}>{children}</div>;
      }}
    </WithScale>
  );
};
