import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { InvoiceDto } from '../dtos';

export const Actions = {
  getInvoices: () => createAction(ActionTypes.GetInvoicesRequest),
  getInvoicesSuccess: (invoicesDto: InvoiceDto[]) =>
    createAction(ActionTypes.GetInvoicesSuccess, invoicesDto),
  getInvoicesFailure: () => createAction(ActionTypes.GetInvoicesFailure),
  retryInvoicePayment: () =>
    createAction(ActionTypes.RetryInvoicePaymentRequest),
  retryInvoicePaymentSuccess: () =>
    createAction(ActionTypes.RetryInvoicePaymentSuccess),
  retryInvoicePaymentFailure: () =>
    createAction(ActionTypes.RetryInvoicePaymentFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
