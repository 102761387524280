import { createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { RemoveExpertInsightsDto } from '../dtos';
import { RemoveExpertInsightsRequest } from '../types/removeExpertInsights';

export const Actions = {
  removeExpertInsights: (
    removeExpertInsightsRequestBody?: RemoveExpertInsightsRequest
  ) =>
    createAction(
      ActionTypes.RemoveExpertInsightsRequest,
      removeExpertInsightsRequestBody
    ),
  removeExpertInsightsSuccess: () =>
    createAction(ActionTypes.RemoveExpertInsightsSuccess),
  removeExpertInsightsFailure: (errors: RemoveExpertInsightsDto) =>
    createAction(ActionTypes.RemoveExpertInsightsFailure, errors),
};
