const Marionette = require("backbone.marionette");
const Handlebars = require("handlebars");
const _ = require("underscore");

// views
const StatsTableView = require("./views/stats/table_view");
const StatsTableLayoutView = require("./views/stats/table_layout_view");
const StatsTableHeaderView = require("./views/stats/table_header_view");
const StatsTableWrapperView = require("./views/stats/table_wrapper_view");

// route helpers
const RouterHelpers = require("./router_helpers");

const statsPageViewEvent = "view_page_stats";

module.exports = Marionette.AppRouter.extend({
  routes: {
    statistics: "statsOverview",
    "statistics/global": "statsGlobal",
    "statistics/category": "statsCategory",
    "statistics/category/compare": "statsCategoryCompare",
    "statistics/mailbox_provider/compare": "statsMailboxProviderCompare",
    "statistics/mailbox_provider": "statsMailboxProvider",
    "statistics/browser/compare": "statsBrowserCompare",
    "statistics/browser": "statsBrowser",
    "statistics/geo": "statsGeo",
    "statistics/subuser": "statsSubuser",
    "statistics/subuser/compare": "statsSubuserCompare",
    "statistics/device": "statsDevice",
    "statistics/parse_webhook": "statsParseWebhook",
  },

  initialize() {
    this.errorRouter = this.getOption("errorRouter");
    this.authController = this.getOption("authController");
  },

  before(route) {
    RouterHelpers.before(this, route);
    if (window.SendGridTiara && window.SendGridTiara.analyticsTrack) {
      window.SendGridTiara.analyticsTrack(statsPageViewEvent);
    }
  },

  statsOverview() {
    import("routes/stats_overview").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsGlobal() {
    import("routes/stats_global").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsCategory() {
    import("routes/stats_category").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsCategoryCompare() {
    import("routes/stats_category_compare").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsMailboxProvider() {
    import("routes/stats_mailbox_provider").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsMailboxProviderCompare() {
    import("routes/stats_mailbox_provider_compare").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsGeo() {
    import("routes/stats_geo").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsSubuser() {
    import("routes/stats_subuser").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsSubuserCompare() {
    import("routes/stats_subuser_compare").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsBrowser() {
    import("routes/stats_browser").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsBrowserCompare() {
    import("routes/stats_browser_compare").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsDevice() {
    import("routes/stats_device").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        createStatsTable: this.createStatsTable,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  statsParseWebhook() {
    import("routes/stats_parse_webhook").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  createStatsTable(statsCollection, pageModel, options) {
    const tableOptions = options || {};
    const headerViewOptions = _.defaults(tableOptions.headerViewOptions || {}, {
      heading: "Figures",
      collection: statsCollection,
      model: pageModel,
    });
    const rowHeaderViewOptions = _.defaults(
      tableOptions.rowHeaderViewOptions || {},
      {
        collection: statsCollection,
        columns: [{ key: "key", label: "Date" }],
        rowHeader: true,
        model: pageModel,
        childClassName: "date",
        formatter: Handlebars.helpers.statDate,
      }
    );
    const tableViewOptions = _.defaults(tableOptions.tableViewOptions || {}, {
      collection: statsCollection,
      model: pageModel,
    });
    const layoutViewOptions = tableOptions.layoutViewOptions || {};

    const figuresTableHeaderView = new StatsTableHeaderView(headerViewOptions);
    const figuresTableRowHeaderView = new StatsTableView(rowHeaderViewOptions);

    tableViewOptions.tableHeaderView = figuresTableHeaderView;
    if (headerViewOptions.showNameFilterDropdown) {
      tableViewOptions.dropdownFilterView = figuresTableHeaderView;
    }

    const figuresTableView = new StatsTableView(tableViewOptions);
    const figuresTableWrapperView = new StatsTableWrapperView({
      statsTableDataView: figuresTableView,
      statsTableRowHeaderView: figuresTableRowHeaderView,
    });

    layoutViewOptions.statsTableView = RouterHelpers.wrapInLoadingView(
      figuresTableWrapperView,
      statsCollection
    );
    layoutViewOptions.statsTableHeaderView = figuresTableHeaderView;

    return new StatsTableLayoutView(layoutViewOptions);
  },
});
