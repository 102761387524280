import * as React from 'react';
import cn from 'classnames';

import { WithScale, Scale, ScaleProps } from '../WithScale';
// utils
import { mapFontScaleClassName, buildClassNames } from '../utils';
// styles
import './index.scss';

type As = 'span' | 'p';

export interface TextProps extends ScaleProps, React.HTMLProps<HTMLElement> {
  as?: As;
  scale?: Scale;
}

export const Text: React.SFC<TextProps> = ({
  as = 'span',
  children,
  className,
  scale = 1,
  ...props
}) => {
  const textScaleClass = mapFontScaleClassName(scale);
  const textClasses = buildClassNames(['mako-text', textScaleClass, className]);

  if (as === 'p') {
    return (
      <WithScale className={textClasses} {...props}>
        {(injectedProps) => <p {...injectedProps}>{children}</p>}
      </WithScale>
    );
  }

  return (
    <WithScale className={textClasses} {...props}>
      {(injectedProps) => <span {...injectedProps}>{children}</span>}
    </WithScale>
  );
};
