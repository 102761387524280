import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { SubusersDto } from '../dtos';

export const Actions = {
  // NOTE: `getSubusers` only fetches active subusers, not _all_ subusers
  getSubusers: () => createAction(ActionTypes.GetSubusersRequest),
  getSubusersSuccess: (subusersDto: SubusersDto) =>
    createAction(ActionTypes.GetSubusersSuccess, subusersDto),
  getSubusersFailure: (errors: SubusersDto) =>
    createAction(ActionTypes.GetSubusersFailure, errors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
