var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "wide";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<h1 role=\"title\">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)))
    + "</h1>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"side-modal "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.wide : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" role=\"modal\">\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <div class=\"alert alert-danger\" role=\"flash\" data-role=\"alert-failure\" style=\"display: none;\">\n    <p>\n      <i class=\"sg-icon sg-icon-warning\" role=\"flash-icon\"></i>\n      <span role=\"flash-copy\">Sorry, an error occurred. Engineers have been notified.</span>\n      <i class=\"sg-icon sg-icon-x\" onclick=\"$(this).parents('[role=flash]').fadeOut()\"></i>\n    </p>\n  </div>\n  <br>\n"
    + ((stack1 = container.invokePartial(partials["@partial-block"],depth0,{"name":"@partial-block","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n<div class=\"side-modal-mask\" role=\"modalMask\"></div>\n";
},"usePartial":true,"useData":true});