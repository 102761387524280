import { AlertType } from '@sendgrid/ui-components/alert';

export enum AlertContentKey {
  GenericFailureContactSales = 1,
}

export interface Alert {
  // timeStamp acts as an id
  timeStamp?: number;
  type: AlertType;
  text?: string;
  contentKey?: AlertContentKey;
}

export enum ModalType {
  EmailBootcampSummaryOfWork = 'EMAIL_BOOTCAMP_SUMMARY_OF_WORK',
  EmailBootcampPurchaseSuccess = 'EMAIL_BOOTCAMP_PURCHASE_SUCCESS',
  EmailImplementationSummaryOfWork = 'EMAIL_IMPLEMENTATION_SUMMARY_OF_WORK',
  EmailImplementationPurchaseSuccess = 'EMAIL_IMPLEMENTATION_PURCHASE_SUCCESS',
}

export interface Modal {
  type: ModalType;
  props?: any;
}

export interface UI {
  alerts: Alert[];
  modals: Modal[];
}
