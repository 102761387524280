import * as React from 'react';
import cn from 'classnames';

import { WithScale, Scale, ScaleProps } from '../WithScale';
// types
import { Colors, IconType } from './types';
// styles
import './index.scss';
import { buildClassNames, mapFontScaleClassName } from '../utils';

export interface IconProps extends ScaleProps, React.HTMLProps<HTMLLIElement> {
  className?: string;
  color?: Colors;
  scale?: Scale;
  type: IconType;
}

export const Icon: React.SFC<IconProps> = ({
  className,
  color = 'slate-80',
  scale = 0,
  type,
  ...props
}) => {
  const iconScaleClass = mapFontScaleClassName(scale);
  const classNames = [
    'mako-icon',
    'sg-icon',
    `sg-icon-${type}`,
    `color-${color}`,
    iconScaleClass,
    className,
  ];
  const iconClasses = buildClassNames(classNames);
  return (
    <WithScale className={iconClasses} {...props}>
      {(injectedProps) => <i {...injectedProps} />}
    </WithScale>
  );
};
