const Marionette = require("backbone.marionette");

module.exports = Marionette.ItemView.extend({
  template: require("templates/errors/500.hbs"),

  ui: {
    refresh: "[data-role=refresh]",
  },

  events: {
    "click @ui.refresh": "refreshPage",
  },

  refreshPage() {
    window.location.reload();
  },
});
