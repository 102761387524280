var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <h2>You don't have access to this page.</h2>\n  <p>If you feel that you should have access to this page, <a role=\"contact\" href=\"mailto:"
    + container.escapeExpression(((helper = (helper = helpers.accountEmail || (depth0 != null ? depth0.accountEmail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"accountEmail","hash":{},"data":data}) : helper)))
    + "\">contact your account administrator</a>.</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.loaded : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.canTeammateRequestAccess : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "      <h2>You don't have access to this page.</h2>\n      <p>You don't have permissions to view this page. You can request access from your account administrator.</p>\n      <button class=\"btn btn-secondary\" role=\"requestAccess\">Request Access</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "      <h2>This feature is not available on your current plan.</h2>\n      <p>You'll need to upgrade to gain access.</p>\n      <button class=\"btn btn-primary\" role=\"showUpgrade\">View Plan & Billing</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"loading-icon\" role=\"loading\">\n      <div class=\"outer\"></div>\n      <div class=\"inner\"></div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div>\n  <i class=\"sg-icon sg-icon-locked is-size-40\"></i>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.restrictAccessRequest : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});