import * as React from 'react';
import { Badge } from '@sendgrid/ui-components';

import { Grid, Heading } from '../../../../components/__common__';

import './index.scss';

interface HeaderProps {
  isRecommended?: boolean;
  title: string;
}

export const Header: React.SFC<HeaderProps> = ({
  isRecommended,
  title,
  children,
}) => (
  <Grid.Column width={3} className="plan-info-header">
    <Heading as="h2" className="capitalize">
      {title}{' '}
      {isRecommended && (
        <Badge className="margin-left-scale-down-2 plan-info-badge">
          Recommended
        </Badge>
      )}
    </Heading>
    {children}
  </Grid.Column>
);
