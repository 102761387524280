import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { ContactSales } from '../types/contactSales';

export const Actions = {
  contactSalesRequest: (premierPackageRequest: ContactSales) =>
    createAction(ActionTypes.ContactSalesRequest, premierPackageRequest),
  contactSalesSuccess: () => createAction(ActionTypes.ContactSalesSuccess),
  contactSalesFailure: (errors: ContactSales) =>
    createAction(ActionTypes.ContactSalesFailure, errors),
  clearContactSalesServerErrors: () =>
    createAction(ActionTypes.ClearContactSalesServerErrors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
