var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<td class=\"stat rank-"
    + alias2(alias1((depth0 != null ? depth0.rank : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\">\n  <div role=\""
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\">\n    <span role=\"percentages\" class=\""
    + ((stack1 = helpers["if"].call(alias3,(depths[1] != null ? depths[1].displayNumbers : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/percentStat.js")).call(alias3,(depth0 != null ? depth0.key : depth0),depths[1],{"name":"percentStat","hash":{},"data":data}))
    + "</span>\n    <span role=\"numbers\" class=\""
    + ((stack1 = helpers.unless.call(alias3,(depths[1] != null ? depths[1].displayNumbers : depths[1]),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/stat.js")).call(alias3,(depth0 != null ? depth0.key : depth0),depths[1],{"name":"stat","hash":{},"data":data}))
    + "</span>\n  </div>\n  <div class=\"rate "
    + ((stack1 = helpers["if"].call(alias3,(depths[1] != null ? depths[1].displayNumbers : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"width:"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/percentStatBarWidth.js")).call(alias3,(depth0 != null ? depth0.key : depth0),depths[1],{"name":"percentStatBarWidth","hash":{},"data":data}))
    + "\"></div>\n</td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.columns : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});