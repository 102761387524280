export enum ResponseOngoingServiceNames {
  ExpertInsights = 'expert_insights',
}

export enum DisplayOngoingServiceNames {
  ExpertInsights = 'Expert Insights',
}

export interface ExpertServiceOngoing {
  name: ResponseOngoingServiceNames;
  basePrice: number;
  isPurchased: boolean;
  isPendingDowngrade: boolean;
}

export interface ExpertServicesOngoing {
  ongoingServices: ExpertServiceOngoing[];
  errorMessage: string;
}
