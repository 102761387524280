import * as React from 'react';

import './index.scss';

export type Scale =
  | 'none'
  | -3
  | -2
  | -1
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10;

interface InjectedProps {
  className: string;
}

export interface WithScaleProps extends ScaleProps {
  className?: string;
  children(props: InjectedProps): React.ReactElement<InjectedProps>;
}

export interface ScaleProps {
  m?: Scale;
  mb?: Scale;
  ml?: Scale;
  mr?: Scale;
  mt?: Scale;
  mx?: Scale;
  my?: Scale;
  p?: Scale;
  pb?: Scale;
  pl?: Scale;
  pr?: Scale;
  pt?: Scale;
  px?: Scale;
  py?: Scale;
}

enum ClassNameMap {
  m = 'margin',
  mb = 'margin-bottom',
  ml = 'margin-left',
  mr = 'margin-right',
  mt = 'margin-top',
  mx = 'margin-x',
  my = 'margin-y',
  p = 'pad',
  pb = 'pad-bottom',
  pl = 'pad-left',
  pr = 'pad-right',
  pt = 'pad-top',
  px = 'pad-x',
  py = 'pad-y',
}

function mapScaleProps(props: ScaleProps) {
  const propKeys = Object.keys(props);
  return propKeys.reduce((arr, key) => {
    const name = ClassNameMap[key as keyof typeof ClassNameMap];
    const value = props[key as keyof ScaleProps];
    if (name && value === 0) {
      return [...arr, `${name}-scale-0`];
    }
    if (name && value) {
      let scale = '';
      if (value === 'none') {
        scale = '-none';
      } else if (value > 0) {
        scale = `-scale-up-${value}`;
      } else if (value < 0) {
        scale = `-scale-down${value}`;
      }
      return [...arr, `${name}${scale}`];
    }
    return arr;
  }, []);
}

/* RenderProp implementation
The RenderProp Component involves a little more setup,
but it's also more flexible than the HOC.

SETUP:
import { WithScale, ScaleProps } from 'path/to/WithScale';
...
interface SomeComponentProps extends ScaleProps {
  text: string;
}

const SomeComponent: React.SFC<SomeComponentProps> = ({ text, ...props }) => {
  return (
    <WithScale {...props}>
      {(injectedProps) => (
        <div {...injectedProps}>{text}</div>
      )}
  );
}

API:
<SomeComponent mb={3} p={2} text="Hello, world!" />
*/

export const WithScale: React.SFC<WithScaleProps> = ({
  className,
  children,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  p,
  pb,
  pl,
  pr,
  pt,
  px,
  py,
  ...props
}) => {
  const scaleProps = { m, mb, ml, mr, mt, mx, my, p, pb, pl, pr, pt, px, py };
  const scaleClasses = mapScaleProps(scaleProps);
  // 'box-scale' is the CSS class in ./index.scss that includes our padding and margin mixins.
  // This helps keep our styles scoped to particular components and prevents leaks.
  const baseClass = ['box-scale'];
  // don't add class names unless they're passed
  const scaleClassNames = !!scaleClasses.length
    ? baseClass.concat(scaleClasses)
    : [];
  const combinedClasses = className
    ? [className, ...scaleClassNames]
    : [...scaleClassNames];

  const classNames = combinedClasses.join(' ').trim();
  return children({
    className: classNames,
    ...props,
  });
};

/* HOC Implementation
In some cases, you might want to use the HOC instead of the renderProp component.

SETUP:
import { withScale } from 'path/to/WithScale';
...
export default withScale(SomeComponent);

API:
<SomeComponent mb={3} p={2} />
*/

export const withScale = (Component: any) => (props: WithScaleProps) => {
  return (
    <WithScale {...props}>
      {(scaleProps) => <Component {...scaleProps} />}
    </WithScale>
  );
};
