const _ = require("underscore");

_.mixin({
  xor(a, b) {
    const diffA = _.difference(a, b);
    const diffB = _.difference(b, a);

    return _.union(diffA, diffB);
  },
});
