const Marionette = require("backbone.marionette");

const _ = require("underscore");
const $ = require("jquery");

module.exports = Marionette.ItemView.extend({
  template: require("templates/stats/table_header.hbs"),

  collectionEvents: {
    sync: "render",
  },

  modelEvents: {
    "change:metric": "render",
  },

  events: {
    "click @ui.percentages": "toggleTable",
    "click @ui.numbers": "toggleTable",
    "click @ui.nameFilters": "toggleNameFilter",
  },

  ui: {
    nameFilters: "[role=nameFilter]",
    dropdownMenu: "[role=nameFilterDropdownMenu]",
    percentages: "[role=percentages]",
    numbers: "[role=numbers]",
  },

  initialize() {
    this.model.set("displayMode", "numbers");
    this.filtered = {};
  },

  serializeData() {
    const data = {
      heading: this.getOption("heading"),
      metric: this.model.get("metric"),
      figuresByMetric: this.getOption("figuresByMetric"),
      displayMode: this.model.get("displayMode"),
      displayToggleIsHidden: this.getOption("displayToggleIsHidden"),
    };

    if (this.getOption("showNameFilterDropdown")) {
      data.showNameFilterDropdown = true;
      const metric = this.model.get("metric");
      const rankedList = this.collection.getRankedList(metric);
      const unchecked = [];
      const checked = [];

      for (let i = 0; i < rankedList.length; i++) {
        rankedList[i].checked =
          i < 10 && !this.filtered[rankedList[i].statName];

        if (!rankedList[i].checked) {
          unchecked.push(rankedList[i].statName);
        } else {
          checked.push(rankedList[i].statName);
        }
      }
      this.trigger("unselected", unchecked);
      this.trigger("selected", checked);
      data.filters = _.sortBy(rankedList, "statName");
    }

    return data;
  },

  toggleTable(event) {
    this.ui.numbers.removeClass("is-active");
    this.ui.percentages.removeClass("is-active");

    const $target = $(event.currentTarget);
    $target.addClass("is-active");

    this.model.set("displayMode", $target.attr("role"));
  },

  toggleNameFilter(event) {
    event.stopPropagation();
    const $nameFilter = $(event.currentTarget);
    const filter = $nameFilter.data("filter");

    if ($nameFilter.hasClass("inactive")) {
      delete this.filtered[filter];
      this.trigger("selected", filter);
      $nameFilter.removeClass("inactive");
      $nameFilter.find("input[type=checkbox]").prop("checked", true);
    } else {
      this.filtered[filter] = true;
      this.trigger("unselected", filter);
      $nameFilter.addClass("inactive");
      $nameFilter.find("input[type=checkbox]").prop("checked", false);
    }
    return false;
  },
});
