import { ActionTypes } from './actionTypes';
import { TeammateRequestAccessScope } from '../types/teammateRequestAccess';
import { TeammateRequestAccessScopeDto } from '../dtos';
import { ActionsUnion, createAction } from '../actionBuilder';

export const Actions = {
  teammateRequestAccessRequest: (
    teammateRequestAccess: TeammateRequestAccessScope
  ) =>
    createAction(
      ActionTypes.TeammateRequestAccessRequest,
      teammateRequestAccess
    ),
  teammateRequestAccessSuccess: () =>
    createAction(ActionTypes.TeammateRequestAccessSuccess),
  teammateRequestAccessFailure: (errors: TeammateRequestAccessScopeDto) =>
    createAction(ActionTypes.TeammateRequestAccessFailure, errors),
  clearTeammateRequestAccess: () =>
    createAction(ActionTypes.ClearTeammateRequestAccess),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
