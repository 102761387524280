const Marionette = require("backbone.marionette");

module.exports = Marionette.ItemView.extend({
  template: require("templates/stats/stats_empty_data.hbs"),

  serializeData() {
    const data = {};
    data.messageText = this.getOption("messageText");
    data.subMessageText = this.getOption("subMessageText");
    return data;
  },
});
