import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { Alert, Modal } from '../types/ui';

export const Actions = {
  createAlert: (alert: Alert) => createAction(ActionTypes.CreateAlert, alert),
  deleteAlert: (alert: Alert) => createAction(ActionTypes.DeleteAlert, alert),
  showModal: (modal: Modal) => createAction(ActionTypes.ShowModal, modal),
  hideModal: () => createAction(ActionTypes.HideModal),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
