import React from 'react';
import cn from 'classnames';

import './index.scss';

export interface BannerMessageProps {
  className?: string;
  type: 'beta' | 'info' | 'warning';
}

export const BannerMessage: React.SFC<BannerMessageProps> = ({
  className,
  type,
  children,
}) => (
  <div className={cn('banner-message', className, `banner-message-${type}`)}>
    {children}
  </div>
);
