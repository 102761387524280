import * as React from 'react';
import { Divider, SideModal, Alert, Loader } from '@sendgrid/ui-components';
import { Link } from 'react-router-dom';

import { Package } from '../../../state/types/package';
import { UserPackage } from '../../../state/types/user';

import {
  BannerMessage,
  Heading,
  PageLayout,
  Text,
} from '../../../components/__common__';
import { EssentialsPlan } from './EssentialsPlan';
import { FreePlan } from './FreePlan';
import { PremierPlan } from './PremierPlan';
import { PremierPlanContactForm } from './PremierPlanContactForm';
import { ProPlan } from './ProPlan';

import ChoosePlanContainer, {
  ChoosePlanContainerProps,
  ChoosePlanContainerState,
} from './Container';
import { UpdatePlan, UpdatePlanProps } from './UpdatePlan';
import { ContactSalesInfo } from '../../../state/types/contactSales';

import primaryReasons, {
  PrimaryReason,
} from './DowngradeToFreePlanModal/SurveyReasonStep/primaryReasons';
import { SurveyReasonStep } from './DowngradeToFreePlanModal/SurveyReasonStep';
import { DowngradeEmailActivityStep } from './DowngradeToFreePlanModal/DowngradeEmailActivityStep';
import { ContactDeletionStep } from './DowngradeToFreePlanModal/ContactDeletionStep';
import DowngradeToFreePlanModal from './DowngradeToFreePlanModal';

import NoSubuserAccessCard from '../../../components/Billing/NoSubuserAccessCard';
import TeammateRequestAccessCard from '../../../components/Billing/TeammateRequestAccessCard';

import { WriteSelectors } from './qahooks';
import './index.scss';
import { AccountAndPlanUtils } from '../utils';
import packageConfig from '../../../state/utils/packageConfig';

export interface ChoosePlanProps
  extends ChoosePlanContainerProps,
    ChoosePlanContainerState {
  packages: Package[];
  userPackage: UserPackage;
  contactSalesInfo: ContactSalesInfo;
  clearContactSalesServerErrors: any;
}

interface ChoosePlanState {
  isModalOpen: boolean;
  updatePlanInfo: UpdatePlanProps;
  downgradeToFreePlanModal: DowngradeToFreePlanModal;
}

interface DowngradeToFreePlanModal {
  isOpen: boolean;
  isOnContactDeletionStep: boolean;
  contactDeletionStep: ContactDeletionStep;
  isOnDowngradeEmailActivityStep: boolean;
  downgradeEmailActivityStep: DowngradeEmailActivityStep;
  isOnSurveyReasonStep: boolean;
  surveyReasonStep: SurveyReasonStep;
}

export enum UpdatePlanType {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
}

export enum FuncFactoryTypes {
  ContactSales = 'ContactSales',
}

export const freePlanName = 'Free 100';

export class ChoosePlan extends React.Component<
  ChoosePlanProps,
  ChoosePlanState
> {
  constructor(props: ChoosePlanProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      updatePlanInfo: {} as UpdatePlanProps,
      downgradeToFreePlanModal: {
        isOpen: false,
        isOnContactDeletionStep: false,
        contactDeletionStep: {
          understandsContactsDeletedChecked: false,
          understandsExportContactsChecked: false,
          understandsInvoiceChecked: false,
        },
        isOnDowngradeEmailActivityStep: false,
        downgradeEmailActivityStep: {
          understandsThreeDaysEaseChecked: false,
        },
        isOnSurveyReasonStep: false,
        surveyReasonStep: {
          primaryReason: '',
          randomizedPrimaryReasons: primaryReasons,
        },
      },
    };
  }

  public wasUpdateSuccessful(nextProps: ChoosePlanProps): boolean {
    return nextProps.updatedPlan.isUpdated;
  }

  public hasDowngradeToFreePlanError(nextProps: ChoosePlanProps): boolean {
    return (
      nextProps.updatedPlan.errors &&
      nextProps.updatedPlan.errors.length > 0 &&
      nextProps.updatedPlan.errors[0].field === freePlanName
    );
  }

  public shouldComponentUpdate(
    nextProps: Readonly<ChoosePlanProps>,
    nextState: Readonly<ChoosePlanState>
  ): boolean {
    if (this.hasDowngradeToFreePlanError(nextProps)) {
      (window as any).location.href = '/account/billing?updateError';
    } else if (this.wasUpdateSuccessful(nextProps)) {
      (window as any).location.href = '/account/billing?updateSuccess';
    }
    return true;
  }

  public packageSelectHandler = (event: React.MouseEvent): void | undefined => {
    const { id } = event.currentTarget as Element;
    const {
      packages,
      premierPackages,
      getEaseUpdatedPricing,
      clearDeleteEaseErrors,
      clearUpdatePlanErrors,
      clearPaymentFormErrors,
      getPaymentForm,
      createSubscriptionPayment,
    } = this.props;
    const newPackage = [...packages, ...premierPackages].find(
      (packageToFind) => packageToFind.id === id
    );

    if (!newPackage) {
      return;
    }

    let isFreeUser = false;
    if (
      AccountAndPlanUtils.doesUserHaveFreePackage(
        this.props.userPackage,
        packages
      )
    ) {
      isFreeUser = true;
    }

    const info = {
      currentPackage: this.props.userPackage,
      newPackage,
      packages,
      updatePlanFunc: this.props.updatePlan,
      cancel: this.toggleModal,
      getEaseUpdatedPricing,
      clearDeleteEaseErrors,
      clearUpdatePlanErrors,
      getPaymentForm,
      clearPaymentFormErrors,
      createSubscriptionPayment,
      isFreeUser,
    } as UpdatePlanProps;

    this.setState({
      ...this.state,
      updatePlanInfo: info,
      isModalOpen: true,
    });

    /**
     * make api calls that are needed for upgrading,
     * this is the safest place to do it. the side modal mounts
     * when this component mounts, so we can't use whenComponentMounts
     * and shouldComponentUpdate isn't a safe place to make async calls
     * as it's invoked for every reconciliation check
     */
    getEaseUpdatedPricing(info.newPackage.id);

    if (isFreeUser) {
      getPaymentForm();
    }
  };

  public funcFactory = (type: FuncFactoryTypes) => {
    return this.props.contactSales;
  };

  public render() {
    const {
      clearContactSalesServerErrors,
      contactSalesInfo,
      deleteEase,
      isSubuser,
      isTeammateWithUpdateAccess,
      loading,
      packages,
      paymentForm,
      premierPackages,
      subscriptionPayment,
      teammateRequestAccessInfo,
      updatePlan,
      updatedEasePricing,
      updatedPlan,
      userEase,
      userPackage,
    } = this.props;

    const { downgradeToFreePlanModal } = this.state;

    const { errors } = contactSalesInfo;
    const contactSalesError = errors && errors.contactSalesErrorMessage;
    const normalPackageIds = packages.reduce(
      (acc, { id }) => ({ [id]: id, ...acc }),
      {}
    );
    const packageIds = {
      ...normalPackageIds,
      ...packageConfig.premierPackages,
    };
    const isCurrentPlanDeprecated =
      !!packages.length && !(userPackage.packageId in packageIds);
    const hasEase = userEase.hasPurchasedEase;
    const showSubuserView = isSubuser && !loading;
    const showTeammateRequestAccessView =
      !isSubuser && !isTeammateWithUpdateAccess && !loading;
    const showNormalUserView =
      !isSubuser && isTeammateWithUpdateAccess && !loading;
    const isPremierPackage =
      userPackage && userPackage.packageId in packageConfig.premierPackages;
    const isPremierPackageOver25M =
      isPremierPackage &&
      userPackage.packageId in packageConfig.premierPackagesOver25M;
    // users
    if (isPremierPackageOver25M) {
      (window as any).location.href = '/account/billing';
    }

    return (
      <React.Fragment>
        <div
          className={`error-banner-container alert alert-danger ${
            contactSalesError ? 'is-visible' : 'is-hidden'
          } `}
        >
          {contactSalesError && (
            <Alert
              type="danger"
              className="alert alert-danger tiara-offset"
              onClick={clearContactSalesServerErrors}
              {...WriteSelectors.errorBanner}
            >
              {contactSalesError}
            </Alert>
          )}
        </div>
        <SideModal isOpen={this.state.isModalOpen} onClose={this.toggleModal}>
          <Heading as="h2">Billing Information</Heading>
          <UpdatePlan
            {...this.state.updatePlanInfo}
            deleteEase={deleteEase}
            newEasePricing={updatedEasePricing}
            userEase={userEase}
            updatedPlanResponse={updatedPlan}
            subscriptionPayment={subscriptionPayment}
            paymentForm={paymentForm}
          />
        </SideModal>
        <PageLayout shouldScrollX>
          <Text mb={-1}>
            <Link to="/account/billing">Plan & Billing</Link>
          </Text>
          <Heading as="h1">Choose Your Plan</Heading>
          {loading && <Loader centered {...WriteSelectors.loader} />}
          {showSubuserView && (
            <div
              {...WriteSelectors.noSubuserAccessContainer}
              className="no-subuser-access-card-container"
            >
              <NoSubuserAccessCard />
            </div>
          )}
          {showTeammateRequestAccessView && (
            <div
              {...WriteSelectors.teammateRequestAccessContainer}
              className="teammate-request-access-card-container"
            >
              <TeammateRequestAccessCard
                {...teammateRequestAccessInfo}
                requestAccess={this.handleTeammateRequestAccess}
              />
            </div>
          )}
          {showNormalUserView && (
            <React.Fragment>
              {isCurrentPlanDeprecated && (
                <BannerMessage
                  className="margin-bottom-scale-up-5"
                  type="warning"
                >
                  You are currently on a{' '}
                  <strong>{userPackage.name} plan</strong>, which we no longer
                  offer. You may remain on this plan or choose from one of our
                  other plans below.
                </BannerMessage>
              )}
              {isPremierPackage && (
                <React.Fragment>
                  <PremierPlan
                    packages={premierPackages}
                    userPackage={userPackage}
                    onPackageSelect={this.packageSelectHandler}
                  />
                  <Divider />
                  <Text className="justify-center align-center">
                    <i className="sg-icon sg-icon-help pad-x-scale-down-3" />
                    Need more volume or want to switch to a non-Premier plan?
                    Please reach out to your CSM.
                  </Text>
                </React.Fragment>
              )}
              {!isPremierPackage && (
                <React.Fragment>
                  <FreePlan
                    packages={packages}
                    userPackage={userPackage}
                    onPackageSelect={this.openDowngradeToFreePlanModal}
                    {...WriteSelectors.freePlan}
                  />
                  <Divider />
                  <EssentialsPlan
                    packages={packages}
                    userPackage={userPackage}
                    onPackageSelect={this.packageSelectHandler}
                    {...WriteSelectors.essentialsPlan}
                  />
                  <Divider />
                  <ProPlan
                    packages={packages}
                    userPackage={userPackage}
                    onPackageSelect={this.packageSelectHandler}
                    {...WriteSelectors.proPlan}
                  />
                  <Divider />
                  <PremierPlanContactForm
                    submitFuncFactory={this.funcFactory}
                    contactSalesInfo={this.props.contactSalesInfo}
                    {...WriteSelectors.premierPlan}
                  />
                  <Divider />
                  <Text
                    className="justify-center align-center"
                    {...WriteSelectors.needMoreInfo}
                  >
                    <i className="sg-icon sg-icon-help pad-x-scale-down-3" />{' '}
                    Need more information? Check out our{' '}
                    <a
                      className="pad-left-scale-down-3"
                      href="https://sendgrid.com/pricing/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      full plan comparison
                    </a>
                    .
                  </Text>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </PageLayout>
        <DowngradeToFreePlanModal
          {...downgradeToFreePlanModal}
          loading={updatedPlan.isUpdatingPlan}
          updatePlan={updatePlan}
          downgradeToFreePlan={this.handleDowngradeToFreePlan}
          exitDowngradeToFreePlanModal={this.exitDowngradeToFreePlanModal}
          goToDowngradeEmailActivityStep={this.goToDowngradeEmailActivityStep}
          goToSurveyReasonStep={this.goToSurveyReasonStep}
          handleContactDeletionCheckboxChange={
            this.handleContactDeletionCheckboxChange
          }
          handleDowngradeEmailActivityCheckboxChange={
            this.handleDowngradeEmailActivityCheckboxChange
          }
          handleSurveyReasonRadioChange={this.handleSurveyReasonRadioChange}
          hasEase={hasEase}
          {...WriteSelectors.downgradeToFreePlanModal}
        />
      </React.Fragment>
    );
  }

  private handleTeammateRequestAccess = () => {
    const { teammateRequestAccess } = this.props;
    const scopeGroupName = 'Billing';
    teammateRequestAccess({ scopeGroupName });
  };

  private toggleModal = (event: React.MouseEvent) => {
    this.setState((current: any) => ({
      isModalOpen: !current.isModalOpen,
    }));
  };

  private getDowngradePlanPackageId = (
    name: string,
    packages: Package[]
  ): string => {
    const downgradePackage = _.findWhere(packages, { name });
    return (downgradePackage && downgradePackage.id) || '';
  };

  private handleDowngradeToFreePlan = () => {
    const { downgradeToFreePlanModal } = this.state;
    const { surveyReasonStep } = downgradeToFreePlanModal;

    const { primaryReason } = surveyReasonStep;

    const packageId = this.getDowngradePlanPackageId(
      freePlanName,
      this.props.packages
    );

    const downgradeToFreePlanInfo = {
      reason: primaryReason,
      packageId,
      packageName: freePlanName,
    };

    this.props.updatePlan(downgradeToFreePlanInfo);
  };

  private openDowngradeToFreePlanModal = (
    event: React.MouseEvent
  ): void | undefined => {
    const { downgradeToFreePlanModal } = this.state;
    const { surveyReasonStep } = downgradeToFreePlanModal;
    const {
      randomizedPrimaryReasons: previousRandomizedPrimaryReasons,
    } = surveyReasonStep;
    const randomizedPrimaryReasons = _.shuffle(
      previousRandomizedPrimaryReasons
    ) as PrimaryReason[];
    const { contacts, userEase } = this.props;
    const maxFreeMcContacts = 2000;
    const hasPaidMcContacts = contacts.total > maxFreeMcContacts;
    const hasEase = userEase.hasPurchasedEase;

    if (hasPaidMcContacts) {
      this.setState({
        downgradeToFreePlanModal: {
          ...downgradeToFreePlanModal,
          surveyReasonStep: {
            ...surveyReasonStep,
            randomizedPrimaryReasons,
          },
          isOpen: true,
          isOnContactDeletionStep: true,
        },
      });
    } else if (hasEase) {
      this.setState({
        downgradeToFreePlanModal: {
          ...downgradeToFreePlanModal,
          surveyReasonStep: {
            ...surveyReasonStep,
            randomizedPrimaryReasons,
          },
          isOpen: true,
          isOnDowngradeEmailActivityStep: true,
        },
      });
    } else {
      this.setState({
        downgradeToFreePlanModal: {
          ...downgradeToFreePlanModal,
          surveyReasonStep: {
            ...surveyReasonStep,
            randomizedPrimaryReasons,
          },
          isOpen: true,
          isOnSurveyReasonStep: true,
        },
      });
    }
  };

  private goToDowngradeEmailActivityStep = () => {
    const { downgradeToFreePlanModal } = this.state;
    this.setState({
      downgradeToFreePlanModal: {
        ...downgradeToFreePlanModal,
        isOnContactDeletionStep: false,
        isOnDowngradeEmailActivityStep: true,
        isOnSurveyReasonStep: false,
      },
    });
  };

  private goToSurveyReasonStep = () => {
    const { downgradeToFreePlanModal } = this.state;
    this.setState({
      downgradeToFreePlanModal: {
        ...downgradeToFreePlanModal,
        isOnContactDeletionStep: false,
        isOnDowngradeEmailActivityStep: false,
        isOnSurveyReasonStep: true,
      },
    });
  };

  private exitDowngradeToFreePlanModal = () => {
    const {
      downgradeToFreePlanModal: {
        surveyReasonStep: { randomizedPrimaryReasons },
      },
    } = this.state;

    this.setState({
      downgradeToFreePlanModal: {
        isOpen: false,
        isOnContactDeletionStep: false,
        contactDeletionStep: {
          understandsContactsDeletedChecked: false,
          understandsExportContactsChecked: false,
          understandsInvoiceChecked: false,
        },
        isOnDowngradeEmailActivityStep: false,
        downgradeEmailActivityStep: {
          understandsThreeDaysEaseChecked: false,
        },
        isOnSurveyReasonStep: false,
        surveyReasonStep: {
          primaryReason: '',
          randomizedPrimaryReasons,
        },
      },
    });
    this.props.clearUpdatePlanErrors();
  };

  private handleContactDeletionCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const { downgradeToFreePlanModal } = this.state;
    const { contactDeletionStep } = downgradeToFreePlanModal;
    const { id, checked } = event.currentTarget;

    this.setState({
      downgradeToFreePlanModal: {
        ...downgradeToFreePlanModal,
        contactDeletionStep: {
          ...contactDeletionStep,
          [id]: checked,
        } as ContactDeletionStep,
      } as DowngradeToFreePlanModal,
    });
  };

  private handleDowngradeEmailActivityCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const { downgradeToFreePlanModal } = this.state;
    const { downgradeEmailActivityStep } = downgradeToFreePlanModal;
    const { id, checked } = event.currentTarget;

    this.setState({
      downgradeToFreePlanModal: {
        ...downgradeToFreePlanModal,
        downgradeEmailActivityStep: {
          ...downgradeEmailActivityStep,
          [id]: checked,
        } as DowngradeEmailActivityStep,
      } as DowngradeToFreePlanModal,
    });
  };

  private handleSurveyReasonRadioChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const { downgradeToFreePlanModal } = this.state;
    const { surveyReasonStep } = downgradeToFreePlanModal;
    const { value } = event.currentTarget;

    this.setState({
      downgradeToFreePlanModal: {
        ...downgradeToFreePlanModal,
        surveyReasonStep: {
          ...surveyReasonStep,
          primaryReason: value,
        } as SurveyReasonStep,
      } as DowngradeToFreePlanModal,
    });
  };
}

export default ChoosePlanContainer(ChoosePlan);
