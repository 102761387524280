import React from 'react';

export interface PrimaryReason {
  value: string;
  label: React.ReactNode;
}

const primaryReasons = [
  {
    value: 'free package is a better fit for my usage',
    label: (
      <span>
        The free plan is a better fit for my <strong>usage</strong>
      </span>
    ),
  },
  {
    value: 'taking a break from sending seasonal/project-based business',
    label: (
      <span>
        I'm taking a break from sending{' '}
        <strong>seasonal/project-based business</strong>
      </span>
    ),
  },
  {
    value: 'have implementation issues',
    label: (
      <span>
        I'm having <strong>implementation issues</strong>
      </span>
    ),
  },
  {
    value: 'i have multiple accounts',
    label: (
      <span>
        I have <strong>multiple accounts</strong>
      </span>
    ),
  },
] as PrimaryReason[];

export default primaryReasons;
