var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <th class=\"group\">\n        <span>\n          Group\n        </span>\n      </th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <th class=\"reason\">\n        <span>\n          Reason\n        </span>\n      </th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "          "
    + container.escapeExpression(__default(require("/opt/sendgrid/mako/src/templates/helpers/paginationLinks.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"paginationLinks","hash":{"queryParams":(depth0 != null ? depth0.queryParams : depth0),"lastPage":(depth0 != null ? depth0.lastPage : depth0),"currentPage":(depth0 != null ? depth0.currentPage : depth0),"limit":(depth0 != null ? depth0.limit : depth0)},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"suppression-list data-table\">\n  <thead>\n    <tr>\n      <th class=\"checkbox-cell\">\n"
    + ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/checkbox.hbs"),depth0,{"name":"checkbox","hash":{"style":"display: inline;","label":"","checked":(depth0 != null ? depth0.checked : depth0),"role":"selectAllBoxes","name":"selectAllBoxes","id":"selectAllBoxes"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </th>\n      <th class=\"contact\" style=\"overflow: visible\">\n"
    + ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/input.hbs"),depth0,{"name":"input","hash":{"is_full_width":"true","is_search":"true","label":"Search by email","container_style":"margin-bottom: -19px; top: -18px","container_role":"searchEmailContainer"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showGroup : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <th class=\"time\">\n        <span>\n          Time\n        </span>\n      </th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showReason : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tr>\n  </thead>\n  <tbody role=\"suppressions\">\n    <tr class=\"hidden searchDisplayBar\" role=\"searchDisplayBar\">\n      <td colspan=\"4\">\n        <div class=\"searchDisplayBarText\"></div>\n        <div class=\"searchDisplayButton\"><i class=\"icon-add-circle\"></i></div>\n      </td>\n    </tr>\n  </tbody>\n  <tfoot>\n    <tr>\n      <td colspan=\"5\">\n        <div class=\"pagination\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lastPage : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n      </td>\n    </tr>\n  </tfoot>\n</table>\n";
},"usePartial":true,"useData":true});