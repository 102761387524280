import * as React from 'react';

import './index.scss';

export interface HeadingProps extends React.HTMLProps<HTMLHeadingElement> {
  as: 'h1' | 'h2' | 'h3' | 'h4';
  children: React.ReactNode | string;
}

export const Heading: React.SFC<HeadingProps> = ({
  as,
  children,
  ...props
}) => {
  switch (as) {
    case 'h1': {
      return <h1 {...props}>{children}</h1>;
    }
    case 'h2': {
      return <h2 {...props}>{children}</h2>;
    }
    case 'h3': {
      return <h3 {...props}>{children}</h3>;
    }
    case 'h4': {
      return <h4 {...props}>{children}</h4>;
    }
    default: {
      console.error(`'${as}' is not a valid value. Please use h1 - h4.`);
      return null;
    }
  }
};
