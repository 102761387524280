export enum UpdateInvoiceAddressActionTypes {
  UpdateInvoiceAddress = 'updateInvoiceAddress',
  UpdateInvoiceAddressRequest = 'updateInvoiceAddress.request',
  UpdateInvoiceAddressSuccess = 'updateInvoiceAddress.success',
  UpdateInvoiceAddressFailure = 'updateInvoiceAddress.failure',

  ClearInvoiceAddressServerErrors = 'clearInvoiceAddressServerErrors',
}

export enum GetInvoiceAddressActionTypes {
  GetInvoiceAddress = 'getInvoiceAddress',
  GetInvoiceAddressRequest = 'getInvoiceAddress.request',
  GetInvoiceAddressSuccess = 'getInvoiceAddress.success',
  GetInvoiceAddressFailure = 'getInvoiceAddress.failure',
}

export const ActionTypes = {
  ...GetInvoiceAddressActionTypes,
  ...UpdateInvoiceAddressActionTypes,
};
