const Marionette = require("backbone.marionette");

const APIConfig = require("apiconfig");
const constants = require("../constants");

module.exports = Marionette.LayoutView.extend({
  template: require("templates/error_layout.hbs"),

  regions: {
    contentView: "[role=content]",
  },

  onShow() {
    this.contentView.show(this.getOption("contentView"));
  },

  serializeData() {
    return {
      sg_url: APIConfig.sendgrid_com,
      show_logo: this.getOption("showLogo"),
      title: this.getOption("title"),
      SGLogo: constants.TWILIO_SENDGRID_LOGO,
      LogoAlt: constants.LOGO_ALT,
    };
  },
});
