import { createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { AddExpertServiceImplementationDto } from '../dtos';
import { AddExpertServiceImplementationRequest } from '../types/addExpertServiceImplementation';

export const Actions = {
  addExpertServiceImplementation: (
    addExpertServiceImplementationRequestBody: AddExpertServiceImplementationRequest
  ) =>
    createAction(
      ActionTypes.AddExpertServiceImplementationRequest,
      addExpertServiceImplementationRequestBody
    ),
  addExpertServiceImplementationSuccess: () =>
    createAction(ActionTypes.AddExpertServiceImplementationSuccess),
  addExpertServiceImplementationFailure: (
    errors: AddExpertServiceImplementationDto
  ) => createAction(ActionTypes.AddExpertServiceImplementationFailure, errors),
};
