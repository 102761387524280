const Marionette = require("backbone.marionette");

module.exports = Marionette.LayoutView.extend({
  template: require("templates/suppressions_layout.hbs"),

  regions: {
    suppresionsHeader: "[role=suppressionsHeader]",
    suppressionsList: "[role=suppressionsList]",
  },

  onShow() {
    this.suppresionsHeader.show(this.getOption("suppressionsHeaderView"));
    this.suppressionsList.show(this.getOption("suppressionsListView"));
  },
});
