import { IMPERSONATING_USERNAME_COOKIE } from '../constants';
import { CookieManager } from './CookieManager';

const MAKO_COOKIE_NAME = 'mako_auth_token';

const getCookies = () => {
  return document.cookie
    .split(';')
    .map((ele) => {
      const [key, value] = ele.trim().split('=');
      return { [key]: value };
    })
    .reduce((one, two) => ({ ...one, ...two }));
};

const cookies = getCookies();
const { [MAKO_COOKIE_NAME]: authCookie = null } = cookies;

let onBehalfOf = {};
// jQuery is available (Not in jest)
if ((window as any).$ && (window as any).$.cookie) {
  // We're using $.cookie in the raw because Tiara Isn't init'd in the case
  // of Redux. This means jQuery isn't loaded in Tiara so we can't use it until
  // it is init'd. This is a silly thing that should be managed by the manager
  // of cookies (Dandalf)
  const impersonatedUser = (window as any).$.cookie(
    IMPERSONATING_USERNAME_COOKIE
  );
  if (impersonatedUser) {
    onBehalfOf = { 'On-behalf-of': impersonatedUser };
  }
}

// Probably a bad typing but not really sure how to resolve better
const defaultJSONHeaders = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `token ${authCookie}`,
    ...onBehalfOf,
  } as any,
};

// When we change the mako_auth_token after logging in i.e. changing password,
// we need to make sure our Authorization header has the updated cookie in fetches
// or else 401s will occur
export const updateMakoAuthCookieHeader = () => {
  const updatedCookies = getCookies();
  const updatedAuthCookie = updatedCookies[MAKO_COOKIE_NAME] || null;
  defaultJSONHeaders.headers.Authorization = `token ${updatedAuthCookie}`;
};

export default defaultJSONHeaders;
