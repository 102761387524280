// both prod and staging IDs included; user with one of these packages will not see the 'Change Plan' button or EASE section
const premierPackagesOver50M = {
  // staging - legacy type plans
  "8b8625ad-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 100M Package",
  "8b863d89-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 200M Package",
  "8b8fb996-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 300M Package",
  "8b910245-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 400M Package",
  "8b9cbf14-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 500M Package",

  // staging - current type plans
  "c0dd906b-c314-407b-8d6e-5809344612a4": "Premier - 100M",
  "18b9583f-e0b4-4116-ae7a-2e256191380b": "Premier - 200M",
  "d3d38d94-90dc-4222-b563-bf5b264bcf5d": "Premier - 300M",
  "c6d00576-d605-4d45-a660-e2648d60ed5e": "Premier - 400M",
  "8a716102-e1fb-494b-8d35-6c9792addcb4": "Premier - 500M",
  "03d76598-6c8b-410e-8ff7-fd065424b1be": "Premier - 600M",

  // prod - legacy type plans
  // eslint-disable-next-line
  // eslint-disable-next-line
  "8b8625ad-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 100M Package",
  // eslint-disable-next-line
  "8b863d89-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 200M Package",
  // eslint-disable-next-line
  "8b8fb996-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 300M Package",
  // eslint-disable-next-line
  "8b910245-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 400M Package",
  // eslint-disable-next-line
  "8b9cbf14-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 500M Package",

  // prod - current type plans
  "59990257-e139-47ca-8ce2-8dbc1cc06fe0": "Premier - 100M",
  "7a417492-9188-4d34-b754-57807cbee831": "Premier - 200M",
  "4483457b-8f26-4055-82f0-44887b372e06": "Premier - 300M",
  "0e4cd4b3-20db-413a-bf74-5392aeb731b2": "Premier - 400M",
  "9cea7417-5102-4013-a97f-754580fd64be": "Premier - 500M",
  "651510ad-8665-4842-bf9a-2dc0976497cd": "Premier - 600M",
};

// both prod and staging IDs included; user with one of these packages will not see the 'Change Plan' button
const premierPackagesOver25M = {
  ...premierPackagesOver50M,
  // staging - legacy type plans
  "8b860844-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 50M Package",
  // staging - current type plans
  "c0c444da-499a-4f17-a7f7-ac2ce4771923": "Premier - 50M",
  // prod - legacy type plans
  // eslint-disable-next-line
  "8b860844-ce8a-11e4-b4e5-5fcde71ee009": "Premier 50M Package",
  // prod - current type plans
  "130066f7-112c-4fdd-8074-f8260485f7c8": "Premier - 50M",
};

const premierPackages25MAndUnder = {
  // Legacy
  // staging
  "8b85bfcc-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 2.5M Package",
  "8b85cd0e-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 3M Plan",
  "8b85d7ea-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 5M Package",
  "8b85e7c0-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 10M Package",
  "8b85ebff-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 25M Package",
  // production (IDs are the same as staging, legacy plans)
  // eslint-disable-next-line
  "8b85bfcc-ce8a-11e4-b4e5-5fcde71ee009": "Premier 2.5M Package",
  // eslint-disable-next-line
  "8b85cd0e-ce8a-11e4-b4e5-5fcde71ee009": "Premier 3M Package",
  // eslint-disable-next-line
  "8b85d7ea-ce8a-11e4-b4e5-5fcde71ee009": "Premier 5M Package",
  "8b8a2328-ce8a-11e4-b4e5-5fcde71ee009": "Premier 7.5 M Package",
  // eslint-disable-next-line
  "8b85e7c0-ce8a-11e4-b4e5-5fcde71ee009": "Premier 10M Package",
  "8b89fb2b-ce8a-11e4-b4e5-5fcde71ee009": "Premier - 15M Package",
  "8b8a1857-ce8a-11e4-b4e5-5fcde71ee009": "Premier - 20M Package",
  // eslint-disable-next-line
  "8b85ebff-ce8a-11e4-b4e5-5fcde71ee009": "Premier 25M Package",
  // Current Plans
  // staging
  "0f302e1d-4df5-457b-bc68-78990ea9124b": "Premier - 5M",
  "bea64b21-50df-4dc7-8b6d-b52bdd3a3d9c": "Premier - 7.5M",
  "7f5e1809-c3e1-42d8-84b0-ecb03fe60411": "Premier - 10M",
  "37e8b4f2-243c-4ab3-8f3f-274a11c9f725": "Premier - 15M",
  "05e0b81e-902b-4abd-bac7-697caee47891": "Premier - 20M",
  "d2999580-8427-4973-b073-bdf86736a125": "Premier - 25M",
  // production
  "401aee44-5d1a-4d7f-9b64-8196cbe7bd76": "Premier - 5M",
  "0a0bc258-4980-45c7-9839-eaed27a92814": "Premier - 7.5M",
  "a7856473-f98a-421a-9810-cd16905fc49a": "Premier - 10M",
  "e79cd326-4193-4e8c-b461-207d3da437b6": "Premier - 15M",
  "f2ae9db6-731b-45f6-b234-fde67c317d28": "Premier - 20M",
  "c18fdaee-c23a-4611-a4ee-b31778d8c2a1": "Premier - 25M",
};

const packageConfig = {
  hiddenPackages: {
    "b545e102-055d-4c7e-9b3e-fe09e538f384": "Pro 2.5 Million Legacy",
    "8b9ce1c9-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 40k Old Pricing",
    "8b9cf53b-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 100k Old Pricing",
  },
  freePackages: {
    "8b9ae93b-ce8a-11e4-b4e5-5fcde71ee009": "Free 12K",
    "02a5084c-94d7-458d-9a88-1d2cfd37b262": "Free 100",
    "71713987-9f01-4dea-b3d4-8d0bcd9d53ed": "Trial 40K",
    "8ad8c03d-7f87-4fb7-8767-87454519efdc": "AWS Pro",
    "ba389e91-2ac8-41e3-8dcf-d734aea21ff8": "AWS Basic",
    "8b9b079e-ce8a-11e4-b4e5-5fcde71ee009": "Free Package GitHub Education",
  },
  essentialsPackages: {
    "8b9ce1c9-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 40k Old Pricing", // also in hiddenPackages
    "8b9cf53b-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 100k Old Pricing", // also in hiddenPackages
    "441b7834-59bc-4d25-8b54-7ff1f6c0e3c7": "Essentials 40k",
    "a99239c9-1e90-4d83-9686-617feff18a05": "Essentials 100k",
  },
  proPackages: {
    "8b9d04af-ce8a-11e4-b4e5-5fcde71ee009": "Pro 100K",
    "8b9d0cf5-ce8a-11e4-b4e5-5fcde71ee009": "Pro 300K",
    "8b9d1f56-ce8a-11e4-b4e5-5fcde71ee009": "Pro 700K",
    "0c37e79f-8c17-453a-b72d-061ca2fa5c54": "Pro 1.5 Million",
    "fddb569d-5745-48cd-af8c-e78ba8e3b478": "Pro 2.5 Million",
  },
  adsPackages: {
    "76559ee6-9cbc-42ee-abb7-02efb53672f5": "Ads",
    "97982829-c3ea-40af-b989-e9905943c0d5": "Ads",
    "1c139ecc-7717-4037-ac53-e9377cb58adc": "Ads",
  },
  premierPackages: {
    ...premierPackages25MAndUnder,
    ...premierPackagesOver25M,
  },
  premierPackages25MAndUnder,
  premierPackagesOver25M,
  premierPackagesOver50M,
  awsPackages: {
    pro: {
      adjusted_price: 80.0,
      base_price: 80.0,
      credits: 0,
      description: "",
      id: "8ad8c03d-7f87-4fb7-8767-87454519efdc",
      name: "AWS Pro",
      is_aws: true,
      newsletter_price: 0.0,
      overage_price: 0.0,
      plan_type: "paid",
      total: 80.0,
    },
    basic: {
      adjusted_price: 10.0,
      base_price: 10.0,
      credits: 0,
      description: "",
      id: "ba389e91-2ac8-41e3-8dcf-d734aea21ff8",
      name: "AWS Basic",
      is_aws: true,
      newsletter_price: 0.0,
      overage_price: 0.0,
      plan_type: "paid",
      total: 10.0,
    },
  },
  adsDefaultPackage: {
    adjusted_price: 50.0,
    base_price: 50.0,
    credits: 0,
    description: "",
    name: "Ads",
    is_aws: false,
    newsletter_price: 0.0,
    overage_price: 0.0,
    plan_type: "paid",
    total: 50.0,
  },
  overage_rates: {
    "Essentials 100K": 0.00075,
    "Pro 300K": 0.0005,
    "Pro 700K": 0.00045,
  },
};

export default packageConfig;
