import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { PackageDto } from '../dtos';

export const Actions = {
  getPackages: () => createAction(ActionTypes.GetPackagesRequest),
  getPackagesSuccess: (packagesDto: PackageDto[]) =>
    createAction(ActionTypes.GetPackagesSuccess, packagesDto),
  getPackagesFailure: () => createAction(ActionTypes.GetPackagesFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
