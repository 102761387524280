const StatsLoadingView = require("./views/loading_view");
const StatsLoadingLayoutView = require("./views/loading_layout_view");
const StatsEmptyDataView = require("./views/stats/stats_empty_data_view");
const SignupStatus = require("./models/signup_status_model");
const qs = require("qs");
const $ = require("jquery");
const APIConfig = require("apiconfig");

module.exports = {
  // eslint-disable-next-line consistent-return
  before(router, route) {
    // Turn off the pong game event listener in case you redirect from
    // the pong game to another route while it is still playing
    if (window.Reqres) {
      window.Reqres.request("stopPongGame");
    }
    const authController = router.getOption("authController");
    const redirectController = router.getOption("redirectController");

    /* This forces the page to refresh if we have any trackers. Emails and other PII's in the querystring
    are typically leaked to trackers like Facebook, GTM, or FE stat aggregation like New Relic DO NOT DELETE */
    if (!window.OnlyBlacklist) {
      if (
        /** If the route is in the blacklist and Mako has been initialized with 3rd party scripts reload
         * If the route is in not in the blacklist and mako has been initialized without 3rd party scripts reload
         */
        (window.urlBlacklist.includes(route) && !window.blackListInit) ||
        (!window.urlBlacklist.includes(route) && window.blackListInit)
      ) {
        window.location.reload();
      }
    }
    const publicRoutes = [
      "login",
      "login/from-site?token=:token",
      "forgot_password",
      "reset_password",
      "signup/validate",
      "signup/aws",
      "errors/expired_invitation",
      "errors/expired_reset_password",
      "teammates/confirm",
      "public/sender_auth/verification",
      "logout",
    ];
    if (publicRoutes.indexOf(route) < 0) {
      if (!authController.checkAuthToken()) {
        authController.logout();
        return false;
      }
      window.track("Finished starting mako (authenticated)");
    } else {
      window.track("Finished starting mako (public)");
    }
    $("[data-role=main-loader-mako]").fadeOut();

    // hook so automation can know the page is transitioning
    $("body").attr("data-mako-ready", "starting");

    const redirectBlacklistRoutes = [];
    // All the public routes should also be considered blacklisted
    redirectBlacklistRoutes.push(...publicRoutes);

    if (redirectBlacklistRoutes.indexOf(route) < 0) {
      redirectController.signupRedirect();
    }

    const signupStatus = new SignupStatus(
      window.Reqres.request("signupStatus")
    );
    if (
      route.indexOf("/account_details") !== 0 &&
      signupStatus.needsToStartProfile()
    ) {
      // need to do a hard refresh since we don't want tiara anymore
      window.location.href = `${
        APIConfig.signup_host
      }/account_details?s2s=true`;
    }

    const params = qs.parse(window.location.search.substr(1)); // remove initial '?'

    // show s2s banner UNLESS we just finished the profile and are on the guide page
    if (!(route === "guide" && params.from === "profile")) {
      $("#hide-s2s-banner-style").remove();
    }

    // when loading mako in an iframe, pass noFeedback=true to slay WebEngage
    if (params.noFeedback !== "true") {
      $("#hide-webengage").remove();
    }
  },

  wrapInLoadingView(view, collection, loadingOptions) {
    const options = loadingOptions || {};
    options.emptyView = options.emptyView || StatsEmptyDataView;
    options.emptyViewOptions = options.emptyViewOptions || {};
    if (options.emptyView === StatsEmptyDataView) {
      options.emptyViewOptions.messageText =
        options.emptyViewOptions.messageText || "No data to display";
      options.emptyViewOptions.subMessageText =
        options.emptyViewOptions.subMessageText ||
        "The date range selected contains no data";
    }

    const loadingView = new StatsLoadingView();
    // eslint-disable-next-line new-cap
    const emptyView = new options.emptyView(options.emptyViewOptions);

    const loadingLayout = new StatsLoadingLayoutView({
      mainView: view,
      loadingView,
      emptyView,
      collection,
    });

    return loadingLayout;
  },
};
