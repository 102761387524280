const Backbone = require("backbone");

module.exports = Backbone.Model.extend({
  impersonation: null,

  impersonate(options) {
    options = options || {};
    // Check if this model is impersonating a subuser
    if (this.impersonation) {
      options.headers = options.headers || {};
      options.headers["On-behalf-of"] = this.impersonation;
    }
    return options;
  },

  // Overwrite save function
  save(attrs, options) {
    options = this.impersonate(options);

    // Proxy the call to the original save function
    return Backbone.Model.prototype.save.call(this, attrs, options);
  },

  fetch(options) {
    options = this.impersonate(options);

    return Backbone.Model.prototype.fetch.call(this, options);
  },
});
