(function($) {
  $.fn.confirm = function() {
    return this.each(function() {
      const $elt = $(this);

      $elt.click(function(event) {
        const $this = $(this);

        let modal = $this.data("confirm-modal");

        if (!modal) {
          const title = $this.attr("data-confirm-title");
          const text = $this.attr("data-confirm-text");
          const iconClass = $this.attr("data-confirm-icon-class");

          // create modal wrapper
          const $modal = $("<div/>");
          $modal.addClass("sg-modal");
          $modal.attr("data-modal", "true");

          // create alert for modal content
          const $alert = $("<div/>");
          $alert.addClass("conf-alert sg-modal-content");
          $alert.attr("data-modal-content", "true");

          $modal.append($alert);

          const $header = $("<h2/>").addClass("conf-alert-header");
          if (iconClass) {
            const $icon = $("<i/>").addClass(iconClass);
            $header.append($icon).addClass("conf-alert-header-with-icon");
          }

          $header.append($("<div/>").text(title).html());
          $alert.append($header);

          if (text) {
            const $text = $("<p/>").addClass("conf-alert-text").text(text);
            const $body = $("<div/>").addClass("conf-alert-body").append($text);

            $alert.append($body);
          }

          const $buttons = $("<div/>").addClass("conf-alert-actions");
          $alert.append($buttons);

          const $cancel = $("<button>Cancel</button>");
          $cancel.addClass("btn btn-small btn-secondary");
          $cancel.attr("data-modal-cancel", "true");

          const $confirm = $("<button>Confirm</button>");
          $confirm.addClass("btn btn-small btn-primary");
          $confirm.attr("data-modal-confirm", "true");

          $buttons.append($cancel).append(" ").append($confirm);

          $("body").append($modal);

          $confirm.click(() => {
            $this.click();
          });

          $cancel.click(e => {
            modal.hide();
          });

          modal = new Mako.Modal($modal);
          $(modal).on("modal:hidden", () => {
            $modal.remove();
            $this.removeData("confirm-modal");
          });

          $this.data("confirm-modal", modal);
        } else {
          modal.hide();
          return true;
        }

        // prevent original click from being called
        event.stopImmediatePropagation();

        // close any dropdowns i'm inside of
        $this.parents(".is-active").removeClass("is-active");
        modal.show();

        return false;
      });
    });
  };
})(jQuery);
