import * as React from 'react';
import { Button } from '@sendgrid/ui-components';

import { Grid, Icon, Text } from '../../../../components/__common__';
import Card, {
  CardTitle,
  CardText,
} from '../../../../components/__common__/Card';

import './index.scss';

interface PlanCardProps {
  isCurrent: boolean;
  onClick?: (e: React.MouseEvent) => void | undefined;
  price?: string;
  text?: string;
  title?: string;
  id: string;
}

export const PlanCard: React.SFC<PlanCardProps> = ({
  isCurrent,
  onClick,
  price,
  text,
  title,
  id,
}) => {
  return (
    <Card p={4} mb={0}>
      <Grid.Row>
        <Grid.Column width={9}>
          <CardTitle as="h3">{title}</CardTitle>
          <CardText className="slate-80">{text}</CardText>
        </Grid.Column>
        <Grid.Column width={3} className="plan-footer">
          {isCurrent ? (
            <Text className="align-center">
              <Icon color="mantis" pr={-2} scale={-1} type="check" />
              Your Current Plan
            </Text>
          ) : (
            <React.Fragment>
              <Text className="strong pad-x-scale-0">{price}</Text>
              <Button id={id} type="secondary" small onClick={onClick}>
                Select
              </Button>
            </React.Fragment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Card>
  );
};
