const PaginationController = require("controllers/pagination_controller");
const Handlebars = require("handlebars");
const _ = require("underscore");
const $ = require("jquery");
const Backbone = require("backbone");

module.exports = function(params) {
  const paginatedLink = function(urlFragment, limit, offset, queryParams) {
    const pageNum = offset / limit + 1;
    return `${urlFragment}?${$.param(_.extend({ page: pageNum }, queryParams))}`;
  };

  let urlFragment = "#";
  if (Backbone.history.fragment) {
    urlFragment += Backbone.history.fragment.split("?")[0];
  }

  const limit = params.hash.limit;
  let currentPage = params.hash.currentPage;
  const lastPage = params.hash.lastPage;
  const queryParams = params.hash.queryParams;
  const offset = params.hash.offset;

  if (currentPage === undefined && offset !== undefined) {
    currentPage = offset / limit + 1;
  }

  const paginationData = new PaginationController().createLinks(
    currentPage,
    lastPage
  );

  let prevClass = "invisible", nextClass = "invisible";

  if (currentPage > 1) {
    prevClass = "";
  }
  if (currentPage < lastPage) {
    nextClass = "";
  }

  const prevButton = `<a role="paging" data-limit="${limit}" data-offset="${(currentPage - 2) * limit}" class="btn btn-secondary btn-small pagination-prev ${prevClass}" href="${paginatedLink(urlFragment, limit, (currentPage - 2) * limit, queryParams)}">Prev</a>`;
  const nextButton = `<a role="paging" data-limit="${limit}" data-offset="${currentPage * limit}" class="btn btn-secondary btn-small pagination-next ${nextClass}" href="${paginatedLink(urlFragment, limit, currentPage * limit, queryParams)}">Next</a>`;

  const html = _.map(paginationData, data => {
    if (data.placeholder) {
      return '<span class="pagination-ellipses">&hellip;</span>';
    }

    const offset = limit * (data.pageNum - 1);
    const $link = $("<a />");
    $link
      .attr("role", "paging")
      .attr("data-limit", limit)
      .attr("data-offset", offset);
    $link.addClass("pagination-link");

    if (data.active) {
      $link.addClass("active").addClass("is-active");
    }

    $link.attr("href", paginatedLink(urlFragment, limit, offset, queryParams));
    $link.text(data.pageNum);

    return $link.wrapAll("<div />").parent().html();
  }).join("");

  const $wrapper = $("<div />");
  $wrapper.append($(prevButton));

  const $paginationLinks = $("<div />");
  $paginationLinks.addClass("pagination-links");

  $paginationLinks.html(html);

  $wrapper.append($paginationLinks);

  $wrapper.append($(nextButton));

  return new Handlebars.SafeString($wrapper.html());
};
