import * as React from 'react';

import { Package } from '../../../../state/types/package';

import { Grid, Text } from '../../../../components/__common__';
import { FeatureList } from '../../../../components/__common__/FeatureList';

import { PlanCard } from '../PlanCard';
import { Header } from '../Header';

import { PlanInfoProps } from '../types';

const type = 'premier';
const featureList = [
  {
    active: true,
    text: 'Core Email API Features',
  },
  {
    active: true,
    text: 'Core Marketing Campaigns Feature',
    subItems: ['Tiered, enterprise pricing for 1 million+ contacts'],
  },
  {
    active: true,
    text: 'Data-rich email activity feed',
  },
  {
    active: true,
    text: 'Dedicated IP  🎉',
  },
  {
    active: true,
    text: 'Subuser management',
  },
  {
    active: true,
    text: 'Customer success manager**',
  },
  {
    active: true,
    text: 'Prioritized support**',
    subItems: ['**for 5 million+ emails/mo'],
  },
];

export const PremierPlan: React.SFC<PlanInfoProps> = ({
  onPackageSelect,
  packages,
  userPackage,
}) => {
  const filteredPackages = packages.filter(
    ({ planType }: Package) => planType === type
  );

  return (
    <Grid.Row>
      <Header title={type}>
        <FeatureList list={featureList} />
      </Header>
      <Grid.Column width={9}>
        {filteredPackages.map(
          ({ basePrice, credits, id, name, overagePrice }: Package) => {
            const isCurrentPackage = userPackage.packageId === id;
            return (
              <PlanCard
                key={id}
                title={name}
                text={`${credits.toLocaleString()} emails/mo + $${overagePrice} per additional email`}
                price={`$${basePrice.toLocaleString()}/mo*`}
                isCurrent={isCurrentPackage}
                onClick={onPackageSelect}
                id={id}
              />
            );
          }
        )}
        <Text className="justify-right">* Taxes may apply</Text>
      </Grid.Column>
    </Grid.Row>
  );
};
