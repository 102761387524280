const parseURI = require("parse-uri");
const APIConfig = require("apiconfig");

module.exports = function(url) {
  const uri = parseURI(url);
  // Check if we're doing anything funky
  if (uri.protocol !== "") {
    return APIConfig.root;
  }

  return url.replace(/^\/\/|^.*?:\/\//i, "");
};
