var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.columns : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "					<th title=\""
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias1,(depth0 != null ? depth0.label : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data}))
    + "\">"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias1,(depth0 != null ? depth0.label : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data}))
    + "</th>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.columns : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "					<th class=\"stat rank-"
    + alias2(alias1((depth0 != null ? depth0.rank : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" title=\""
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias3,(depth0 != null ? depth0.label : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data}))
    + "\">\n						"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias3,(depth0 != null ? depth0.label : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.computation : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					</th>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "							<span class=\"computation "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.displayComputations : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias1,(depth0 != null ? depth0.computation : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table class=\"data-table stats reversed "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.className : depth0), depth0))
    + "\">\n	<thead>\n		<tr>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.rowHeader : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "		</tr>\n	</thead>\n	<tbody>\n\n	</tbody>\n</table>\n";
},"useData":true});