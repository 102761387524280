import * as React from 'react';
import { Loader, Button } from '@sendgrid/ui-components';
import { Alert } from '@sendgrid/ui-components/alert';
import { PaymentFormInfoWithBillingProvider } from '../../../../state/types/paymentFormInfo';
import _ from 'underscore';
import './index.scss';
import { PaymentMethod } from '../../../../state/types/paymentMethod';
import { SubscriptionPayment } from '../../../../state/types/subscriptionPayment';
import { AccountAndPlanUtils } from '../../../AccountAndBilling/utils';
import { logPaymentProviderError } from '../../../../state/payment_form/service';

interface PaymentFormParams {
  tenantId: string;
  id: string;
  token: string;
  signature: string;
  key: string;
  url: string;
  style: string;
  submitEnabled: boolean;
  field_accountId: string;
}

interface PaymentFormState {
  loading: boolean;
  paymentFailure: boolean;
  hasPaymentFormRendered: boolean;
}

type PaymentFormProps =
  | PaymentFormPropsForPaidUser
  | PaymentFormPropsForUpgradingUser;

export interface PaymentFormPropsForPaidUser {
  paymentForm: PaymentFormInfoWithBillingProvider;
  paymentMethod: PaymentMethod;
  isFreeUser?: boolean;
  close: (e: React.MouseEvent) => void | undefined;
  getPaymentMethods: () => void;
  getPaymentForm: () => void;
  updateDefaultPaymentMethod: (response: any) => void;
  clearPaymentFormErrors: () => void;
}

export interface PaymentFormPropsForUpgradingUser {
  paymentForm: PaymentFormInfoWithBillingProvider;
  isFreeUser: boolean;
  close: (e: React.MouseEvent) => void | undefined;
  getPaymentForm: () => void;
  clearPaymentFormErrors: () => void;
  packageId: string;
  createSubscriptionPayment: (subscriptionPayment: SubscriptionPayment) => void;
  subscriptionPayment: SubscriptionPayment;
}

enum ZuoraErrorKey {
  Error = 'error',
}

enum ZuoraErrorCode {
  Unknown = 'unknown',
}

export class PaymentForm extends React.Component<
  PaymentFormProps,
  PaymentFormState
> {
  constructor(props: PaymentFormProps) {
    super(props);
    this.state = {
      loading: true,
      paymentFailure: false,
      hasPaymentFormRendered: false,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<PaymentFormProps>,
    nextState: Readonly<PaymentFormState>
  ): boolean {
    /**
     * the tenant id comes asynchronously so we have to watch the next props.
     * after the first time we get it, we don't want to re-render the payment form.
     */
    if (
      _.isEmpty(nextProps.paymentForm.tenantId) ||
      nextState.hasPaymentFormRendered
    ) {
      return true;
    }

    const {
      subscriptionPayment,
    } = nextProps as PaymentFormPropsForUpgradingUser;

    /**
     * if a user tried to upgrade and we get an error,
     * we want to give the user a chance to retry
     */
    if (this.isSubscriptionPaymentError(subscriptionPayment)) {
      this.displayPaymentFailure();
      return true;
    }

    const { isFreeUser } = this.props;

    const paymentFormParams = {
      tenantId: nextProps.paymentForm.tenantId,
      id: nextProps.paymentForm.pageId,
      token: nextProps.paymentForm.paymentFormToken,
      signature: nextProps.paymentForm.paymentFormSignature,
      key: nextProps.paymentForm.key,
      url: nextProps.paymentForm.url,
      style: 'inline',
      submitEnabled: true,
    } as PaymentFormParams;

    /**
     * it needs to be set this way to handle both updating a CC or free -> paid flow
     */
    if (nextProps.paymentForm.billingProviderAccountId) {
      paymentFormParams.field_accountId =
        nextProps.paymentForm.billingProviderAccountId;
    }

    const callback = isFreeUser
      ? this.upgradingUserCallback
      : this.creditCardUpdateCallback;

    // zuora needs this, but we don't pre-populate it
    const paymentPrePoplulatedFields = {};

    if (
      !this.state.hasPaymentFormRendered ||
      !nextState.hasPaymentFormRendered
    ) {
      (window as any).Z.renderWithErrorHandler(
        paymentFormParams,
        paymentPrePoplulatedFields,
        callback,
        this.handlePaymentError
      );
      this.setPaymentFormRenderedState();
    }

    return true;
  }

  public render() {
    return (
      <React.Fragment>
        {!this.props.isFreeUser && <h1>Billing Information</h1>}
        {(this.state.paymentFailure ||
          (this.props.paymentForm && this.props!.paymentForm!.error)) && (
          <div className="error-banner-container">
            <div>
              <Alert type="danger" onClick={this.cancel}>
                We encountered an error while loading your payment form. Please{' '}
                <a
                  className="retry-payment"
                  onClick={this.retryLoadingPaymentForm}
                >
                  try again
                </a>
                .
              </Alert>
              <div className="buttons-container">
                <Button type="secondary" onClick={this.cancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="payment-form-container">
          <React.Fragment>
            {!this.state.paymentFailure &&
              this.props.paymentForm &&
              !this.props.paymentForm.error && (
                <React.Fragment>
                  <div className="zuora-iframe-container">
                    {this.state.loading && (
                      <Loader className="loader" centered />
                    )}
                    {
                      <div
                        className={
                          this.state.loading ? 'hidden-payment-form' : ''
                        }
                        id="zuora_payment"
                      />
                    }
                  </div>
                  <div>
                    <Button type="secondary" onClick={this.cancel}>
                      Cancel
                    </Button>
                  </div>
                  <div className="secure-info">
                    <span className="sg-icon sg-icon-locked">
                      <span className="colfax text">
                        {' '}
                        Your information is secure
                      </span>
                    </span>
                  </div>
                </React.Fragment>
              )}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }

  private cancel = (event: React.MouseEvent) => {
    this.clearError();
    /**
     * the side modal isn't unmounted when the user closes
     * we want the state to be reset
     */
    this.setState({
      loading: true,
      hasPaymentFormRendered: false,
      paymentFailure: false,
    });
    this.props.close(event);
  };

  private setPaymentFormRenderedState = () => {
    /**
     * we only want to set this once. This method is called in the shouldComponentUpdate
     * lifecycle method. if this doesn't return once it's set, the method is called perpetually
     * until we blow the call stack.
     */
    if (this.state.hasPaymentFormRendered) {
      return;
    }
    /**
     * we don't show the loading state once the payment form has rendered.
     */
    this.setState({ hasPaymentFormRendered: true, loading: false });
  };

  private displayPaymentFailure = () => {
    if (this.state.paymentFailure) {
      return;
    }
    this.setState({ paymentFailure: true });
  };

  private isSubscriptionPaymentError = (
    subscriptionPayment: SubscriptionPayment
  ): boolean => {
    return (
      subscriptionPayment &&
      !subscriptionPayment.isCreated &&
      subscriptionPayment.errors &&
      subscriptionPayment.errors.length > 0
    );
  };

  private retryLoadingPaymentForm = () => {
    this.clearError();
    this.props.getPaymentForm();
  };

  private clearError = () => {
    this.props.clearPaymentFormErrors();
    this.setState({ loading: true, paymentFailure: false });
  };

  /**
   * response has to be any since it comes from a 3rd party api
   * we don't want to risk any weirdness if they make updates
   */
  private upgradingUserCallback = (response: any) => {
    const { packageId, createSubscriptionPayment } = this
      .props as PaymentFormPropsForUpgradingUser;

    this.setState({
      ...this.state,
      loading: true,
    });

    const { billingProviderAccountId = '' } = this.props.paymentForm;
    if (response.success === 'true') {
      const refId = response.refId;
      const subscriptionPayment = {
        paymentMethodId: refId,
        packageId,
      } as SubscriptionPayment;
      /**
       * this only exists if the user either has a current account that's expired
       * or there's a failure mid-payment and an account is created
       */
      if (billingProviderAccountId) {
        subscriptionPayment.billingProviderAccountId = billingProviderAccountId;
      }

      createSubscriptionPayment(subscriptionPayment);
    } else {
      AccountAndPlanUtils.tracking.segmentTiaraAnalyticsTrack(
        'Zuora request failure, free to paid failure'
      );
      this.displayPaymentFailure();
    }
  };

  private creditCardUpdateCallback = (response: any) => {
    const { close, updateDefaultPaymentMethod } = this
      .props as PaymentFormPropsForPaidUser;
    if (response.success === 'true') {
      close({} as React.MouseEvent);
      updateDefaultPaymentMethod(response);
    } else {
      AccountAndPlanUtils.tracking.segmentTiaraAnalyticsTrack(
        'Zuora request failure, credit card not updated'
      );
      this.displayPaymentFailure();
    }
  };

  private handlePaymentError = (key: any, code: any, message: any) => {
    // Since the payment form submission happens on Zuora's servers, we don't
    // have logs that we can report on. This endpoint logs payment provider
    // errors so we can generate alerts from them.
    const isServerError =
      key.toLowerCase() === ZuoraErrorKey.Error &&
      code.toLowerCase() === ZuoraErrorCode.Unknown;
    if (isServerError) {
      logPaymentProviderError(message);
    }

    // Custom error handling can be implemented here to override default messages
    // provided by Zuora. This method sends any custom messages to the form.
    (window as any).Z.sendErrorMessageToHpm(key, message);
  };
}
