const Marionette = require("backbone.marionette");
const $ = require("jquery");
const _ = require("underscore");

module.exports = Marionette.Behavior.extend({
  initialize() {
    this.listenTo(this.view, "error:validation", _.bind(this.onError, this));
  },

  defaults: {
    submitButton: "[role=submitButton]",
    modelSaveOptions: {},
  },

  ui() {
    return {
      submitButton: this.options.submitButton,
    };
  },

  events: {
    "click @ui.submitButton": "save",
  },

  save() {
    this.view.triggerMethod("saving");

    const view = this.view;
    this.clearErrors();

    const values = this.getFormValues();
    let model;
    if (this.options.modelClass) {
      model = new this.options.modelClass(values);
    } else {
      model = this.view.model;
      model.set(values, { validate: false });
    }

    let attrs = null;
    if (this.options.attributes) {
      if (_.isFunction(this.options.attributes)) {
        attrs = this.options.attributes(model);
      } else {
        attrs = this.options.attributes;
      }
    }

    this.listenTo(model, "invalid", (model, error) => {
      view.triggerMethod("error:validation", model, error);
    });

    const me = this;
    this.listenTo(model, "change", () => {
      me.clearErrors();
    });

    let xhr;
    if (!model.isNew()) {
      xhr = model.save(attrs, this.options.modelSaveOptions);
    } else {
      xhr = model.save();
    }

    if (!xhr) {
      return;
    }

    $.when(xhr).then(
      data => {
        model.set(data, { validate: false });
        view.triggerMethod("saved", model);
      },
      data => {
        _.each(data.responseJSON.errors, error => {
          view.triggerMethod("error:server", model, error);
        });
      }
    );
  },

  clearErrors() {
    this.$el
      .find("[data-field],[data-field-error]")
      .parent()
      .errors("clear");
  },

  getFormValues() {
    const fields = {};
    this.view.$el.find("[data-field]").each(function() {
      const $elt = $(this);
      const name = $elt.data("field");
      let value = $elt.val();

      value = _.isString(value) ? $.trim(value) : value;

      fields[name] = value;
    });

    return fields;
  },

  onError(model, errors) {
    if (!_.isArray(errors)) {
      errors = [errors];
    }

    const view = this;
    _.each(errors, error => {
      if (!error.field) {
        // no field associated with the error... not sure how these are supposed to be handled
        if (error.message) {
          window.EventBus.trigger("flash", error.message, true);
        }
        return;
      }

      view.$el
        .find(`[data-field=${error.field}],[data-field-error=${error.field}]`)
        .parent()
        .errors("show", error.message);
    });
  },
});
