import { createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { AddExpertInsightsDto } from '../dtos';
import { AddExpertInsightsRequest } from '../types/addExpertInsights';

export const Actions = {
  addExpertInsights: (addExpertInsightsRequestBody: AddExpertInsightsRequest) =>
    createAction(
      ActionTypes.AddExpertInsightsRequest,
      addExpertInsightsRequestBody
    ),
  addExpertInsightsSuccess: () =>
    createAction(ActionTypes.AddExpertInsightsSuccess),
  addExpertInsightsFailure: (errors: AddExpertInsightsDto) =>
    createAction(ActionTypes.AddExpertInsightsFailure, errors),
};
