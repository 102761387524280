const _ = require("underscore");

module.exports = function(a, b, options) {
  if (a === b) {
    if (_.isObject(options)) {
      return options.fn(this);
    }

    return options;
  }
};
