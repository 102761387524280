import * as React from 'react';
import { Package } from '../../../../state/types/package';
import { Grid } from '../../../../components/__common__';
import { FeatureList } from '../../../../components/__common__/FeatureList';
import { PlanCard } from '../PlanCard';
import { Header } from '../Header';
import { PlanInfoProps } from '../types';
import { AccountAndPlanUtils } from '../../utils';

const type = 'free';
const featureList = [
  {
    active: true,
    text: 'Core Email API Features',
  },
  {
    active: true,
    text: 'Core Marketing Campaigns Feature',
    subItems: ['2,000 contacts free'],
  },
  {
    active: true,
    text: 'Data-rich email activity feed',
  },
  {
    active: false,
    text: 'Dedicated IP',
  },
  {
    active: false,
    text: 'Subuser management',
  },
  {
    active: false,
    text: 'Customer success manager',
  },
  {
    active: false,
    text: 'Prioritized support',
  },
];

export const FreePlan: React.SFC<PlanInfoProps> = ({
  onPackageSelect,
  packages,
  userPackage,
}) => {
  const { packageId } = userPackage;
  const freePackages = AccountAndPlanUtils.getFreePackages(packages);

  // if the user's current package is free, show that package,
  // otherwise, show the Free100k package;
  const displayPackage =
    freePackages[packageId] ||
    freePackages[AccountAndPlanUtils.FreePackages.free100KId] ||
    {};

  let text = '100 emails/day';

  if (
    displayPackage &&
    displayPackage.id === AccountAndPlanUtils.FreePackages.trial40KId
  ) {
    text = `${displayPackage.credits.toLocaleString()} emails for your first 30 days, then 100 emails/day, forever`;
  }

  return (
    <Grid.Row>
      <Header title={type}>
        <FeatureList list={featureList} />
      </Header>
      <Grid.Column width={9}>
        {displayPackage && (
          <PlanCard
            key={displayPackage.id}
            title={displayPackage.name}
            text={text}
            price="$0.00"
            isCurrent={packageId === displayPackage.id}
            onClick={onPackageSelect}
            id={packageId}
          />
        )}
      </Grid.Column>
    </Grid.Row>
  );
};
