const Marionette = require("backbone.marionette");

const _ = require("underscore");

// noinspection JSUnusedLocalSymbols
module.exports = Marionette.ItemView.extend({
  tagName: "tr",
  template: require("templates/stats/table_row.hbs"),

  initialize() {
    this.pageModel = this.getOption("pageModel");
    this.listenTo(this.pageModel, "change:displayMode", this.toggleDisplayMode);
  },

  serializeData() {
    const columns = this.getOption("columns");
    const metric = this.pageModel.get("metric");

    const stats = this.getStats(columns, metric);
    stats.columns = columns;
    stats.displayNumbers = this.pageModel.get("displayMode") !== "percentages";

    return stats;
  },

  getStats(names, metric) {
    const stats = this.model.get("stats");
    const name = this.pageModel.get(this.getOption("modelKey"));

    if (!metric) {
      let index = 0;
      if (name) {
        for (let i = 0; i < stats.length; i++) {
          if (stats[i].name === name) {
            index = i;
            break;
          }
        }
      }

      return stats[index].metrics;
    }

    const computedStats = {};
    // sum each metric
    const totalForMetric = _.reduce(
      stats,
      (total, stat) => total + stat.metrics[metric],
      0
    );
    const valueOfMetric = function valueOfMetric(stat, metricType) {
      return stat ? stat.metrics[metricType] : 0;
    };
    const statsOfName = function statsOfName(statsModel, modelName) {
      return _.find(statsModel, s => s.name === modelName);
    };
    const result = _.map(names, n => {
      const nameStats = statsOfName(stats, n.label);
      const value = valueOfMetric(nameStats, metric);
      let numRequests;
      let numDelivered;
      if (nameStats && nameStats.metrics) {
        // We already get calculated "requests" and "delivered" metrics
        // subuser and category comparison
        if (this.pageModel.isCategoryOrSubuserComparison) {
          const { requests = 0, delivered = 0 } = nameStats.metrics;

          numRequests = requests;
          numDelivered = delivered;
        } else {
          // For mailbox provider comparison we must calculate the "requests" manually
          // since the metrics does not return it already
          const {
            delivered = 0,
            blocks = 0,
            bounces = 0,
            drops = 0,
          } = nameStats.metrics;
          const processed = delivered + blocks + bounces;

          numRequests = processed + drops;
          numDelivered = delivered;
        }
      }

      return {
        name: n.key,
        total: value,
        numRequests,
        numDelivered,
      };
    });

    _.map(result, r => {
      // For subuser/category comparison, we need to adjust the percentage rates
      // in the figures table to be with respect to its requests/delivered counts
      if (this.pageModel.isComparisonPercentageAdjusted) {
        if (metric === "requests") {
          // For "requests" metric, just show all N/A for now (divide by zero) since it doesn't
          // make sense to have any rate
          computedStats[`${r.name}_rate`] = r.total / 0;
        } else {
          // All other metrics will be divided by delivered or requests
          const metricsDividedByDelivered = [
            "clicks",
            "unique_clicks",
            "opens",
            "unique_opens",
            "spam_reports",
            "unsubscribes",
          ];
          computedStats[`${r.name}_rate`] = metricsDividedByDelivered.includes(
            metric
          )
            ? r.total / r.numDelivered
            : r.total / r.numRequests;
        }
      } else {
        computedStats[`${r.name}_rate`] = r.total / totalForMetric;
      }
      computedStats[r.name] = r.total;
    });

    return computedStats;
  },

  toggleDisplayMode(model, displayMode) {
    const old = displayMode === "percentages" ? "numbers" : "percentages";

    this.$el.find(`[role=${old}]`).addClass("hidden");
    this.$el.find(`[role=${displayMode}]`).removeClass("hidden");
    this.$el.find(".rate").toggleClass("hidden");
  },
});
