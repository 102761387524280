const Marionette = require("backbone.marionette");

const MAX_MIDDLE_COUNT = 4;

module.exports = Marionette.Controller.extend({
  createLinks(currentPage, maxPages) {
    let paginationLinks;
    paginationLinks = [];
    this.appendPageLink(paginationLinks, 1, currentPage);
    if (maxPages === 1) {
      return paginationLinks;
    }
    if (maxPages > 2) {
      this.appendMiddleLinks(paginationLinks, currentPage, maxPages);
    }
    this.appendPageLink(paginationLinks, maxPages, currentPage);
    return paginationLinks;
  },
  appendPlaceholder(paginationLinks) {
    return paginationLinks.push({
      placeholder: true,
    });
  },
  appendPageLink(paginationLinks, pageNum, currentPage) {
    return paginationLinks.push({
      pageNum,
      active: currentPage === pageNum,
    });
  },
  appendMiddleLinks(paginationLinks, currentPage, maxPages) {
    let lastPageNum, pageNum, pageNums, _i, _len;
    pageNums = this.getMiddlePageNums(currentPage, maxPages);
    if (pageNums[0] > 2) {
      this.appendPlaceholder(paginationLinks);
    }
    for (_i = 0, _len = pageNums.length; _i < _len; _i++) {
      pageNum = pageNums[_i];
      this.appendPageLink(paginationLinks, pageNum, currentPage);
    }
    lastPageNum = paginationLinks[paginationLinks.length - 1].pageNum;
    if (lastPageNum < maxPages - 1) {
      return this.appendPlaceholder(paginationLinks);
    }
  },
  getMiddlePageNums(currentPage, maxPages) {
    let end, startingIndex, _i, _results;
    if (maxPages - currentPage < MAX_MIDDLE_COUNT) {
      startingIndex = maxPages - MAX_MIDDLE_COUNT;
    } else {
      startingIndex = currentPage - 1;
    }
    startingIndex = Math.max(startingIndex, 2);
    end = Math.min(startingIndex + (MAX_MIDDLE_COUNT - 1), maxPages - 1);
    return function() {
      _results = [];
      for (
        let _i = startingIndex;
        startingIndex <= end ? _i <= end : _i >= end;
        startingIndex <= end ? _i++ : _i--
      ) {
        _results.push(_i);
      }
      return _results;
    }.apply(this);
  },
});
