import * as React from 'react';

import './index.scss';
import { ScaleProps, WithScale } from '../WithScale';

/*

This is a new, experimental component with an immature API.
Please use with caution.

*/

export type BorderColorScale = 'default' | 'dark';
export type BorderRadii = 0 | 2;

interface BorderRadiusProps {
  borderRadius?: BorderRadii;
}

export interface BorderedBoxProps
  extends ScaleProps,
    BorderRadiusProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  borderColor?: BorderColorScale;
}

const mapRadiusToClassName = (value: BorderRadii) => {
  return value === 0 ? `border-radius-0` : '';
};

export const BorderedBox: React.SFC<BorderedBoxProps> = ({
  borderColor = 'default',
  borderRadius = 2 as BorderRadii,
  className = '',
  children,
  ...props
}) => {
  const baseClass = 'bordered-box';
  const borderScaleClass = `border-color-${borderColor}`;
  const borderRadiusClass = mapRadiusToClassName(borderRadius);
  const classNames = [baseClass, borderScaleClass, className, borderRadiusClass]
    .join(' ')
    .trim();

  return (
    <WithScale className={classNames} {...props}>
      {(injectedProps) => <div {...injectedProps}>{children}</div>}
    </WithScale>
  );
};
