const Marionette = require("backbone.marionette");

module.exports = Marionette.LayoutView.extend({
  template: require("templates/loading_layout.hbs"),

  regions: {
    loading: ">[role=loading]",
    main: ">[role=main]",
    empty: ">[role=empty]",
  },

  collectionEvents: {
    sync: "onSync",
    request: "onRequest",
  },

  initialize() {
    this.isLoading = false;
    this.hasData = this.collection.length > 0;
  },

  serializeData() {
    return {
      showLoading: this.isLoading,
      showMain: !this.isLoading && this.hasData,
      showEmpty: !this.isLoading && !this.hasData,
    };
  },

  render() {
    // This needs to play nicely with the single stats view,
    // but since it is a LayoutView it can't be rerendered.
    // So render once and if we are told to get rendered again,
    // just rerender the loading or main view.
    if (!this.rendered) {
      Marionette.LayoutView.prototype.render.call(this);
      this.rendered = true;
      return;
    }

    if (this.isLoading) {
      this.getOption("loadingView").render();
    } else if (this.hasData) {
      this.getOption("mainView").render();
    } else {
      this.getOption("emptyView").render();
    }
  },

  onShow() {
    this.loading.show(this.getOption("loadingView"));
    this.main.show(this.getOption("mainView"));
    this.empty.show(this.getOption("emptyView"));
  },

  onRequest() {
    this.isLoading = true;
    this.loading.$el.removeClass("hidden");
    this.main.$el.addClass("hidden");
    this.empty.$el.addClass("hidden");
  },

  onSync() {
    this.isLoading = false;
    this.hasData = this.collection.length > 0;
    this.loading.$el.addClass("hidden");

    if (this.hasData) {
      this.main.$el.removeClass("hidden");
    } else {
      this.empty.$el.removeClass("hidden");
    }

    this.getOption("mainView").render();
  },
});
