import { getUserWithTimezone } from "./state/time_zone/actions";

const Backbone = require("backbone");
const $ = require("jquery");
Backbone.$ = require("jquery");
require("dotdotdot"); // For whitelabel jquery-dotdotdot faq
require("bootstrap-daterangepicker");
require("./lib/modal.js");
require("select2");
require("../styles/mako.scss");

const moment = require("moment");
const Error500View = require("./views/errors/500_view");
const Error404View = require("./views/errors/404_view");

// Tiara needs jQuery to be global so we just make it global because we're lazy
// https://github.com/webpack/webpack/issues/2180#issuecomment-196048732
window.$ = require("jquery");
window.jQuery = require("jquery");
Backbone.routefilter = require("./helpers/backbone_routefilter");

const Marionette = require("backbone.marionette");
require("bootstrap-daterangepicker");

const APIConfig = require("apiconfig");

// explicitly state when mako ajax is complete, to ease automation, per Alex
let ajaxcalls = 0;
const $body = $("body");
$body.attr("data-mako-ready", "starting");
$(document).ajaxSend(() => {
  ajaxcalls += 1;
  if ($body.attr("data-mako-ready") === "starting") {
    $body.attr("data-mako-ready", "pending");
  }
  $body.attr("data-mako-network-on", "true");
});
$(document).ajaxComplete(() => {
  ajaxcalls -= 1;
  if (ajaxcalls === 0) {
    $body
      .attr("data-mako-ready", "complete")
      .attr("data-mako-network-on", "false");
  }
});
$(document).ajaxError(() => {
  ajaxcalls -= 1;
  if (ajaxcalls === 0) {
    $body
      .attr("data-mako-ready", "complete")
      .attr("data-mako-network-on", "false");
  }
});

$("head").prepend(
  `<link rel="stylesheet" href="${APIConfig.tiara.cdn_css}" type="text/css" />`
);

$.ajax({
  url: APIConfig.tiara.cdn_js,
  dataType: "script",
  cache: true,
}).done(() => {
  window.track("Finished loading tiara.js");

  const AppInitializer = require("./lib/app_initializer");

  // routers
  const Router = require("./router");
  const SuppressionsRouter = require("./suppressions_router");
  const StatsRouter = require("./stats_router");
  const ErrorRouter = require("./error_router");

  // controllers
  const AuthController = require("./controllers/auth_controller");
  const RedirectController = require("./controllers/redirect_controller");
  const LayoutController = require("./controllers/layout_controller");

  // collections
  const StatsCollection = require("./collections/stats_collection");
  const FeatureToggleCollection = require("./collections/feature_toggle_collection");

  // models
  const UserProfileModel = require("./models/user_profile_model");
  const ReputationModel = require("./models/reputation_model");
  const EmailModel = require("./models/email_model");
  const UsernameModel = require("./models/username_model");
  const TimezoneModel = require("./models/timezone_model");
  const ProvisionStatusModel = require("./models/provision_status_model");
  const TokensEmailModel = require("./models/tokens_email_model");
  const AccountProfileModel = require("./models/account_profile_model");
  const UserPackageModel = require("./models/user_package_model");

  // views
  const SignupModalView = require("./views/signup/modal_view");
  const EmailConfirmationModalLayoutView = require("./views/email_confirmation/email_confirmation_modal_layout_view");
  const EmailConfirmationModalView = require("./views/email_confirmation/email_confirmation_modal_view");
  const EmailConfirmationSuccessModalView = require("./views/email_confirmation/email_confirmation_success_modal_view");

  // helpers
  const Notifier = require("./helpers/notifier.js");
  require("./helpers/jquery/errors");
  require("./helpers/jquery/confirm");
  require("./helpers/jquery/multirange_slider");
  require("./helpers/jquery/whenall");
  require("./helpers/jquery/styleguide_modal");
  require("./helpers/underscore/xor");
  const DomEvents = require("./helpers/dom_events");

  window.track("Finished requiring modules");

  const Mako = new Marionette.Application();
  // eslint-disable-next-line no-undef
  const authController = new AuthController({ tiara: SendGridTiara }); // SendGridTiara exposed through index.html
  const signupModalView = new SignupModalView();
  const redirectController = new RedirectController({
    signupModalView,
  });

  $.cookie.defaults.path = "/";
  if (APIConfig.cookie_default_domain) {
    $.cookie.defaults.domain = APIConfig.cookie_default_domain;
  } else if (window.location.hostname !== "localhost") {
    $.cookie.defaults.domain = ".sendgrid.com";
  }

  window.EventBus = Mako.vent;
  window.Reqres = Mako.reqres;
  Mako.addRegions({ bodyRegion: "[role=container]" });

  // Redux
  const { createBrowserHistory } = require("history");
  const { configureStore } = require("./state/store");
  const { createUserProfile } = require("./state/account_profile/actions");
  const { createUserAccount } = require("./state/account/actions");
  const { createUserPackage } = require("./state/user_package/actions");
  const { Actions } = require("./state/feature_toggles/actions");

  // React Router history
  const history = createBrowserHistory();
  // Instantiate Redux store
  const store = configureStore({}, history);

  Mako.on("start", function onStartMako(userData) {
    // update Redux store with userData
    store.dispatch(createUserProfile(userData));
    store.dispatch(
      createUserAccount({ ...userData.account, ...userData.signup_status })
    );
    store.dispatch(createUserPackage(userData.user_package));
    store.dispatch(Actions.createFeatureToggles(userData.feature_toggles));

    const {
      type = "",
      reseller_username: resellerUsername = "",
    } = userData.userType;
    window.Reqres.setHandler(
      "isAwsCustomer",
      () =>
        (type === "reseller_customer" || type === "reseller_customer_free") &&
        resellerUsername === "reseller@aws.com"
    );

    const userProfileModel = new UserProfileModel(userData.profile);
    const reputationModel = new ReputationModel(userData.account);
    const emailModel = new EmailModel();
    const usernameModel = new UsernameModel(userData.username);
    const accountProfileModel = new AccountProfileModel(
      userData.accountProfile
    );

    const timezoneModel = new TimezoneModel();
    const emailConfirmationModalView = new EmailConfirmationModalView({
      tokensEmailModel: new TokensEmailModel(userData.email),
      profileModel: new UserProfileModel(userData.profile),
      redirectController,
      authController,
    });
    const userPackageModel = new UserPackageModel(userData.user_package);

    const emailConfirmationSuccessModalView = new EmailConfirmationSuccessModalView();

    const emailConfirmationModalLayoutView = new EmailConfirmationModalLayoutView(
      {
        confirmationModalView: emailConfirmationModalView,
        successModalView: emailConfirmationSuccessModalView,
      }
    );

    emailConfirmationModalView.on("saved", () => {
      emailConfirmationModalView.hideModal();
      emailConfirmationSuccessModalView.showModal();
    });

    window.Reqres.setHandler("signupStatus", () => userData.signup_status);
    window.Reqres.setHandler("timezone", () => timezoneModel);
    window.Reqres.setHandler("account", () => userData.account);
    window.Reqres.setHandler("userPackage", () => userPackageModel);
    window.Reqres.setHandler("isEaseGA", () => true);

    const statsCollection = new StatsCollection();
    statsCollection.params({
      start_date: moment()
        .utcOffset(-window.Reqres.request("timezone").getOffsetMinutes())
        .startOf("month")
        .format("YYYY-MM-DD"),
    });
    const featureToggleCollection = new FeatureToggleCollection(
      userData.feature_toggles
    );
    window.Reqres.setHandler("hasFeature", (appName, featureName) =>
      featureToggleCollection.hasFeature(appName, featureName)
    );
    // Can pass down scopes string like "teammates.read,subusers.read" to check
    window.Reqres.setHandler("hasScopes", (scopesToCheckStr) => {
      const scopesToCheck = scopesToCheckStr.split(",");
      return scopesToCheck.every(
        (scopeToCheck) => authController.scopes[scopeToCheck]
      );
    });

    window.Reqres.setHandler("getScopes", () => {
      const scopesObj = authController.scopes;
      const scopes = Object.keys(scopesObj);
      return scopes.filter((scope) => scopesObj[scope]).map((scope) => scope);
    });

    window.Reqres.setHandler("currentUser", () => {
      const username = usernameModel.get("username");
      const userId = usernameModel.get("user_id");
      const userType = reputationModel.get("type");
      return {
        user_id: userId,
        username: username || "Yourself",
        userType,
        email: userData.email.email,
      };
    });
    window.Reqres.setHandler("2faUser", () => {
      const multifactorPhone = userProfileModel.get("multifactor_phone");
      const authyId = userProfileModel.get("authy_id");
      return {
        multifactorPhone,
        authyId,
      };
    });

    // this needs called outside of the layout controller to properly push data into the new react
    // components and at the same time keep the current model working properly.
    window.Reqres.request("timezone")
      .fetch()
      .then((timezone) => store.dispatch(getUserWithTimezone(timezone)));

    const provisionStatusModel = new ProvisionStatusModel();

    const notifier = new Notifier(APIConfig.airbrake);

    // Controllers
    const layoutController = new LayoutController({
      region: Mako.bodyRegion,
      provisionStatusModel,
      signupModalView,
      emailModel,
      emailConfirmationModalLayoutView,
    });

    // Routers
    this.errorRouter = new ErrorRouter({
      usernameModel,
      accountProfileModel,
      layoutController,
      // authController and redirectController are used by router helper
      authController,
      redirectController,
    });

    window.EventBus.on("navigate", (href, callback) => {
      Backbone.history.navigate(href, { trigger: true });
      window.EventBus.trigger("closeFlash");
      window.SendGridTiara.updateNav(href);
      window.scrollTo(0, 0);
      if (callback && typeof callback === "function") {
        callback();
      }
    });

    window.EventBus.on("render500Error", () => {
      const error500View = new Error500View();
      layoutController.display(error500View);
    });

    window.EventBus.on("render404Error", () => {
      const error404View = new Error404View();
      layoutController.display(error404View);
    });

    window.EventBus.on("redirectToSignupApp", () => {
      // Redirect to new signup subdomain if users navigate to our subdomain for /signup
      const queryParams = window.location.search;
      window.location.href = `${APIConfig.signup_host}${queryParams}`;
    });

    window.EventBus.on("redirectToLabsApp", () => {
      // Redirect to labs subdomain if users navigate to our subdomain for /labs
      // If given /labs/<some_labs_path>, append the path after /labs to the labs url
      // i.e. /labs -> https://labs.sendgrid.com
      // i.e. /labs/sso/grow -> https://labs.sendgrid.com/sso/grow
      // i.e. /labs/mda -> https://labs.sendgrid.com/mda
      const queryParams = window.location.search;
      const labsPathPrefix = "/labs";
      const labsRemainingPath = window.location.pathname.slice(
        labsPathPrefix.length
      );

      window.location.href = `${
        APIConfig.labs_host
      }${labsRemainingPath}${queryParams}`;
    });

    // redirects to new account and billing pages
    window.EventBus.on("redirectToAccountPage", () => {
      window.location.href = `${APIConfig.mako_host}/account/details`;
    });

    window.EventBus.on("redirectToBillingPage", () => {
      window.location.href = `${APIConfig.mako_host}/account/billing`;
    });

    this.router = new Router({
      // jshint ignore:line
      region: Mako.bodyRegion,
      usernameModel,
      emailStatsCollection: statsCollection,
      userProfileModel,
      emailModel,
      authController,
      redirectController,
      layoutController,
      signupModalView,
      notifier,
      errorRouter: this.errorRouter,
      // Redux store
      store,
      // React Router history
      history,
    });

    // eslint-disable-next-line no-new
    new SuppressionsRouter({
      region: Mako.bodyRegion,
      layoutController,
      authController,
      redirectController,
      errorRouter: this.errorRouter,
    });

    // eslint-disable-next-line no-new
    new StatsRouter({
      region: Mako.bodyRegion,
      layoutController,
      authController,
      redirectController,
      errorRouter: this.errorRouter,
    });

    DomEvents.init();
    Backbone.history.start({
      pushState: true,
      root: APIConfig.root,
      hashChange: true,
    });
  });

  // eslint-disable-next-line no-undef
  AppInitializer.loadInitialData(authController, SendGridTiara)
    .done((
      account,
      profile,
      // eslint-disable-next-line camelcase
      feature_toggles,
      scopes,
      signupStatus,
      email,
      username,
      accountProfile,
      userPackage,
      userType = [{}] // Could be undefined coming back if in login page
    ) => {
      Mako.start({
        account: account[0],
        profile: profile[0],
        feature_toggles: feature_toggles[0],
        email: email[0],
        username: username[0],
        accountProfile: accountProfile[0],
        signup_status: signupStatus[0],
        user_package: userPackage[0],
        userType: userType[0],
      });
    })
    .fail(() => {
      // This deferred only fails if there was a 401
      authController.logout();
    });
});
