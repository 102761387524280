const Backbone = require("backbone");
const APIConfig = require("apiconfig");

module.exports = Backbone.Model.extend({
  url: `${APIConfig.host}s2s/signup/status`,

  needsToStartProfile() {
    return this.get("profile_required") && !this.get("profile_complete");
  },
});
