const Marionette = require("backbone.marionette");

module.exports = Marionette.LayoutView.extend({
  template: require("templates/stats/table_wrapper.hbs"),

  regions: {
    rowHeader: "[role=statsTableRowHeader]",
    dataTable: "[role=statsTableData]",
  },

  onShow() {
    this.rowHeader.show(this.getOption("statsTableRowHeaderView"));
    this.dataTable.show(this.getOption("statsTableDataView"));
  },

  render() {
    // This needs to play nicely with the single stats view,
    // but since it is a LayoutView it can't be rerendered.
    // So render once and if we are told to get rendered again,
    // just rerender the loading or main view.
    if (!this.rendered) {
      Marionette.LayoutView.prototype.render.call(this);
      this.rendered = true;
      return;
    }

    this.getOption("statsTableRowHeaderView").render();
    this.getOption("statsTableDataView").render();
  },
});
