const Backbone = require("backbone");
const APIConfig = require("apiconfig");
const $ = require("jquery");

module.exports = Backbone.Model.extend({
  urlRoot: `${APIConfig.host}scopes/requests/verify`,

  url() {
    const params = {
      scope_group_name: this.get("scopeGroupName"),
    };
    return (this.urlRoot += `?${$.param(params)}`);
  },
});
