const selectQaHook = (hook) => `[data-qahook="${hook}"]`;
const writeQaHook = (hook) => ({ "data-qahook": hook });
// Adds objects with data-qahooks properties to be passed into React component props
// i.e. { key: { data-qahook: <some_qa_hook> }, ... }
const writeSelectorGenerator = (Selectors) =>
  Object.keys(Selectors)
    .map((key) => ({
      [key]: writeQaHook(Selectors[key]),
    }))
    .reduce((accumulator, nextValue) => ({ ...accumulator, ...nextValue }));
// CSS selector version of the the data-qahooks attributes
// i.e. { key: '[data-qahook="<some_qa_hook>"]', ... }
const selectSelectorGenerator = (Selectors) =>
  Object.keys(Selectors)
    .map((key) => ({
      [key]: selectQaHook(Selectors[key]),
    }))
    .reduce((accumulator, nextValue) => ({ ...accumulator, ...nextValue }));

export { selectQaHook, writeSelectorGenerator, selectSelectorGenerator };
