import { ActionTypes } from './actionTypes';
import { ActionsUnion, createAction } from '../actionBuilder';
import { UserPackageDto } from '../dtos';

export const createUserPackage = (payload: UserPackageDto) => {
  return { type: ActionTypes.CreateUserPackage, payload };
};

export const Actions = {
  getUserPackage: () => createAction(ActionTypes.GetUserPackageRequest),
  getUserPackageSuccess: (userPackage: UserPackageDto) =>
    createAction(ActionTypes.GetUserPackageSuccess, userPackage),
  getUserPackageFailure: (errors: Pick<UserPackageDto, 'errors'>) =>
    createAction(ActionTypes.GetUserPackageFailure, errors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
