// Only add actions to this if they use epics
import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { BillableContactsInfoDto } from '../dtos';

export const Actions = {
  getBillableContactsInfo: () =>
    createAction(ActionTypes.GetBillableContactsInfoRequest),
  getBillableContactsInfoSuccess: (
    billableContactsInfo: BillableContactsInfoDto
  ) =>
    createAction(
      ActionTypes.GetBillableContactsInfoSuccess,
      billableContactsInfo
    ),
  getBillableContactsInfoFailure: (
    billableContactsInfo: BillableContactsInfoDto
  ) =>
    createAction(
      ActionTypes.GetBillableContactsInfoFailure,
      billableContactsInfo
    ),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
