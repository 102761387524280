export enum ActionTypes {
  GetBillableContacts = 'getBillableContacts',
  GetBillableContactsRequest = 'getBillableContacts.request',
  GetBillableContactsSuccess = 'getBillableContacts.success',
  GetBillableContactsFailure = 'getBillableContacts.failure',

  GetBillableContactsCost = 'getBillableContactsCost',
  GetBillableContactsCostRequest = 'getBillableContactsCost.request',
  GetBillableContactsCostSuccess = 'getBillableContactsCost.success',
  GetBillableContactsCostFailure = 'getBillableContactsCost.failure',

  GetBillableContactsInfo = 'getBillableContactsAndCost',
  GetBillableContactsInfoRequest = 'getBillableContactsAndCost.request',
  GetBillableContactsInfoSuccess = 'getBillableContactsAndCost.success',
  GetBillableContactsInfoFailure = 'getBillableContactsAndCost.failure',
}
