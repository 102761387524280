import { Scale } from '../WithScale';

type ClassName = string | undefined;

export function buildClassNames(classNameArr: ClassName[] = []): string {
  return classNameArr
    .reduce((acc, className) => {
      // remove empty or undefined class names
      if (!!className) {
        return [...acc, className];
      }
      return acc;
    }, [])
    .join(' ');
}

export function mapFontScaleClassName(scale: Scale) {
  if (scale === 0) {
    return 'font-scale-0';
  }

  return scale > 0 ? `font-scale-up-${scale}` : `font-scale-down${scale}`;
}
