const APIConfig = require("apiconfig");
const Marionette = require("backbone.marionette");
const $ = require("jquery");
require("jquery.cookie");

module.exports = Marionette.Controller.extend({
  redirectMap: {
    confirm_email_page: "signup/update_email",
    provision_page: "signup/account_details",
  },

  initialize(options) {
    options = options || {};
    this.authTokenCookieName = options.cookieName || "mako_auth_token";
    this.signupCompleteCookieNamePrefix =
      options.signupCompleteCookieNamePrefix || "mako_signup_complete_";
    this.formSubmittedCookieNamePrefix =
      options.formSubmittedCookieNamePrefix || "mako_contact_form_submitted_";
  },

  getSignupCompleteCookieName() {
    return (
      this.signupCompleteCookieNamePrefix +
      $.cookie(this.authTokenCookieName).substring(0, 6)
    );
  },

  getFormSubmittedCookieName() {
    return (
      this.formSubmittedCookieNamePrefix +
      $.cookie(this.authTokenCookieName).substring(0, 6)
    );
  },

  isSignupCompleted() {
    const signupComplete = $.cookie(this.getSignupCompleteCookieName());

    return signupComplete === "ok";
  },

  shouldRedirect() {
    const deferred = new $.Deferred();

    // if the signup is completed, skip the rest of this method
    if (this.isSignupCompleted()) {
      return deferred.resolve(false);
    }

    $.ajax({
      url: `${APIConfig.host}signup/redirect`,
      contentType: "application/json",
      context: this,
    }).done(function(data, statusText, xhr) {
      const status = xhr.status;

      if (status === 204) {
        // set the persistent cookie
        $.cookie(this.getSignupCompleteCookieName(), "ok");
        deferred.resolve(false);
      } else if (status === 200) {
        if (
          this.getWindowLocationHref().indexOf(
            this.redirectMap[data.redirect]
          ) > -1
        ) {
          // if the redirect would take us here again, prevent that loop
          deferred.resolve(true);
          return;
        }

        if ($.cookie(this.getFormSubmittedCookieName()) === "ok") {
          deferred.resolve(true, "signup/please_wait");
        } else {
          deferred.resolve(true, this.redirectMap[data.redirect]);
        }
      }
    });

    return deferred;
  },

  signupRedirect() {
    const deferred = this.shouldRedirect();
    const view = this;

    $.when(deferred).done((shouldRedirect, route) => {
      if (shouldRedirect && route) {
        view.getOption("signupModalView").showModal(route);
      }
    });
  },

  getWindowLocationHref() {
    return window.location.href;
  },
});
