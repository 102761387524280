export enum ActionTypes {
  GetUserWithTimezone = 'getUserWithTimezone',

  UpdateTimezone = 'updateTimezone',
  UpdateTimezoneRequest = 'updateTimezone.request',
  UpdateTimezoneSuccess = 'updateTimezone.success',
  UpdateTimezoneFailure = 'updateTimezone.failure',

  ClearTimezoneServerErrors = 'clearTimezoneServerErrors',
}
