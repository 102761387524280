const Marionette = require("backbone.marionette");

const Palette = require("palette");
const _ = require("underscore");

module.exports = Marionette.ItemView.extend({
  template: require("templates/suppression.hbs"),
  tagName: "tr",
  ui: {
    suppression: "[role=suppression]",
  },
  serializeData() {
    return _.extend(this.model.toJSON(), {
      // TODO: Standardize key name at API level
      created: this.model.get("created") || this.model.get("created_at"),
      // Having duplicate name attributes for checkboxes prevent you from clicking multiple checkboxes
      // with the same name. As a result, we create a unique name based off of the email plus Backbone cid
      emailId: `${this.model.get("email")}-${this.model.cid}`,
    });
  },
  modelEvents: {
    change: "render",
  },
  events: {
    "change [role=selectBox]": "selectBox",
  },

  selectBox() {
    this.model.set("checked", !this.model.get("checked"));
    this.model.trigger("updateCounter");
    Palette.bindGravatars(this.$el);
  },
});
