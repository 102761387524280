const Backbone = require("backbone");
const APIConfig = require("apiconfig");

module.exports = Backbone.Model.extend({
  urlRoot: `${APIConfig.host}user/type`,

  isAwsCustomer() {
    const userType = this.get("type");
    const resellerUsername = this.get("reseller_username");

    // user must be a reseller customer, and the
    // reseller's username must be reseller@aws.com
    return (
      (userType === "reseller_customer" ||
        userType === "reseller_customer_free") &&
      resellerUsername === "reseller@aws.com"
    );
  },
  isAwsDeactivated() {
    const userType = this.get("type");
    const resellerUsername = this.get("reseller_username");

    return (
      userType === "reseller_customer_free" &&
      resellerUsername === "reseller@aws.com"
    );
  },
});
