import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { EaseStorageDto, IpsDto } from '../dtos';

export const Actions = {
  getIps: () => createAction(ActionTypes.GetIpsRequest),
  getIpsSuccess: (ipsDto: IpsDto) =>
    createAction(ActionTypes.GetIpsSuccess, ipsDto),
  getIpsFailure: () => createAction(ActionTypes.GetIpsFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
