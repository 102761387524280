var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-required";
},"3":function(container,depth0,helpers,partials,data) {
    return "is-error";
},"5":function(container,depth0,helpers,partials,data) {
    return "is-disabled";
},"7":function(container,depth0,helpers,partials,data) {
    return "is-full-width";
},"9":function(container,depth0,helpers,partials,data) {
    return "is-search";
},"11":function(container,depth0,helpers,partials,data) {
    return "has-value";
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.input_type || (depth0 != null ? depth0.input_type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"input_type","hash":{},"data":data}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    return "text";
},"17":function(container,depth0,helpers,partials,data) {
    return "autofocus";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"input-info-tooltip\" style=\"line-height: 16px\" role=\"tooltip-container\">\n      <span\n              data-tooltip=\""
    + container.escapeExpression(((helper = (helper = helpers.tooltip_text || (depth0 != null ? depth0.tooltip_text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"tooltip_text","hash":{},"data":data}) : helper)))
    + "\"\n              data-tooltip-pos=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tooltip_direction : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "\"\n              data-tooltip-length=\"large\" role=\"tooltip-span\"\n      >\n        <i class=\"sg-icon sg-icon-info-circle input-info-icon\" role=\"tooltip-icon\"></i>\n      </span>\n    </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.tooltip_direction || (depth0 != null ? depth0.tooltip_direction : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tooltip_direction","hash":{},"data":data}) : helper)));
},"22":function(container,depth0,helpers,partials,data) {
    return "right";
},"24":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"input-text-wrap\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_required : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.error_text : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_disabled : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_full_width : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_search : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\"\n     style=\""
    + alias4(((helper = (helper = helpers.container_style || (depth0 != null ? depth0.container_style : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"container_style","hash":{},"data":data}) : helper)))
    + "\"\n     role=\""
    + alias4(((helper = (helper = helpers.container_role || (depth0 != null ? depth0.container_role : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"container_role","hash":{},"data":data}) : helper)))
    + "\">\n\n  <label class=\"input-text-label\" for=\""
    + alias4(((helper = (helper = helpers.container_role || (depth0 != null ? depth0.container_role : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"container_role","hash":{},"data":data}) : helper)))
    + "-input-id\" role=\"label\">\n    "
    + alias4(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data}) : helper)))
    + "\n  </label>\n\n  <input class=\"input-text\"\n         data-field=\""
    + alias4(((helper = (helper = helpers["data-field"] || (depth0 != null ? depth0["data-field"] : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"data-field","hash":{},"data":data}) : helper)))
    + "\"\n         id=\""
    + alias4(((helper = (helper = helpers.container_role || (depth0 != null ? depth0.container_role : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"container_role","hash":{},"data":data}) : helper)))
    + "-input-id\"\n         type=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.input_type : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\"\n         role=\"input\"\n         value=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\"\n         maxlength=\""
    + alias4(((helper = (helper = helpers.max_length || (depth0 != null ? depth0.max_length : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"max_length","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.autofocus : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n  >\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tooltip_text : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <span class=\"input-info danger "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.error_text : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" role=\"error\">\n    "
    + alias4(((helper = (helper = helpers.error_text || (depth0 != null ? depth0.error_text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"error_text","hash":{},"data":data}) : helper)))
    + "\n  </span>\n\n  <span class=\"input-info "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.info_text : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" role=\"info\">\n    "
    + alias4(((helper = (helper = helpers.info_text || (depth0 != null ? depth0.info_text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"info_text","hash":{},"data":data}) : helper)))
    + "\n  </span>\n</div>\n";
},"useData":true});