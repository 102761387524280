export enum ActionTypes {
  // the 'GetInvoices' action is for loading state
  GetInvoices = 'getInvoices',
  GetInvoicesRequest = 'getInvoices.request',
  GetInvoicesSuccess = 'getInvoices.success',
  GetInvoicesFailure = 'getInvoices.failure',
  RetryInvoicePayment = 'retryInvoicePayment',
  RetryInvoicePaymentRequest = 'retryInvoicePayment.request',
  RetryInvoicePaymentSuccess = 'retryInvoicePayment.success',
  RetryInvoicePaymentFailure = 'retryInvoicePayment.failure',
}
