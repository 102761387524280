const $ = require("jquery");
const APIConfig = require("apiconfig");
const _ = require("underscore");
const Marionette = require("backbone.marionette");
const DomEvents = require("../helpers/dom_events");
require("jquery.cookie");

// models
const TokensEmailModel = require("../models/tokens_email_model");
const UsernameModel = require("../models/username_model");
const AccountProfileModel = require("../models/account_profile_model");
const UserTypeModel = require("../models/user_type_model");

module.exports = {
  loadInitialData(authController, tiara) {
    // noinspection JSUnusedLocalSymbols
    let ready,
      impersonatingUsername,
      tiaraParams,
      deferredUserData,
      shouldRenderSidebar,
      originalMonitorDOMRefresh;

    originalMonitorDOMRefresh = Marionette.MonitorDOMRefresh;

    Marionette.MonitorDOMRefresh = function(view) {
      view.on("dom:refresh", () => {
        if (deferredUserData) {
          deferredUserData.scopes.done(() => {
            authController.deny(view);
          });

          view.$el.find("[data-confirm]").confirm();
        }

        DomEvents.checkInputFields();
        DomEvents.select2Everywhere();
        DomEvents.tooltipify();
      });

      originalMonitorDOMRefresh(view);
    };

    ready = new $.Deferred();

    tiaraParams = {
      jQuery: $,
      underscore: _,
      api_host: APIConfig.host,
      target: "[role=container]",
      sg_host: APIConfig.sendgrid_com,
      nlvxHost: APIConfig.nlvx_host,
      makoHost: APIConfig.mako_host,
      // This toggles Tiara injecting Segment and other third party scripts.
      includeThirdPartyScripts:
        APIConfig.thirdPartyScripts && !window.blackListInit,
    };

    if (!this.shouldRenderSidebar(authController)) {
      // not going to reuse anything from tiara, we're on basic pages like signup, provision, error, etc that don't reuse fetched tiara data
      tiaraParams.logoutCallback = function() {
        /* no-op */
      };
      tiaraParams.shouldHideSidebar = true;
      tiaraParams.shouldHideSegmentIntegrations = true;
      tiaraParams.shouldMakeRequests = false;

      tiara.init(tiaraParams); // Init strictly for analytics

      ready.resolve([{}], [{}], [[]], [{}], [{}], [{}], [{}], [{}], [{}]);
      authController.setPrefilter();
      return ready;
    }

    deferredUserData = tiara.init(tiaraParams);

    tiara.deferreds = deferredUserData;

    deferredUserData.scopes.done((data) => {
      authController.scopes = authController.scopes || {};
      if (data) {
        const scopes = data.scopes;
        for (let i = 0; i < scopes.length; i++) {
          authController.scopes[scopes[i]] = true;
        }
      }
    });

    impersonatingUsername = tiara.getImpersonatingUsername();

    // after sidebar loads, clean the url and set prefilters
    $.whenAll([
      deferredUserData.account,
      deferredUserData.profile,
      deferredUserData.feature_toggles,
      deferredUserData.scopes,
      deferredUserData.signup_status,
      deferredUserData.pkg,
    ]).done(
      (
        account,
        profile,
        feature_toggles,
        scopes,
        signupStatus,
        userPackage
      ) => {
        window.track("Finished first batch of deferreds");
        authController.setPrefilter(impersonatingUsername);

        const tokensEmailModel = new TokensEmailModel();
        const emailXHR = tokensEmailModel.fetch();

        const usernameModel = new UsernameModel();
        const usernameXHR = usernameModel.fetch();

        const accountProfileModel = new AccountProfileModel();
        const accountProfileXHR = accountProfileModel.fetch();

        const userTypeModel = new UserTypeModel();
        const userTypeXHR = userTypeModel.fetch();

        $.whenAll([emailXHR, usernameXHR, accountProfileXHR, userTypeXHR]).done(
          (email, username, accountProfile, userType) => {
            window.track("Finished second batch of deferreds");

            if (
              _.some(
                [
                  account,
                  profile,
                  feature_toggles,
                  scopes,
                  signupStatus,
                  email,
                  username,
                  accountProfile,
                  userPackage,
                  userType,
                ],
                (def) => def[2] === "UNAUTHORIZED"
              )
            ) {
              ready.fail();
            } else {
              ready.resolve(
                account,
                profile,
                feature_toggles,
                scopes,
                signupStatus,
                email,
                username,
                accountProfile,
                userPackage,
                userType
              );
            }
          }
        );
      }
    );

    return ready;
  },

  // private

  getImpersonatingUsername() {
    const regExForUsername = /[\?&]impersonatingUsername=([^&]+)/;
    const result = location.search.match(regExForUsername);
    if (result) {
      return decodeURIComponent(result[1]);
    }
    return null;
  },

  shouldRenderSidebar(authController) {
    // never show sidebar if the auth token doesn't exist
    if (!authController.checkAuthToken()) {
      return false;
    }

    // signup or error pages or public pages
    return !this.routeStartsWith(
      "signup|errors|login|forgot_password|reset_password|validate_2fa|account/billing/thanks|public"
    );
  },

  routeStartsWith(routePrefix) {
    let route = this.getCurrentRoute();
    if (route.indexOf(APIConfig.root) === 0) {
      route = route.substring(APIConfig.root.length);
    }

    const renderSidebar = new RegExp(`^\/?(${routePrefix})`);
    return renderSidebar.test(route);
  },

  getCurrentRoute() {
    // Backbone.history hasn't started yet, so manually get route
    return window.location.pathname;
  },
};
