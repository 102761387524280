const $ = require("jquery");

const Backbone = require("backbone");
const _ = require("underscore");

module.exports = Backbone.Model.extend({
  parse(data, options) {
    const computations = options.collection.computations;
    if (!computations) {
      return data;
    }
    const self = this;

    _.each(data.stats, stat => {
      _.each(computations, c => {
        const n = stat.metrics[c.numerator];
        let d;
        let v = 0;

        if (_.isFunction(c.denominator)) {
          v = function() {
            const d = c.denominator(self);
            if (d !== 0) {
              return n / d;
            }

            return 0;
          };
        } else {
          d = stat.metrics[c.denominator];

          if (d !== 0) {
            v = n / d;
          }
        }

        stat.metrics[`${c.numerator}_rate`] = v;
      });
    });

    return data;
  },

  metricDelivered() {
    const selectedProvider = $(
      "#itemSelectorContainer-input-id :selected"
    ).val();

    if (selectedProvider) {
      const stat = this.get("stats").find(ele => ele.name === selectedProvider);
      if (stat && stat.metrics) {
        return stat.metrics.delivered;
      }
    }

    return 0;
  },

  metricRequests() {
    const selectedProvider = $(
      "#itemSelectorContainer-input-id :selected"
    ).val();

    let provider = {};
    if (selectedProvider) {
      const stat = this.get("stats").find(ele => ele.name === selectedProvider);
      if (stat && stat.metrics) {
        provider = stat.metrics;
      }
    }
    const { delivered = 0, blocks = 0, bounces = 0, drops = 0 } = provider;
    const processed = delivered + blocks + bounces;

    return processed + drops;
  },

  metricSum(metric) {
    return _.chain(this.get("stats"))
      .map(s => s.metrics[metric])
      .reduce((sum, item) => sum + item)
      .value();
  },
});
