// TODO: do something/anything about multiple modals possibility?
// TODO: Allow for async confirm/cancel presses to allow outside code to reject/approve submits
// TODO: Dynamically adjust the margin-left of modal content
// TODO: Actually hide the thing on transitionend so it removes any buttons from tabindex
const $ = require("jquery");

window.Mako = window.Mako || {};

(function modal() {
  const defaults = {
    bodyOpenClassName: "modal-open",
    showModalClassName: "show",
    modalMaskClassName: "modal-mask",
  };

  function Modal($modal, options) {
    this.$modal = $modal;
    this.bindEvents();
    this.options = $.extend({}, defaults, options);
  }

  Modal.defaults = defaults;

  Modal.prototype = {
    bindEvents() {
      const self = this;
      const $modal = this.$modal;
      const $cancel = $modal.find("[data-modal-cancel]");
      const $confirm = $modal.find("[data-modal-confirm]");
      const $content = $modal.find("[data-modal-content]");

      $cancel.on("click", e => {
        self.hide();
        $(self).trigger("modal:cancel");

        e.stopPropagation();
      });

      $confirm.on("click", e => {
        self.hide();
        $(self).trigger("modal:confirm");

        e.stopPropagation();
      });

      $content.on("click", e => {
        const target = e.target;
        // Don't trap events if it's a confirmation link.
        // Rails has some magic where links with data-method="delete"
        // will automatically xhr send that properly and then reload
        // the page or something. It's pretty magical. This makes it work.
        if (!$(target).is("[data-modal-confirm],[data-modal-cancel]")) {
          e.stopPropagation();
        }
      });

      $modal.on("click", () => {
        self.hide();
        $(self).trigger("modal:cancel");
      });
    },

    show() {
      const isShown = this.$modal.hasClass(this.options.showModalClassName);
      const self = this;

      if (!isShown) {
        $("body").addClass(this.options.bodyOpenClassName);
        this.$modal.show();

        this.$modal.addClass(this.options.showModalClassName);
        this.$modal.on("transitionend", () => {
          self.afterShow();
        });
      }
    },

    afterShow() {
      $(this).trigger("modal:shown");
      this.$modal.off("transitionend");
    },

    hide() {
      const self = this;

      this.$modal.on("transitionend", () => {
        self.afterHide();
      });

      $("body").removeClass(this.options.bodyOpenClassName);
      this.$modal.removeClass(this.options.showModalClassName);
    },

    afterHide() {
      this.$modal.hide();
      this.$modal.off("transitionend");
      $(this).trigger("modal:hidden");
    },
  };

  window.Mako.Modal = Modal;
})();
