export enum ActionTypes {
  GetPaymentMethod = 'getPaymentMethod',
  GetPaymentMethodRequest = 'getPaymentMethod.request',
  GetPaymentMethodSuccess = 'getPaymentMethod.success',
  GetPaymentMethodFailure = 'getPaymentMethod.failure',

  UpdateDefaultPaymentMethod = 'updateDefaultPaymentMethod',
  UpdateDefaultPaymentMethodRequest = 'updateDefaultPaymentMethod.request',
  UpdateDefaultPaymentMethodSuccess = 'updateDefaultPaymentMethod.success',
  UpdateDefaultPaymentMethodFailure = 'updateDefaultPaymentMethod.failure',

  ReactivateUser = 'reactivateUser',
  ReactivateUserRequest = 'reactivateUser.request',
  ReactivateUserSuccess = 'reactivateUser.success',
  ReactivateUserFailure = 'reactivateUser.failure',
}
