// one auth controller to rule them all
const APIConfig = require("apiconfig");
const Marionette = require("backbone.marionette");

const $ = require("jquery");
const _ = require("underscore");
const parseURI = require("parse-uri");
const cleanUrl = require("../helpers/clean_url");
require("jquery.cookie");

const MAKO_COOKIE_NAME = "mako_auth_token";
const SESSION_COOKIE_NAME = "session_identifier";
const IMPERSONATING_USERNAME_COOKIE = "sendgrid-impersonating-username";
const ADMIN_IMPERSONATION_COOKIE = "sendgrid-admin-impersonation";

module.exports = Marionette.Controller.extend({
  initialize(options) {
    this.tiara = options.tiara;
    this.scopes = {};
    this.setSessionCookie();
  },

  // used only in development/staging
  authenticate(username, password) {
    const self = this;
    return this.fetchToken(username, password).success(({ token }) => {
      this.setToken(token);
      this.fetchUserPackage(token).then(
        (userPackage) => {
          self.redirectToDashBoardOrAds(userPackage);
        },
        () => self.redirectToDashboard()
      );
    });
  },

  fetchToken(username, password) {
    return $.ajax({
      type: "POST",
      url: `${APIConfig.host}public/tokens`,
      contentType: "application/json",
      data: JSON.stringify({
        username,
        password,
      }),
    });
  },

  redirectToDashBoardOrAds(userPackage) {
    const adsPackageName = "ads";
    const userPackageName = userPackage.name.toLowerCase();
    // If the user has an ads package,
    if (userPackageName === adsPackageName) {
      const labsPath = `${APIConfig.labs_host}/grow`;
      // redirect them to Ads: https://labs.sendgrid.com/grow or https://staging.labs.sendgrid.com/grow
      return this.redirect(labsPath);
    }
    // otherwise, redirect them to the Mako dashboard
    return this.redirectToDashboard();
  },

  fetchUserPackage(token) {
    return $.ajax({
      type: "GET",
      url: `${APIConfig.host}user/package`,
      contentType: "application/json",
      headers: {
        Authorization: `token ${token}`,
      },
    });
  },

  setToken(token, isSendToSend) {
    // set cookie then redirect to dashboard
    $.cookie(MAKO_COOKIE_NAME, token);

    // this gets deleted by tiara during logout with tiara.defaultLogout
    $.cookie(ADMIN_IMPERSONATION_COOKIE, token);

    let redirectUrl = APIConfig.root;

    if (isSendToSend) {
      redirectUrl += "guide?verified=true";
      this.redirect(redirectUrl);
    }

    this.resetSessionCookie();
  },

  loginWithToken(token, isSendToSend) {
    this.setToken(token, isSendToSend);
    this.redirectToDashboard();
  },

  isImpersonating() {
    return (
      !!$.cookie(ADMIN_IMPERSONATION_COOKIE) ||
      !!$.cookie(IMPERSONATING_USERNAME_COOKIE)
    );
  },

  checkAuthToken() {
    return !!$.cookie(MAKO_COOKIE_NAME);
  },

  // hide all elements based on user scopes
  deny(view) {
    const self = this;

    function authorize($elt, scopes) {
      const formElements = "input,select,textarea,button";
      const classes = {
        ".btn": "is-disabled",
        ".switch": "is-switch-disabled",
      };

      for (let i = 0; i < scopes.length; i++) {
        const scope = scopes[i].trim();

        // hide element if user has a given scope and element is set to hide
        if (self.scopes[scope] && $elt.data("authorized") === "hide") {
          $elt.addClass("hidden");
          break;
        }

        // hide all user doesn't have scopes for
        if (!self.scopes[scope]) {
          // disable form fields
          $elt
            .find(formElements)
            .addBack(formElements)
            .attr("disabled", "disabled")
            .addClass("is-disabled");

          // visually disable elements that look like buttons or toggles
          Object.keys(classes).forEach((selector) => {
            $elt
              .find(selector)
              .addBack(selector)
              .addClass(classes[selector]);
          });

          $elt.find("a").addClass("hidden");

          if ($elt.data("unauthorized") === "hide") {
            $elt.addClass("hidden");
          }

          $elt.find("[data-unauthorized=hide]").addClass("hidden");
          $elt.trigger("mako:unauthorized", scope);

          break;
        }
      }
    }

    // find all child elements with data-permissions
    view.$el.find("[data-permissions]").each(function() {
      const $elt = $(this);
      let perms = $elt.attr("data-permissions").split(/,/);

      // find any parents that also require scopes
      $elt.parents("[data-permissions]").each(function() {
        perms = perms.concat(
          $(this)
            .attr("data-permissions")
            .split(/,/)
        );
      });

      // authorize on the element's scopes and parent's scopes
      authorize($elt, perms);
    });
  },

  logout(clickedLogout) {
    this.tiara.defaultLogout(clickedLogout);
  },

  setSessionCookie() {
    if (typeof $.cookie(SESSION_COOKIE_NAME) === "undefined") {
      // Generates a fairly long random identifier for the session
      // This cookie doesn't matter for auth and is used only to identify session in 3rd party integs
      const sessionCookie = `${(Math.random() * 1e16).toString(36)}-${(
        Math.random() * 1e16
      ).toString(36)}-${(Math.random() * 1e16).toString(36)}`;
      $.cookie(SESSION_COOKIE_NAME, sessionCookie);
    }
  },

  getSessionCookie() {
    return $.cookie(SESSION_COOKIE_NAME);
  },

  unsetSessionCookie() {
    $.removeCookie(SESSION_COOKIE_NAME);
  },

  resetSessionCookie() {
    this.unsetSessionCookie();
    this.setSessionCookie();
  },

  redirectTo2FA() {
    // This code is duplicated in Tiara with the login redirect stuff it would be nice to consolidate it
    const redirectTo = encodeURIComponent(
      window.location.pathname + window.location.search
    );
    this.redirect(`${APIConfig.root}validate_2fa?redirect_to=${redirectTo}`);
  },

  redirectToDashboard(followRedirect = true) {
    const parsedUri = parseURI(this.getWindowLocation());
    const whitelist = [
      "support.sendgrid.com",
      "sendgrid.com",
      "staging.sendgrid.com",
      "mc.sendgrid.com",
      "mc.staging.sendgrid.com",
      "labs.sendgrid.com",
      "staging.labs.sendgrid.com",
      "dev.labs.sendgrid.com",
    ];

    if (followRedirect && parsedUri.queryKey.redirect_to) {
      let redirectUrl = decodeURIComponent(parsedUri.queryKey.redirect_to);

      if (!redirectUrl.match(/^(https?:)?\/.*/)) {
        // bad proto should be rejected due to xss risk
        redirectUrl = "/";
      }

      const parsedRedirectUrl = parseURI(redirectUrl);

      if (whitelist.indexOf(parsedRedirectUrl.host) === -1) {
        // Remove potentially unsafe external links
        redirectUrl = cleanUrl(redirectUrl);
      }

      this.redirect(redirectUrl);
    } else {
      this.redirect(APIConfig.root);
    }
  },

  setPrefilter(impersonatingUsername) {
    const self = this;
    $.ajaxPrefilter((options, originalOptions, jqXHR) => {
      // only if request is api.sendgrid.com
      if (options.url.indexOf(APIConfig.host) === 0) {
        const token = $.cookie(MAKO_COOKIE_NAME);

        jqXHR.fail((response) => {
          if (response.status === 401 && token) {
            self.logout();
          } else if (
            response.status === 403 &&
            token &&
            response.responseJSON.errors &&
            response.responseJSON.errors.length > 0 &&
            response.responseJSON.errors[0].field === "2fa" &&
            self.getCurrentRoute() !== "/validate_2fa"
          ) {
            self.redirectTo2FA();
          }
        });

        jqXHR.setRequestHeader("Authorization", `token ${token}`);
        // skipImpersonation is set in tiara, needed for subuser searching when impersonating
        if (impersonatingUsername && !options.skipImpersonation) {
          jqXHR.setRequestHeader("On-behalf-of", impersonatingUsername);
        }
      }
    });
  },

  getCurrentRoute() {
    // Backbone.history hasn't started yet, so manually get route
    return window.location.pathname;
  },

  getScopes() {
    // Used on api keys create and edit permissions views
    return this.scopes;
  },

  verify(scope) {
    return _.has(this.scopes, scope);
  },

  // private
  getWindowLocation() {
    return window.location;
  },

  redirect(url) {
    window.parent.location.href = url;
  },
});
