const Marionette = require("backbone.marionette");

const APIConfig = require("apiconfig");

module.exports = Marionette.ItemView.extend({
  template: require("templates/signup/modal.hbs"),
  ui: {
    signupModal: "[role=signupModal]",
    iframe: "[role=iframe]",
  },

  showModal(path) {
    this.bindUIElements();
    const host = this.getOrigin();
    this.ui.iframe.attr(
      "src",
      `${host + APIConfig.root + path}?noFeedback=true`
    );
    this.ui.signupModal.modal("show");
  },

  getOrigin() {
    return location.origin;
  },
});
