var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <h2 data-role=\"title\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</h2>\n\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  <div class='input-text-wrap' data-text-input-parent=\"true\">\n    <input data-text-input=\"true\"  data-field=\"email\" class=\"input-text\" id=\"email\" role=\"emailAddress\" type=\"text\" name=\"email\" value=\""
    + alias2(alias1((depth0 != null ? depth0.email : depth0), depth0))
    + "\" />\n    <label class=\"input-text-label\" for=\"email\">Email Address</label>\n  </div>\n\n  <div class=\"btn-list\">\n    <div class=\"btn btn-primary\" role=\"submitButton\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "    <p>We want to make sure we can get in touch with you in case there's a problem with your account. Can you confirm your email address for us?</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    <p>We want to make sure we can get in touch with you in case there's a problem with your account and we don't currently have your email address saved in your profile.</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        Send Confirmation Email\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        Send Verification Email\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/centerModal.hbs"),depth0,{"name":"centerModal","hash":{"containerRole":"emailConfirmationContainer"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});