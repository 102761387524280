const $ = require("jquery");

module.exports = {
  show(modalContainer) {
    $(window).scrollTop(0);
    $(modalContainer)
      .find("[data-role=modal], [data-role=mask]")
      .addClass("is-visible");
  },

  hide(modalContainer) {
    $(modalContainer)
      .find("[data-role=modal], [data-role=mask]")
      .removeClass("is-visible");
  },
};
