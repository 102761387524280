import * as React from 'react';
import { Grid } from '../../../components/__common__/Grid';

import './index.scss';
import { ReactNode } from 'react';

export interface PageLayoutProps {
  children: React.ReactNode;
  shouldScrollX?: boolean;
}

/**
 * PageLayout needs the component lifecycle hooks but isn't concerned with
 * state.
 */
export class PageLayout extends React.Component<PageLayoutProps, {}> {
  private readonly tiaraContainerEl: HTMLElement;
  private scrollableClass = 'is-scrollable';

  constructor(props: PageLayoutProps) {
    super(props);
    const el = document.getElementsByClassName('tiara-content-container');
    if (el && el.length) {
      this.tiaraContainerEl = el[0] as HTMLElement;
    }
  }

  public componentDidMount(): void {
    if (this.props.shouldScrollX && this.tiaraContainerEl) {
      this.tiaraContainerEl.classList.add(this.scrollableClass);
    }
  }

  public componentWillUnmount(): void {
    if (this.props.shouldScrollX && this.tiaraContainerEl) {
      this.tiaraContainerEl.classList.remove(this.scrollableClass);
    }
  }

  public render(): ReactNode {
    const { children } = this.props;
    return (
      <div className="page-layout">
        <Grid.Row>
          <Grid.Column width={12}>{children}</Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}
