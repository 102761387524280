const Marionette = require("backbone.marionette");

module.exports = Marionette.LayoutView.extend({
  template: require("templates/stats/table_layout.hbs"),

  regions: {
    statsTableView: "[role=statsTable]",
    statsTableHeaderView: "[role=header]",
  },

  ui: {
    container: "[role=container]",
  },

  collectionEvents: {
    sync: "hideTable",
  },

  onShow() {
    this.statsTableHeaderView.show(this.getOption("statsTableHeaderView"));
    this.statsTableView.show(this.getOption("statsTableView"));
    this.hideTable();
  },

  hideTable() {
    if (this.getOption("hideWithoutData") && this.collection.length === 0) {
      this.ui.container.addClass("hidden");
    } else {
      this.ui.container.removeClass("hidden");
    }
  },
});
