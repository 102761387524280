import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
// premier packages are the same type as normal packages
import { PackageDto } from '../dtos';

export const Actions = {
  getPremierPackages: () => createAction(ActionTypes.GetPremierPackagesRequest),
  getPremierPackagesSuccess: (packagesDto: PackageDto[]) =>
    createAction(ActionTypes.GetPremierPackagesSuccess, packagesDto),
  getPremierPackagesFailure: () =>
    createAction(ActionTypes.GetPremierPackagesFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
