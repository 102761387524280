import { ActionTypes } from './actionTypes';
import { Timezone } from '../types/user';
import { ActionsUnion, createAction } from '../actionBuilder';
import { TimezoneDto } from '../dtos';

export const getUserWithTimezone = (payload: Timezone) => {
  return { type: ActionTypes.GetUserWithTimezone, payload };
};

export const Actions = {
  updateTimezoneRequest: (timezone: Timezone) =>
    createAction(ActionTypes.UpdateTimezoneRequest, timezone),
  updateTimezoneSuccess: (timezone: TimezoneDto) =>
    createAction(ActionTypes.UpdateTimezoneSuccess, timezone),
  updateTimezoneFailure: (errors: TimezoneDto) =>
    createAction(ActionTypes.UpdateTimezoneFailure, errors),
  clearTimezoneServerErrors: () =>
    createAction(ActionTypes.ClearTimezoneServerErrors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
