var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"emptyData\">\n  <h3>"
    + alias4(((helper = (helper = helpers.messageText || (depth0 != null ? depth0.messageText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageText","hash":{},"data":data}) : helper)))
    + "</h3>\n  <h4>"
    + alias4(((helper = (helper = helpers.subMessageText || (depth0 != null ? depth0.subMessageText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subMessageText","hash":{},"data":data}) : helper)))
    + "</h4>\n</div>";
},"useData":true});