const Backbone = require("backbone");
const _ = require("underscore");

module.exports = Backbone.Model.extend({
  toJSON(options) {
    let json = Backbone.Model.prototype.toJSON.apply(this, arguments);

    if (this.excludes && _.has(options || {}, "emulateHTTP")) {
      json = _.omit(json, this.excludes);
    }

    return json;
  },
});
