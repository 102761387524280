const md5 = require("crypto-js/md5");
const Handlebars = require("handlebars");

module.exports = function(email, size) {
  const $container = $("<span/>").addClass("avatar");
  const $image = $("<img/>");
  $container.append($image);

  $image
    .attr("role", "avatar")
    .attr("src", "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D")
    .width(size)
    .height(size);

  if (email) {
    $container.attr("data-email", email);
    $image.attr(
      "src",
      `//s.gravatar.com/avatar/${md5(email).toString()}?s=${size}&d=404`
    );
  } else {
    $image.addClass("emptyImage");
  }

  return new Handlebars.SafeString($container[0].outerHTML);
};
