const APIConfig = require("apiconfig");
const ImpersonationModel = require("../models/impersonation_model");

module.exports = ImpersonationModel.extend({
  url: `${APIConfig.host}user/email`,
  idAttribute: "email",

  validate(attrs, options) {
    if (!attrs.email || attrs.email.length === 0) {
      return { field: "email", message: "email address is required" };
    }
  },
});
