const _ = require("underscore");
const fractionConvertPercentage = require("./fractionConvertPercentage");

module.exports = function(key, context) {
  let value = context[`${key}_rate`];
  if (_.isFunction(value)) {
    value = value();
  }

  return fractionConvertPercentage(value);
};
