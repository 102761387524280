const APIConfig = require("apiconfig");
const ImpersonationModel = require("../models/impersonation_model");

module.exports = ImpersonationModel.extend({
  url: `${APIConfig.host}user/profile`,

  isNew() {
    // always do a patch/put not post
    return false;
  },
});
