const Marionette = require("backbone.marionette");
const $ = require("jquery");
const Modal = require("../../helpers/modal");

const SaveBehavior = require("../../behaviors/save_behavior");
const TokensEmailModel = require("../../models/tokens_email_model");
const moment = require("moment");

const cookie = "mako_email_confirmed";

module.exports = Marionette.ItemView.extend({
  template: require("templates/email_confirmation/email_confirmation_modal.hbs"),

  behaviors: {
    SaveBehavior: {
      behaviorClass: SaveBehavior,
      modelClass: TokensEmailModel,
    },
  },

  ui: {
    modalContainer: "[data-role=emailConfirmationContainer]",
    modal: "[data-role=emailConfirmationContainer] [data-role=modal]",
    mask: "[data-role=emailConfirmationContainer] [data-role=mask]",
    close: "[data-role=emailConfirmationContainer] [data-role=close]",

    submitButton: "[role=submitButton]",
  },

  events: {
    "click @ui.close": "hideModal",
    "click @ui.mask": "hideModal",
  },

  initialize() {
    this.profileModel = this.getOption("profileModel");
    this.tokensEmailModel = this.getOption("tokensEmailModel");
    this.redirectController = this.getOption("redirectController");
    this.authController = this.getOption("authController");
  },

  showModal(resend) {
    if (resend) {
      this.template = require("templates/email_confirmation/email_confirmation_resend_modal.hbs");
    } else {
      this.template = require("templates/email_confirmation/email_confirmation_modal.hbs");
    }

    this.render();

    Modal.show(this.ui.modalContainer);
  },

  hideModal() {
    Modal.hide(this.ui.modalContainer);
  },

  onShow() {
    // never show when impersonating or on the s2s flow or in Cypress tests
    if (
      this.authController.isImpersonating() ||
      window.Reqres.request("signupStatus") ||
      window.Cypress
    ) {
      return;
    }

    const hasCookie = $.cookie(cookie) !== undefined;
    let confirmedAt = this.tokensEmailModel.get("confirmed_at");
    if (confirmedAt) {
      confirmedAt = moment.unix(confirmedAt);
    }

    const hasConfirmed =
      confirmedAt && moment().diff(confirmedAt, "months") < 12;
    const shouldRedirect = this.redirectController.shouldRedirect();
    const view = this;

    $.when(shouldRedirect).done(redirect => {
      // if signup is completed and no cookie is present and they have not confirmed yet
      if (!redirect && !hasCookie && !hasConfirmed) {
        view.showModal();
      }
    });
  },

  onSaved() {
    this.hideModal();
  },

  onErrorServer(model, errors) {
    this.triggerMethod("error", model, errors);
  },

  serializeData() {
    const firstName = this.profileModel.get("first_name");
    const result = {
      email: this.tokensEmailModel.get("email"),
    };

    if (!firstName) {
      result.title = "Hi!";
    } else {
      result.title = `Hi ${firstName}!`;
    }

    return result;
  },
});
