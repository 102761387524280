import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { EaseInfoDto, EaseStorageDto } from '../dtos';
import { create } from 'react-test-renderer';
import { Ease, UserEase } from '../types/ease';

export const Actions = {
  getEase: () => createAction(ActionTypes.GetEaseRequest),
  getEaseSuccess: (easeDto: EaseInfoDto) =>
    createAction(ActionTypes.GetEaseSuccess, easeDto),
  getEaseFailure: (easeDto: EaseStorageDto) =>
    createAction(ActionTypes.GetEaseFailure, easeDto),

  getEaseUpdatedPrice: (packageId: string) =>
    createAction(ActionTypes.GetEaseUpdatedPriceRequest, packageId),
  getEaseUpdatedPriceSuccess: (easeDto: EaseInfoDto) =>
    createAction(ActionTypes.GetEaseUpdatedPriceSuccess, easeDto),
  getEaseUpdatedPriceFailure: (easeDto: EaseInfoDto) =>
    createAction(ActionTypes.GetEaseUpdatedPriceSuccess, easeDto),

  deleteEase: (ease: Ease) => createAction(ActionTypes.DeleteEaseRequest, ease),
  deleteEaseSuccess: (ease: Ease) =>
    createAction(ActionTypes.DeleteEaseSuccess, ease),
  deleteEaseFailure: (ease: Ease) =>
    createAction(ActionTypes.DeleteEaseFailure, ease),

  clearDeleteEaseServerErrors: () =>
    createAction(ActionTypes.ClearDeleteEaseServerErrors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
