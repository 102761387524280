import {
  AccountProfile,
  Timezone,
  UsageStats,
  UserPackage,
} from '../../state/types/user';
import { compact } from 'lodash';
import { AddressDto } from '../../state/dtos';
import moment from 'moment';
import { Package } from '../../state/types/package';
import { TypedMap } from '../../@types/TypedMap';
import { CreditCardTypes } from '../../components/__common__/CreditCardIcon';

export namespace AccountAndPlanUtils {
  export const calcOverage = (
    usageStats: UsageStats,
    userPackage: UserPackage
  ): number => {
    if (usageStats && !usageStats.overage) {
      return 0;
    }
    return usageStats.overage * userPackage.overagePrice;
  };

  export const isTrial40 = (userPackage: UserPackage): boolean => {
    const trial40K = 'trial 40k';
    if (!userPackage.name) {
      return false;
    }
    return userPackage.name.toLowerCase() === trial40K;
  };

  export const isCancelledAccount = (userPackageStatus: string): boolean => {
    const cancellationStatus = 'Pending Cancellation';
    return userPackageStatus === cancellationStatus;
  };

  export enum PlanLinks {
    AboutIps = 'https://sendgrid.com/blog/shared-and-dedicated-ips-which-should-you-choose/',
    ChoosePlan = '/account/billing/choose_plan',
    ContactSupport = 'https://support.sendgrid.com/hc/en-us',
    EmailBootcamp = 'https://sendgrid.com/solutions/email-bootcamp',
    EmailImplementation = 'https://sendgrid.com/solutions/email-implementation',
    EmailUsageSettings = '/settings/alerts',
    ExpertInsights = 'https://sendgrid.com/solutions/expert-insights',
    ExpertInsightsExamplePDF = 'https://sendgrid.com/wp-content/uploads/pdf/Expert-Insights-Sample.pdf',
    ExpertServicesImplementation = '/account/billing/expert_services/implementation',
    ExpertServicesOngoing = '/account/billing/expert_services/ongoing',
    ExpertServicesQuestions = 'https://sendgrid.com/expert-services-questions/',
    ExplainMyInvoice = 'https://sendgrid.com/docs/ui/account-and-settings/reading-your-invoice/',
    KnowledgeBase = 'https://sendgrid.com/docs/ui/account-and-settings/billing/',
    LearnAboutContacts = 'https://sendgrid.com/docs/ui/account-and-settings/billing/#marketing-campaigns-contacts',
    ManageEase = '/settings/billing/addons/email_activity',
    ManageIps = '/settings/ip_addresses',
    Plan = '/account/billing',
    SubuserDocs = 'https://sendgrid.com/docs/ui/account-and-settings/subusers',
    TOS = 'http://sendgrid.com/tos',
    WhatIsEase = 'https://sendgrid.com/docs/ui/analytics-and-reporting/email-activity-feed/',
  }

  // create util, intersection type, and picker to grab data from either account or billing
  export const formatAddress = (
    addressInfo: AccountProfile | AddressDto
  ): string[] => {
    const { address, address2, city, country, state, zip } = addressInfo;
    let cityStateZip = '';
    if (city) {
      cityStateZip = `${city},  ${state} ${zip}`;
    }
    return compact([address, address2, cityStateZip, country]);
  };

  // Format payment method credit card to show last 4 numbers
  // e.g "************0141" => "0141"
  export const formatCardNumber = (rawCardNumber: string = '') => {
    return rawCardNumber.replace(/\*/gi, '');
  };
  // Format card types returned from Zuora's API to have proper copy
  export const formatCardType = (cardType: CreditCardTypes) => {
    switch (cardType) {
      case 'Discover':
      case 'Visa':
        return cardType;
      case 'AmericanExpress':
        return 'American Express';
      case 'MasterCard':
        return 'Mastercard';
      default:
        return '';
    }
  };

  export const hasTrialEnded = (userPackage: UserPackage): boolean => {
    const today = moment();
    if (userPackage.trialExpirationDate) {
      const packageExpDate = moment(userPackage.trialExpirationDate);
      return today.isAfter(packageExpDate);
    }
    return false;
  };

  export const select = {
    createSelectOptions: (arr: string[]) =>
      arr.map((item) => ({ label: item, value: item })),

    createSelectTimezoneOptions: (timezoneOptions: Timezone[] = []) =>
      timezoneOptions.map(({ code, name, offset }) => ({
        label: `${offset} - ${name}`,
        value: code,
      })),
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  export const dates = {
    getCurrentMonth: (): string => {
      return moment().format('MMMM');
    },
    getStartOfNextMonth: (): string => {
      const nextMonth = moment().add(1, 'month');
      const nextMonthText = months[nextMonth.month()];
      const nextMonthYear = nextMonth.year();

      return `${nextMonthText} 1, ${nextMonthYear}`;
    },
    getNextMonth: (): string => {
      return moment()
        .add(1, 'M')
        .format('MMMM');
    },
    getYear: (): string => {
      return moment().format('Y');
    },
  };

  export const transform = {
    capitalizeFirstLetter: (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  };

  export enum FreePackages {
    free100KId = '02a5084c-94d7-458d-9a88-1d2cfd37b262',
    trial40KId = '71713987-9f01-4dea-b3d4-8d0bcd9d53ed',
  }

  export const doesUserHaveFreePackage = (
    userPackage: UserPackage,
    allPackages: Package[]
  ): boolean => {
    if (!allPackages || (allPackages && !allPackages.length) || !userPackage) {
      return false;
    }

    const freePackages = getFreePackages(allPackages);
    return userPackage.packageId in freePackages;
  };

  export const getFreePackages = (packages: Package[]): TypedMap<Package> => {
    const free = 'free';
    if (!packages || (packages && !packages.length)) {
      return {};
    }

    return packages.reduce(
      (acc, p: Package) => {
        if (p.planType && p.planType.toLowerCase() === free) {
          return { ...acc, [p.id]: p };
        }
        return acc;
      },
      {} as any
    );
  };

  export const dollarize = (n: string | number) => {
    // handles 0 since it can be falsy
    if (n !== undefined) {
      // make sure it's a number
      const num = typeof n === 'string' ? parseFloat(n) : n;
      // convert it to a string with two decimals
      const numStr = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `$${numStr}`;
    }
    return '';
  };

  // Converts numbers in thousands to have proper commas i.e. 4500 to "4,500" or "4500" to "4,500"
  export const numberWithCommas = (n: string | number = '') => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  export const dollarizeWithoutCents = (n: string | number) => {
    // handles 0 since it can be falsy
    if (n !== undefined) {
      // make sure it's a number
      const num = typeof n === 'string' ? parseFloat(n) : n;
      // add commas if necessary
      return `$${num.toLocaleString()}`;
    }
    return '';
  };

  export const flash = {
    showErrorBanner: (
      errorMsg: string = 'An error occurred, please try again.',
      timeToDisplay?: number
    ) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash', errorMsg, true, timeToDisplay);
    },
    showErrorBannerHTML: (
      errorMsg: string = 'An error occurred, please try again.',
      timeToDisplay?: number
    ) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash_html', errorMsg, true, timeToDisplay);
    },
    showSuccessBanner: (
      successMsg: string = 'Success!',
      timeToDisplay?: number
    ) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash', successMsg, false, timeToDisplay);
    },
    showSuccessBannerHTML: (
      successMsg: string = 'Success!',
      timeToDisplay?: number
    ) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash_html', successMsg, false, timeToDisplay);
    },
  };

  export const tracking = {
    segmentTiaraAnalyticsTrack: (trackingInfo: string) => {
      window.SendGridTiara.analyticsTrack(trackingInfo);
    },
  };
}
