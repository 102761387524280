import { ActionTypes } from './actionTypes';
import { ActionsUnion, createAction } from '../actionBuilder';
import { TimezoneDto } from '../dtos';

export const Actions = {
  getTimezoneOptionsRequest: () =>
    createAction(ActionTypes.GetTimezoneOptionsRequest),
  getTimezoneOptionsSuccess: (timezoneOptions: TimezoneDto[]) =>
    createAction(ActionTypes.GetTimezoneOptionsSuccess, timezoneOptions),
  getTimezoneOptionsFailure: (errors: TimezoneDto[]) =>
    createAction(ActionTypes.GetTimezoneOptionsFailure, errors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
