(function($) {
  $.fn.sgModal = function(action, options) {
    return this.each(function() {
      const $this = $(this);

      const modal = new Mako.Modal($this);

      $(modal)
        .on("modal:shown", () => {
          $this.find(".sg-modal-content").addClass("is-visible");
        })
        .on("modal:hidden", () => {
          $this.find(".sg-modal-content").removeClass("is-visible");
        });

      // proxy modal events to the jquery element
      $(modal).on("modal:confirm modal:shown modal:cancel modal:hidden", e => {
        $this.trigger(e);
      });

      modal.show();
    });
  };
})(jQuery);
