import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { PaymentMethodDto, PaymentFormDto } from '../dtos';

export const Actions = {
  getPaymentMethod: () => createAction(ActionTypes.GetPaymentMethodRequest),
  getPaymentMethodSuccess: (paymentMethodDto: PaymentMethodDto) =>
    createAction(ActionTypes.GetPaymentMethodSuccess, paymentMethodDto),
  getPaymentMethodFailure: (paymentMethodDto: PaymentMethodDto) =>
    createAction(ActionTypes.GetPaymentMethodFailure, paymentMethodDto),
  updateDefaultPaymentMethod: (payload: PaymentFormDto) =>
    createAction(ActionTypes.UpdateDefaultPaymentMethodRequest, payload),
  updateDefaultPaymentMethodSuccess: (paymentMethodDto: PaymentMethodDto) =>
    createAction(
      ActionTypes.UpdateDefaultPaymentMethodSuccess,
      paymentMethodDto
    ),
  updateDefaultPaymentMethodFailure: () =>
    createAction(ActionTypes.UpdateDefaultPaymentMethodFailure),
  reactivateUser: () => createAction(ActionTypes.ReactivateUser),
  reactivateUserSuccess: () => createAction(ActionTypes.ReactivateUserSuccess),
  reactivateUserFailure: () => createAction(ActionTypes.ReactivateUserFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
