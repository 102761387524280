import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { SubscriptionPayment } from '../types/subscriptionPayment';

export const Actions = {
  createSubscriptionPayment: (subscriptionPayment: SubscriptionPayment) =>
    createAction(
      ActionTypes.CreateSubscriptionPaymentRequest,
      subscriptionPayment
    ),
  createSubscriptionPaymentSuccess: (
    subscriptionPayment: SubscriptionPayment
  ) =>
    createAction(
      ActionTypes.CreateSubscriptionPaymentSuccess,
      subscriptionPayment
    ),
  createSubscriptionPaymentFailure: (
    subscriptionPayment: SubscriptionPayment
  ) =>
    createAction(
      ActionTypes.CreateSubscriptionPaymentFailure,
      subscriptionPayment
    ),
  clearCreateSubscriptionPaymentErrors: () =>
    createAction(ActionTypes.ClearCreateSubscriptionPaymentErrors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
