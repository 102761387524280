import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { ExpertServicesImplementationDto } from '../dtos';

export const Actions = {
  getExpertServicesImplementation: () =>
    createAction(ActionTypes.GetExpertServicesImplementationRequest),
  getExpertServicesImplementationSuccess: (
    expertServicesImplementationDto: ExpertServicesImplementationDto
  ) =>
    createAction(
      ActionTypes.GetExpertServicesImplementationSuccess,
      expertServicesImplementationDto
    ),
  getExpertServicesImplementationFailure: (
    errors: ExpertServicesImplementationDto
  ) => createAction(ActionTypes.GetExpertServicesImplementationFailure, errors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
