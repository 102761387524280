const Marionette = require("backbone.marionette");

module.exports = Marionette.LayoutView.extend({
  template: require("templates/email_confirmation/email_confirmation_modal_layout.hbs"),

  regions: {
    confirmationModal: "[role=confirmationModal]",
    successModal: "[role=successModal]",
  },

  onShow() {
    const confirmationModalView = this.getOption("confirmationModalView");
    const successModalView = this.getOption("successModalView");

    this.confirmationModal.show(confirmationModalView);
    this.successModal.show(successModalView);

    this.listenTo(confirmationModalView, "saved", () => {
      confirmationModalView.hideModal();
      successModalView.showModal();
    });

    this.listenTo(successModalView, "resend", () => {
      successModalView.hideModal();
      confirmationModalView.showModal(true);
    });
  },
});
