const Marionette = require("backbone.marionette");

const Modal = require("../../helpers/modal");

module.exports = Marionette.ItemView.extend({
  template: require("templates/email_confirmation/email_confirmation_success_modal.hbs"),

  ui: {
    modalContainer: "[data-role=emailConfirmationSuccessContainer]",
    modal: "[data-role=emailConfirmationSuccessContainer] [data-role=modal]",
    mask: "[data-role=emailConfirmationSuccessContainer] [data-role=mask]",
    close: "[data-role=emailConfirmationSuccessContainer] [data-role=close]",

    resendLink: "[role=resendLink]",
  },

  events: {
    "click @ui.close": "hideModal",
    "click @ui.mask": "hideModal",
  },

  triggers: {
    "click @ui.resendLink": "resend",
  },

  showModal() {
    Modal.show(this.ui.modalContainer);
  },

  hideModal() {
    Modal.hide(this.ui.modalContainer);
  },
});
