import defaultJSONHeaders from './default_json_headers';
import APIConfig from '../apiconfig';

export enum RequestTypes {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

interface ResponseBody<T> extends Body {
  ok: boolean;
  status: number;
  statusText: string;
  json(): Promise<T>;
}

/**
 * namespace for all Authenticated Http calls
 */
export namespace AuthedHttp {
  const buildRequest = (
    requestType: RequestTypes,
    body?: any,
    requestOptions?: Request
  ): Request => {
    let request = {} as any;
    let headers = defaultJSONHeaders.headers;
    if (requestOptions) {
      if (requestOptions.headers) {
        headers = { ...headers, ...requestOptions.headers };
      }
      request = { ...requestOptions };
    }

    request.headers = headers;
    request.method = requestType;

    if (body) {
      request.body = JSON.stringify(body);
    }
    return request as Request;
  };

  function makeRequest<T>(
    uri: string,
    request: Request
  ): Promise<ResponseBody<T>> {
    const url = `${APIConfig.host}${uri}`;
    return fetch(url, request);
  }

  /**
   * Wrapper for the HTTP GET method
   * Requires the Return Type as the generic parameter
   * @param url - url to hit
   * @param requestOptions - request options to override
   */
  export function get<T>(
    url: string,
    requestOptions?: Request
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Get, undefined, requestOptions);
    return makeRequest<T>(url, request);
  }

  /**
   * Wrapper for the HTTP POST method
   * Requires the Return Type as the generic parameter
   * @param url - url to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   */
  export function post<T>(
    url: string,
    body?: any,
    requestOptions?: Request
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Post, body, requestOptions);
    return makeRequest<T>(url, request);
  }

  /**
   * Wrapper for the HTTP PUT method
   * Requires the Return Type as the generic parameter
   * @param url - url to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   */
  export function put<T>(
    url: string,
    body: any,
    requestOptions?: Request
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Put, body, requestOptions);
    return makeRequest<T>(url, request);
  }

  /**
   * Wrapper for the HTTP PATCH method
   * Requires the Return Type as the generic parameter
   * @param url - url to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   */
  export function patch<T>(
    url: string,
    body: any,
    requestOptions?: Request
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Patch, body, requestOptions);
    return makeRequest<T>(url, request);
  }

  /**
   * Wrapper for the HTTP DELETE method
   * Requires the Return Type as the generic parameter
   * @param url - url to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   */
  export function del<T>(
    url: string,
    body?: any,
    requestOptions?: Request
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Delete, body, requestOptions);
    return makeRequest<T>(url, request);
  }
}
