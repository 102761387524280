import _ from "underscore";
import {
  selectSelectorGenerator,
  writeSelectorGenerator,
} from "../../../../../pages/selectorHelpers";

const QaHooks = {};

// if key/value have the same name (i.e. key is 'loader' and its value is 'loader'), add it here
const dataAttributesArr = [
  "contactHeader",
  "contactCheckboxList",
  "continueDowngradeButton",
];

// if key/value have different names (i.e. key is 'loader' and its value is 'nameLoader'), add it at the bottom of this object
const dataAttributes = {
  ..._.object(dataAttributesArr, dataAttributesArr),
};

const Selectors = { ...selectSelectorGenerator(dataAttributes), ...QaHooks };
const WriteSelectors = writeSelectorGenerator(dataAttributes);

export { Selectors, WriteSelectors };
