import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { BillingAccountAndPaymentFormDto, BillingAccountDto } from '../dtos';

export const Actions = {
  getBillingAccount: () => createAction(ActionTypes.GetBillingAccountRequest),
  getBillingAccountSuccess: (billingAccountDto: BillingAccountDto) =>
    createAction(ActionTypes.GetBillingAccountSuccess, billingAccountDto),
  getBillingAccountFailure: () =>
    createAction(ActionTypes.GetBillingAccountSuccess),

  getPaymentFormForPaidUser: () =>
    createAction(ActionTypes.GetPaymentFormForPaidUserRequest),
  getPaymentFormSuccessForPaidUser: (
    billingAccountAndPaymentFormDto: BillingAccountAndPaymentFormDto
  ) =>
    createAction(
      ActionTypes.GetPaymentFormForPaidUserSuccess,
      billingAccountAndPaymentFormDto
    ),
  getPaymentFormFailureForPaidUser: () =>
    createAction(ActionTypes.GetPaymentFormForPaidUserFailure),

  clearPaymentFormErrorsForPaidUser: () =>
    createAction(ActionTypes.ClearPaymentFormErrors),

  getPaymentFormForUpgradingUser: () =>
    createAction(ActionTypes.GetPaymentFormForUpgradingUserRequest),
  getPaymentFormSuccessForUpgradingUser: (
    billingAccountAndPaymentFormDto: BillingAccountAndPaymentFormDto
  ) =>
    createAction(
      ActionTypes.GetPaymentFormForUpgradingUserSuccess,
      billingAccountAndPaymentFormDto
    ),
  getPaymentFormFailureForUpgradingUser: () =>
    createAction(ActionTypes.GetPaymentFormForUpgradingUserFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
