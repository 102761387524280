import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { UpdatedPlan, UpdatePlanType } from '../types/updatedPlan';

export const Actions = {
  updatePlan: (updatePlan: UpdatePlanType) => {
    return createAction(ActionTypes.UpdatePlanRequest, updatePlan);
  },
  updatePlanSuccess: (updatedPlan: UpdatedPlan) =>
    createAction(ActionTypes.UpdatePlanSuccess, updatedPlan),
  updatePlanFailure: (updatedPlan: UpdatedPlan) =>
    createAction(ActionTypes.UpdatePlanFailure, updatedPlan),

  clearUpdatePlanServerErrors: () =>
    createAction(ActionTypes.ClearUpdatePlanServerErrors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
