const Marionette = require("backbone.marionette");

// Helper views
const SignupExpiredEmailConfirmationView = require("./views/errors/expired_signup_email_confirmation");
const ErrorLayoutView = require("./views/error_layout_view");

// Route helpers
const RouterHelpers = require("./router_helpers");
const IPsCollection = require("./collections/ips_collection");

const MakoRouter = Marionette.AppRouter.extend({
  routes: {
    "": "dashboard",
    "signup/update_email": "signupEmailValidation",
    "signup/validate": "signupValidate",
    "resubscribe/aws": "resubscribeAws",
    "signup/aws": "signupAws",
    signup: "redirectToSignupApp",
    labs: "redirectToLabsApp",
    login: "login",
    "login/from-site?token=:token": "tokenLogin",
    forgot_password: "forgotPassword",
    reset_password: "resetPassword",
    validate_2fa: "validate2FA",
    guide: "guide",
    "guide/complete": "guideParty",
    "guide/integrate": "guideIntegrate",
    "guide/integrate/langs": "guideLangs",
    "guide/integrate/langs/:lang": "guideLang",
    "guide/integrate/langs/:lang/verify": "guideVerify",
    templates: "templateEngine",
    marketing_emails: "marketingEmails",
    email_activity: "emailActivity",
    "email_activity/download": "emailActivityCsvDownload",
    "email_activity/:msg_id": "emailActivityItem",

    email_validation: "emailValidation",

    "expert_insights/download": "expertInsightsPdfDownload",

    "account/details": "accountDetails",
    "account/billing": "accountDetails",
    "account/subscription": "accountDetails",
    "settings/billing/premier": "billing",
    "account/billing/choose_plan": "accountDetails",
    "account/billing/expert_services/implementation": "accountDetails",
    "account/billing/expert_services/ongoing": "accountDetails",
    "settings/account": "redirectToAccountPage",
    "settings/alerts": "settingsAlerts",
    "settings/alerts/:id": "settingsAlertDetails",
    "settings/auth": "settingsAuth",
    "settings/subusers": "subuserManagement",
    "settings/subusers/:username/settings": "subuserManagementSettings",
    "settings/subusers/:username/profile": "subuserManagementProfile",
    "settings/api_keys": "apiKeys",
    "settings/access": "accessSettings",
    "settings/whitelabel": "whitelabelOverview",
    "settings/whitelabel/domains": "whitelabelDomains",
    "settings/whitelabel/domains/:id": "whitelabelDomainDetails",
    "settings/whitelabel/links": "whitelabelEmailLinks",
    "settings/whitelabel/links/:id": "whitelabelEmailLinkDetails",
    "settings/whitelabel/ips": "whitelabelIPs",
    "settings/whitelabel/ips/:id": "whitelabelIPDetails",

    "settings/sender_auth": "whiteLabelLandingPage",

    "settings/sender_auth/domains": "whiteLabelDomainList",
    "settings/sender_auth/domain/get/:id": "whiteLabelDomainDetail",
    "settings/sender_auth/domain/create": "whiteLabelCreateDomain",
    "settings/sender_auth/domain/configure": "whiteLabelCreateDomainStep2",

    "settings/sender_auth/verify": "whiteLabelVerify",

    "settings/sender_auth/links": "whiteLabelLinkList",
    "settings/sender_auth/link/get/:id": "whiteLabelLinkDetail",
    "settings/sender_auth/link/create": "whiteLabelCreateLink",
    "settings/sender_auth/link/configure": "whiteLabelCreateLinkStep2",
    "public/sender_auth/verification": "senderAuthPublicVerification",

    "settings/sender_auth/reverse_dns": "senderAuthReverseDNSList",
    "settings/sender_auth/reverse_dns/create": "senderAuthReverseDNSCreate",
    "settings/sender_auth/reverse_dns/get/:id": "senderAuthReverseDNSDetails",

    "settings/sender_auth/confirmation/instructions_sent": "instructions_sent",
    "settings/sender_auth/confirmation/installation_confirmed":
      "installation_confirmed",
    "settings/sender_auth/confirmation/link_expired": "link_expired",
    "settings/sender_auth/confirmation/success": "success",
    "settings/sender_auth/confirmation/sorry": "sorry",

    "settings/parse": "parseSettings",
    "settings/mail_settings": "mailSettings",
    "settings/partners": "mailSettingsPartners",
    "settings/tracking": "mailSettingsTracking",
    "settings/billing": "redirectToBillingPage",
    "settings/billing/aws": "billing",
    "settings/billing/addons/email_activity": "emailActivityBilling",
    "settings/billing/addons/email_activity/success":
      "emailActivityBillingSuccess",
    "settings/billing/addons/email_activity/success/downgrade":
      "emailActivityBillingDowngrade",
    "settings/billing/thanks": "billingThanks",
    "settings/billing/retry_thanks": "billingRetryThanks",
    "settings/billing/aws/thanks/basic": "billingThanksAwsBasic",
    "settings/billing/aws/thanks/pro": "billingThanksAwsPro",
    "settings/credentials": "credentials",
    "settings/credentials/create": "credentialsCreate",
    "settings/credentials/:username/edit": "credentialsEdit",
    settings: "redirectSettings",
    "settings/teammates": "teammates",
    "settings/ip_addresses": "ipAddresses",
    "settings/ip_addresses/purchase_success": "ipAddressesPurchaseSuccess",
    "teammates/confirm": "teammatesConfirmation",
    "errors/expired_invitation": "errorsExpiredInvitation",
    "errors/expired_reset_password": "errorsExpiredResetPassword",
    logout: "logout",
    "*notFound": "notFound404",
  },

  instructions_sent() {
    import("routes/whitelabel/confirmation/instructions_sent.jsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          requiredScopes: "whitelabel.update",
          accessDeniedOptions: {
            restrictAccessRequest: true,
          },
        });
      }
    );
  },
  installation_confirmed() {
    import("routes/whitelabel/confirmation/public_install_confirmed.jsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      }
    );
  },
  link_expired() {
    import("routes/whitelabel/confirmation/public_link_expired.jsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      }
    );
  },
  success() {
    import("routes/whitelabel/confirmation/successful.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },
  sorry() {
    import("routes/whitelabel/confirmation/sorry.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  whiteLabelLandingPage() {
    import("./routes/whitelabel/whitelabel_landing_page.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },

  whiteLabelDomainList() {
    import("./routes/whitelabel/whitelabel_domain_list.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },

  whiteLabelLinkList() {
    import("./routes/whitelabel/whitelabel_link_list.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },

  whiteLabelDomainDetail(id) {
    import("./routes/whitelabel/whitelabel_domain_detail").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        id,
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },

  whiteLabelLinkDetail(id) {
    import("./routes/whitelabel/whitelabel_link_detail").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        id,
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },
  whiteLabelCreateDomain() {
    import("./routes/whitelabel/whitelabel_create_domain.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.update",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },
  whiteLabelCreateDomainStep2() {
    import("./routes/whitelabel/whitelabel_create_domain_step2.jsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          requiredScopes: "whitelabel.update",
          accessDeniedOptions: { scopeGroupName: "Whitelabel" },
        });
      }
    );
  },
  whiteLabelVerify() {
    import("./routes/whitelabel/whitelabel_verify.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.update",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },
  whiteLabelCreateLink() {
    import("./routes/whitelabel/whitelabel_create_link.jsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.update",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },
  whiteLabelCreateLinkStep2() {
    import("./routes/whitelabel/whitelabel_create_link_step2.jsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          requiredScopes: "whitelabel.update",
          accessDeniedOptions: { scopeGroupName: "Whitelabel" },
        });
      }
    );
  },

  senderAuthReverseDNSList() {
    const isSubuser =
      window.Reqres.request("currentUser").userType === "subuser";

    const hasIpsCollection = new IPsCollection();
    hasIpsCollection.limit = 1;
    hasIpsCollection.exclude_whitelabels = false;

    // This is the preffered method to find IP's but for some reason resellers doesn't allow access to the packages endpoint
    // the previous hasIPsCollection method is from the previous iteration of whitelabel/sender auth

    // const isAtLeastProUser = window.Reqres.request("userPackage").attributes
    // .has_ip;

    hasIpsCollection.fetch().done((ele) => {
      const isAtLeastProUser = Boolean(ele.length);
      if (isSubuser) {
        // subusers will see "You don't have access to this page..."
        this.errorRouter.errorsAccessDenied({ restrictAccessRequest: true });
        return;
      }

      if (isAtLeastProUser) {
        import("./routes/whitelabel/whitelabel_rdns_list").then((view) => {
          view.default({
            errorRouter: this.errorRouter,
            layoutController: this.getOption("layoutController"),
            requiredScopes: "whitelabel.read",
            accessDeniedOptions: { scopeGroupName: "Whitelabel" },
          });
        });
        return;
      }

      // free, essentials, and other legacy packages below pro will see "This feature is not available on your current plan..."
      this.errorRouter.errorsAccessDenied({ restrictAccessRequest: false });
    });
  },

  senderAuthReverseDNSCreate() {
    const isSubuser =
      window.Reqres.request("currentUser").userType === "subuser";

    const hasIpsCollection = new IPsCollection();
    hasIpsCollection.limit = 1;
    hasIpsCollection.exclude_whitelabels = false;

    hasIpsCollection.fetch().done((ele) => {
      const isAtLeastProUser = Boolean(ele.length);

      if (isSubuser) {
        // subusers will see "You don't have access to this page..."
        this.errorRouter.errorsAccessDenied({ restrictAccessRequest: true });
        return;
      }

      if (isAtLeastProUser) {
        import("./routes/whitelabel/whitelabel_create_rdns.jsx").then(
          (view) => {
            view.default({
              errorRouter: this.errorRouter,
              layoutController: this.getOption("layoutController"),
              requiredScopes: "whitelabel.update",
              accessDeniedOptions: { scopeGroupName: "Whitelabel" },
            });
          }
        );
        return;
      }

      // free, essentials, and other legacy packages below pro will see "This feature is not available on your current plan..."
      this.errorRouter.errorsAccessDenied({ restrictAccessRequest: false });
    });
  },

  senderAuthReverseDNSDetails(id) {
    const isSubuser =
      window.Reqres.request("currentUser").userType === "subuser";

    const hasIpsCollection = new IPsCollection();
    hasIpsCollection.limit = 1;
    hasIpsCollection.exclude_whitelabels = false;

    hasIpsCollection.fetch().done((ele) => {
      const isAtLeastProUser = Boolean(ele.length);

      if (isSubuser) {
        // subusers will see "You don't have access to this page..."
        this.errorRouter.errorsAccessDenied({ restrictAccessRequest: true });
        return;
      }

      if (isAtLeastProUser) {
        import("./routes/whitelabel/whitelabel_rdns_detail").then((view) => {
          view.default({
            errorRouter: this.errorRouter,
            layoutController: this.getOption("layoutController"),
            id,
            requiredScopes: "whitelabel.read",
            accessDeniedOptions: { scopeGroupName: "Whitelabel" },
          });
        });
        return;
      }

      // free, essentials, and other legacy packages below pro will see "This feature is not available on your current plan..."
      this.errorRouter.errorsAccessDenied({ restrictAccessRequest: false });
    });
  },

  senderAuthPublicVerification() {
    import("./routes/whitelabel/whitelabel_domain_verification.jsx").then(
      (view) => {
        view.default({
          isPublicRoute: true,
          region: this.getOption("region"),
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      }
    );
  },

  // Move WhiteLabel routes elsewhere, this is for merging purposes

  initialize() {
    this.errorRouter = this.getOption("errorRouter");
    this.authController = this.getOption("authController");
  },

  before(route) {
    RouterHelpers.before(this, route);
  },

  signupEmailValidation() {
    import("routes/signup_email_validation").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  signupValidate(queryParams) {
    import("routes/signup_validate").then((view) => {
      view.default({
        self: this,
        queryParams,
      });
    });
  },

  signupAws() {
    import("routes/signup_aws").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  redirectToSignupApp() {
    window.EventBus.trigger("redirectToSignupApp");
  },

  redirectToLabsApp() {
    window.EventBus.trigger("redirectToLabsApp");
  },

  redirectToAccountPage() {
    window.EventBus.trigger("redirectToAccountPage");
  },

  redirectToBillingPage() {
    window.EventBus.trigger("redirectToBillingPage");
  },

  resubscribeAws() {
    import("routes/resubscribe_aws").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  errorsExpiredEmailConfirmation() {
    if (this.getOption("authController").checkAuthToken()) {
      window.EventBus.trigger("navigate", "");
      return;
    }

    const errorView = new SignupExpiredEmailConfirmationView();
    const layoutView = new ErrorLayoutView({
      contentView: errorView,
      showLogo: true,
    });
    this.getOption("region").show(layoutView);
  },

  login(queryParams) {
    import("routes/login").then((view) => {
      view.default({
        authController: this.getOption("authController"),
        region: this.getOption("region"),
        queryParams,
      });
    });
  },

  tokenLogin(token, isSendToSend) {
    this.getOption("authController").loginWithToken(token, isSendToSend);
  },

  forgotPassword() {
    import("routes/forgot_password").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  resetPassword(queryParams) {
    import("routes/reset_password").then((view) => {
      view.default({
        region: this.getOption("region"),
        queryParams,
      });
    });
  },

  validate2FA() {
    import("routes/validate_2fa").then((view) => {
      view.default({
        authController: this.getOption("authController"),
        region: this.getOption("region"),
      });
    });
  },

  dashboard() {
    import("routes/dashboard").then((view) => {
      view.default({
        emailStatsCollection: this.getOption("emailStatsCollection"),
        userProfileModel: this.getOption("userProfileModel"),
        layoutController: this.getOption("layoutController"),
        emailModel: this.getOption("emailModel"),
        usernameModel: this.getOption("usernameModel"),
      });
    });
  },

  guide(queryParams) {
    import("routes/guide").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  guideParty() {
    import("routes/guide_party").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  guideIntegrate() {
    import("routes/guide_integrate").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  guideLangs() {
    import("routes/guide_langs").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  guideLang(language) {
    import("routes/guide_lang").then((view) => {
      view.default({
        region: this.getOption("region"),
        language,
      });
    });
  },

  guideVerify(language) {
    import("routes/guide_verify").then((view) => {
      view.default({
        region: this.getOption("region"),
        language,
      });
    });
  },

  emailActivity(queryParams) {
    import("routes/email_activity").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  emailActivityItem(msgId, queryParams) {
    import("routes/email_activity").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        msgId,
        queryParams,
      });
    });
  },

  emailActivityCsvDownload(queryParams) {
    import("routes/email_activity_csv_download").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  emailValidation() {
    import("routes/email_validation/email_validation_landing_page.tsx").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          errorRouter: this.errorRouter,
          requiredScopes: "validations.email.read",
          accessDeniedOptions: {
            scopeGroupName: "Email Validation",
            canTeammateRequestAccess: true,
          },
        });
      }
    );
  },

  accountDetails() {
    import("routes/account_details").then((view) => {
      // temporarily hide the billing rewrite behind a feature toggle
      const currentPath = window.location.pathname;
      const isBillingPath = currentPath.indexOf("/account/billing") === 0;
      // AWS customers (activated/deactivated) get redirected to the old billing page
      if (window.Reqres.request("isAwsCustomer") && isBillingPath) {
        return window.EventBus.trigger("navigate", "settings/billing/aws");
      }

      return view.default({
        store: this.getOption("store"),
        history: this.getOption("history"),
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  settingsAuth() {
    import("routes/settings_auth").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  settingsAlerts() {
    import("routes/settings_alerts").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  settingsAlertDetails(alertId) {
    import("routes/settings_alert_details").then((view) => {
      view.default({
        alertId,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  templateEngine() {
    import("routes/template_engine").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  marketingEmails() {
    import("routes/marketing_emails").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  whitelabelOverview() {
    import("routes/whitelabel_overview").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        usernameModel: this.getOption("usernameModel"),
      });
    });
  },

  whitelabelDomains() {
    import("routes/whitelabel_domains").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  whitelabelEmailLinks() {
    import("routes/whitelabel_email_links").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  whitelabelEmailLinkDetails(linkId) {
    import("routes/whitelabel_email_link_details").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        linkId,
      });
    });
  },

  whitelabelIPs() {
    import("routes/whitelabel_ips").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  whitelabelIPDetails(ipId) {
    import("routes/whitelabel_ip_details").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        ipId,
      });
    });
  },

  whitelabelDomainDetails(domainId) {
    import("routes/whitelabel_domain_details").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        domainId,
      });
    });
  },

  redirectSettings() {
    window.EventBus.trigger("navigate", "settings/account");
  },

  subuserManagement(queryParams) {
    import("routes/subuser_management").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        emailModel: this.getOption("emailModel"),
        layoutController: this.getOption("layoutController"),
        authController: this.authController,
        queryParams,
      });
    });
  },

  subuserManagementSettings(username) {
    import("routes/subuser_management_settings").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        username,
      });
    });
  },

  subuserManagementProfile(username) {
    import("routes/subuser_management_profile").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        username,
      });
    });
  },

  parseSettings() {
    import("routes/settings_parse").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  mailSettings() {
    import("routes/settings_mail").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  mailSettingsPartners() {
    import("routes/settings_partners").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  mailSettingsTracking() {
    import("routes/settings_tracking").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  apiKeys(queryParams) {
    import("routes/settings_api_keys").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  billing() {
    import("routes/billing").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        notifier: this.getOption("notifier"),
        authController: this.authController,
      });
    });
  },

  emailActivityBilling(queryParams) {
    if (window.Reqres.request("isEaseGA")) {
      import("routes/email_activity_billing").then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          queryParams,
        });
      });
    } else {
      // Redirect users to email activity if they somehow figure out this link before GA
      window.EventBus.trigger("navigate", "/email_activity");
    }
  },

  emailActivityBillingSuccess() {
    if (window.Reqres.request("isEaseGA")) {
      import("routes/email_activity_billing_success").then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      });
    } else {
      // Redirect users to email activity if they somehow figure out this link before GA
      window.EventBus.trigger("navigate", "/email_activity");
    }
  },

  emailActivityBillingDowngrade() {
    if (window.Reqres.request("isEaseGA")) {
      import("routes/email_activity_billing_downgrade").then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      });
    } else {
      // Redirect users to email activity if they somehow figure out this link before GA
      window.EventBus.trigger("navigate", "/email_activity");
    }
  },

  billingThanks(queryParams) {
    import("routes/billing_thanks").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  billingThanksAwsBasic() {
    import("routes/billing_thanks_aws_basic").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  billingThanksAwsPro() {
    import("routes/billing_thanks_aws_pro").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  billingRetryThanks() {
    import("routes/billing_retry_thanks").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  credentials(queryParams) {
    import("routes/settings_credentials").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  credentialsCreate() {
    import("routes/settings_credentials_create").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  credentialsEdit(username) {
    import("routes/settings_credentials_edit").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        username,
      });
    });
  },

  accessSettings() {
    import("routes/settings_access").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  teammates() {
    import("routes/settings_teammates").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        authController: this.getOption("authController"),
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  teammatesConfirmation(queryParams) {
    import("routes/teammates_confirm").then((view) => {
      view.default({
        region: this.getOption("region"),
        queryParams,
      });
    });
  },

  errorsExpiredInvitation() {
    import("routes/errors_expired_invitation").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  errorsExpiredResetPassword() {
    import("routes/errors_expired_reset_password").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  ipAddresses() {
    import("routes/settings_ip_addresses").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  ipAddressesPurchaseSuccess(queryParams) {
    import("routes/settings_ip_addresses_purchase_success").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  expertInsightsPdfDownload(queryParams) {
    import("routes/expert_insights/expert_insights_download.tsx").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          queryParams,
        });
      }
    );
  },

  logout() {
    this.getOption("authController").logout(true);
  },

  notFound404() {
    const currentPath = window.location.pathname;
    // For all routes with /labs as a prefix, we'll redirect to the Labs app with
    // the remaining path appended to the end of the Labs host
    if (currentPath.startsWith("/labs")) {
      window.EventBus.trigger("redirectToLabsApp");
    } else {
      window.EventBus.trigger("render404Error");
    }
  },
});

module.exports = MakoRouter;
