import { ResponseError } from './responseError';

export interface EasePricing {
  name: string;
  price: string;
}

export enum EaseStorageName {
  ThreeDays = '3 days',
  ThirtyDays = '30 days',
}

export interface Ease {
  storage: EaseStorageName;
  pendingDowngrade: boolean;
  easePricing: EasePricing[];
  errors: ResponseError[];
}

export interface DeletedEase {
  isSuccessful: boolean;
  errors: ResponseError[];
}

export class UserEase implements Partial<Ease> {
  public storage: EaseStorageName;
  public pendingDowngrade: boolean;
  public price: number;
  public hasPurchasedEase: boolean;
  public errors: ResponseError[];
  public currentPlanHasPricing: boolean;
  // we use this when we're downgrading ease
  public readonly baseData: Ease;

  constructor(easeData?: Ease) {
    if (easeData) {
      this.storage = easeData.storage;
      this.pendingDowngrade = easeData.pendingDowngrade;
      const p = this.determineUsersEase(easeData);
      if (p) {
        this.price = Math.floor(+p.price);
        this.currentPlanHasPricing = true;
      } else {
        this.price = 0;
        // If the pricing endpoint errors out with a 404 and message "plan not found",
        // it will be undefined i.e. some ultra high volume premier packages, staging packages not set up.
        // We will hide the EASE addon section if the plan does not have pricing or it is not available.
        this.currentPlanHasPricing = false;
      }
      this.baseData = easeData;
      this.hasPurchasedEase = this.price > 0;
    }
  }

  private determineUsersEase(data: Ease): EasePricing | undefined {
    if (!data.easePricing) {
      return undefined;
    }

    return data.easePricing.find((e) => {
      return e.name === this.storage;
    });
  }
}
