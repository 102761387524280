import { ActionTypes } from './actionTypes';
import { CancelAccountInfo } from '../types/cancelAccount';
import { CancelAccountInfoDto } from '../dtos';
import { ActionsUnion, createAction } from '../actionBuilder';

export const Actions = {
  cancelAccountRequest: (cancelAccountInfo: CancelAccountInfo) =>
    createAction(ActionTypes.CancelAccountRequest, cancelAccountInfo),
  cancelAccountSuccess: () => createAction(ActionTypes.CancelAccountSuccess),
  cancelAccountFailure: (errors: CancelAccountInfoDto) =>
    createAction(ActionTypes.CancelAccountFailure, errors),
  clearCancelAccountServerErrors: () =>
    createAction(ActionTypes.ClearCancelAccountServerErrors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
