var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <i data-role=\"close\" class=\"sg-icon sg-icon-x\"></i>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div data-role=\""
    + container.escapeExpression(((helper = (helper = helpers.containerRole || (depth0 != null ? depth0.containerRole : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"containerRole","hash":{},"data":data}) : helper)))
    + "\">\n  <div data-role=\"modal\" class=\"center-modal\" tabindex=\"-1\" aria-hidden=\"true\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hideClose : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"alert alert-danger\" role=\"flash\" data-role=\"alert-failure\" style=\"display: none; margin: 0 -105px 14px\">\n      <p>\n        <i class=\"sg-icon sg-icon-warning\" role=\"flash-icon\"></i>\n        <span role=\"flash-copy\">Sorry, an error occurred. Engineers have been notified.</span>\n        <i class=\"sg-icon sg-icon-x\" onclick=\"$(this).parents('[role=flash]').fadeOut()\"></i>\n      </p>\n    </div>\n\n"
    + ((stack1 = container.invokePartial(partials["@partial-block"],depth0,{"name":"@partial-block","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div data-role=\"mask\" class=\"modal-mask\"></div>\n</div>\n";
},"usePartial":true,"useData":true});