const Marionette = require("backbone.marionette");

module.exports = Marionette.ItemView.extend({
  tagName: "tr",
  template: require("templates/stats/table_row_header.hbs"),

  serializeData() {
    const stats = {};
    const formatter = this.getOption("formatter");
    const aggregatedBy = this.getOption("pageModel").get("aggregated_by");

    if (this.model.get("stats").length > 0 && this.model.get("stats")[0].key) {
      stats.name = this.model.get("stats")[0].key;
    } else {
      stats.name = this.model.get("date");
    }

    if (formatter) {
      stats.name = formatter(stats.name, this.getOption("index"), aggregatedBy);
    }

    return stats;
  },
});
